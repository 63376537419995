import React, { useEffect, useState, useRef } from 'react'
import '../../css/index.css'

import { Link, useNavigate } from 'react-router-dom'
import { FaInfoCircle } from "react-icons/fa";
// import FilterSection from '../common/FilterSection'
import { FaCheckDouble, FaFilter, FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setFileId, setCompanyId, setUserId } from '../../../Redux/companyid/companyid.action';
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import FilterSection from '../../common/FilterSection';
import DataEntriesNo from '../../components/pagination/DataEntriesNo'
import PageNumber from '../../components/pagination/PageNumber'
import { ImCross } from 'react-icons/im'
import { LuRefreshCcw } from 'react-icons/lu'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCircleInfo, FaPlus } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import Sidebar1 from '../../common/Sidebar1';
import { userRoles, setSortOptions } from '../../../Redux/userRoles/userRoles.action';
import ConfirmationDialog from '../../../utils/ConfirmationDialog';
import Error from '../../../pages/Error';
import { useSortedData } from '../../../utils/sortUtils';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { jobList } from '../../../utils/ConstantOption';

const AddPermission = ({ newUserBar, setNewUserBar, editWindow, editData, handleCancelEditInfo }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openShowHide, setOpenShowHide] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [accessHelp, setAccessHelp] = useState(false);
    const [errors, setErrors] = useState({});

    const {
        userId, userRolesData, sortOrder, pageNumber, totalRecords, sortBy, filters, loading, permissions, userAuthData, errorUserAuth } = useSelector((state) => ({
            userId: state.companyid.userId,
            userRolesData: state.userRoles.userRolesData,
            sortOrder: state.userRoles.sortOrder,
            pageNumber: state.userRoles.pageNumber,
            totalRecords: state.userRoles.totalRecords,
            sortBy: state.userRoles.sortBy,
            filters: state.userRoles.filters,
            loading: state.userRoles.loading,
            permissions: state.companyid.permissions,
            userAuthData: state.userAuth.userAuthData,
            errorUserAuth: state.userAuth.errorUserAuth
        }));
    const [pageList, setPageList] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_HOST_NAME}/api/page/list`)
            .then((response) => {
                const pages = response?.data || [];
                setPageList(pages);

                let childPermissions = [];

                if (editWindow && editData?.permissions) {
                    // Use the permissions from editData instead of setting all to "full access"
                    childPermissions = editData.permissions;
                } else {
                    // Default case: Set all children to "full access"
                    childPermissions = pages.flatMap(page =>
                        page.children?.map(child => ({
                            pageId: child._id,
                            permissionLevel: "full access"
                        })) || []
                    );
                }

                setPermissionCustom(childPermissions);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [editWindow, editData]); // Add dependencies to re-run when `editWindow` or `editData` change

    const [updatedData, setUpdatedData] = useState(false);

    const [newUserDetail, setNewUserDetail] = useState({
        email: '',

        title: '',
        first_name: '',
        last_name: ''
    });



    const toggleNewUserBar = () => {
        setNewUserBar(!newUserBar);
    };

    const handleSetDetail = (e) => {
        const { name, value } = e.target;

        setNewUserDetail(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleCancel = () => {
        // Show the confirmation dialog
        setShowConfirmation(true);
    };

    const handleConfirmCancel = () => {
        // Handle the cancellation (e.g., reset form, navigate away, etc.)

        // setFormData(initialFormData);
        setShowConfirmation(false);
        setNewUserBar(false);
        // Reset or clear the form data if needed
    };
    const handleBack = () => {
        if (tabIndex === 1) {
            setTabIndex(0);
        }
        else {
            setNewUserDetail({
                email: '',
                title: '',
                first_name: '',
                last_name: ''
            })
            setPermissionCustom([]);
            setNewUserBar(null);
            handleCancelEditInfo();

        }
    }
    const handleCloseDialog = () => {
        // Close the confirmation dialog
        setShowConfirmation(false);
    };
    const [permissionCustom, setPermissionCustom] = useState([]);

    // console.log(permissionCustom, "custom");

    const handleSetPermission = (pageId, newPermission) => {
        setPermissionCustom(prevPermissions => {
            let updatedPermissions = [...prevPermissions];

            // Function to update parent permissions dynamically
            const updateParent = (parentId) => {
                const parentItem = pageList.find(item => item._id === parentId);
                if (!parentItem || !parentItem.children) return;

                const childPermissions = parentItem.children.map(child =>
                    updatedPermissions.find(p => p.pageId === child._id)?.permissionLevel || "full access"
                );

                const validPermissions = ["full access", "read", "no access"];
                const filteredChildPermissions = childPermissions.filter(p => validPermissions.includes(p));

                const uniquePermissions = [...new Set(filteredChildPermissions)];
                let parentPermission = uniquePermissions.length === 1 ? uniquePermissions[0] : "partial";

                const parentIndex = updatedPermissions.findIndex(p => p.pageId === parentId);
                if (parentIndex !== -1) {
                    updatedPermissions[parentIndex] = { ...updatedPermissions[parentIndex], permissionLevel: parentPermission };
                } else {
                    updatedPermissions.push({ pageId: parentId, permissionLevel: parentPermission });
                }
            };

            // Function to update children when a parent is changed
            const updateChildren = (parentId, permission) => {
                const parentItem = pageList.find(item => item._id === parentId);
                if (parentItem?.children?.length > 0) {
                    parentItem.children.forEach(child => {
                        const childIndex = updatedPermissions.findIndex(p => p.pageId === child._id);
                        if (childIndex !== -1) {
                            updatedPermissions[childIndex] = {
                                ...updatedPermissions[childIndex],
                                permissionLevel: permission === "partial" ? "full access" : permission
                            };
                        } else {
                            updatedPermissions.push({
                                pageId: child._id,
                                permissionLevel: permission === "partial" ? "full access" : permission
                            });
                        }
                    });
                }
            };

            // Check if the item is a parent
            const isParent = pageList.some(parent => parent._id === pageId && parent.children?.length > 0);
            if (isParent) {
                updateChildren(pageId, newPermission);
            }

            // Update the selected page’s permission
            const index = updatedPermissions.findIndex(p => p.pageId === pageId);
            if (index !== -1) {
                updatedPermissions[index] = { ...updatedPermissions[index], permissionLevel: newPermission };
            } else {
                updatedPermissions.push({ pageId, permissionLevel: newPermission });
            }

            // If it's a child, update its parent’s permission dynamically
            pageList.forEach(parent => {
                if (parent.children?.some(child => child._id === pageId)) {
                    updateParent(parent._id);
                }
            });

            // **Filter out parent entries from final permission list**
            const childrenPermissions = updatedPermissions.filter(permission =>
                pageList.some(parent => parent.children?.some(child => child._id === permission.pageId))
            );

            return childrenPermissions;
        });
    };

    useEffect(() => {
        setPageList(prevList =>
            prevList.map(page => ({
                ...page,
                permissionLevel: permissionCustom.find(p => p.pageId === page._id)?.permissionLevel || "full access",
                children: page.children?.map(child => ({
                    ...child,
                    permissionLevel: permissionCustom.find(p => p.pageId === child._id)?.permissionLevel || "full access"
                })) || [],
            }))
        );
    }, [permissionCustom]);

    useEffect(() => {
        if (editWindow && editData) {
            setNewUserDetail({
                email: editData.email || '',
                // title: editData.job_role || '',
                first_name: editData.first_name || '',
                last_name: editData.last_name || '',
                title: editData.title || ""
            });

            // Set permissions from editData if available
            if (editData.permissions) {
                const filteredPermissions = editData.permissions.map(({ _id, ...rest }) => rest);
                setPermissionCustom(filteredPermissions);
            }

        }
    }, [editWindow, editData]);


    useEffect(() => {
        // console.log("Updated permissions:", permissionCustom);
    }, [permissionCustom]);
    // console.log(permissionCustom, "Custom ");

    const [expandedItems, setExpandedItems] = useState([]);

    const toggleExpand = (id) => {
        setExpandedItems((prev) =>
            prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
        );
    };
    const handleAddUser = (e) => {
        e.preventDefault();
        if (newUserDetail.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newUserDetail.email)) {
            toast.error("Please input a valid Email ID");
            return;
        }
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/add/subuser`, {
            userId: userId,
            email: newUserDetail.email,
            job_role: newUserDetail.title,
            first_name: newUserDetail.first_name,
            last_name: newUserDetail.last_name,
            permissions: permissionCustom
            // "permissions": [{ "apiPath": "/", "permissionLevel": newUserDetail.type }]

        },
            // {
            //     withCredentials: true 
            // }
        )
            .then(response => {
                toast.success(response?.data);
                setUpdatedData(!updatedData);
                setNewUserBar(false);
                setNewUserDetail({ email: '', first_name: '', last_name: '' });
                handleCancelEditInfo();
            })
            .catch(error => {
                // console.log(error, "in adding new user");
                toast.error(error?.response?.data || error?.message);
            });
    };

    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        let payload = { userId, subUserId: editData?.subUserId || "" };

        // Check if basic details have changed
        if (newUserDetail.email !== editData.email) {
            payload.email = newUserDetail.email;
        }
        if (newUserDetail.first_name !== editData.first_name) {
            payload.first_name = newUserDetail.first_name;
        }
        if (newUserDetail.last_name !== editData.last_name) {
            payload.last_name = newUserDetail.last_name;
        }
        if (newUserDetail.title !== editData.title) {
            payload.job_role = newUserDetail.title;
        }

        // Find permission differences (removing `_id` from each)
        const newPermissions = permissionCustom
            .map(({ _id, ...rest }) => rest) // Remove _id
            .filter((perm) =>
                !editData.permissions.some(
                    (existingPerm) => JSON.stringify({ ...existingPerm, _id: undefined }) === JSON.stringify(perm)
                )
            );

        if (newPermissions.length > 0) {
            payload.newPermissions = newPermissions;
        }

        // Only send request if there are changes
        if (Object.keys(payload).length > 1) {
            axios.post(`${process.env.REACT_APP_HOST_NAME}/api/edit/subuser`, payload)
                .then(response => {
                    toast.success(response?.data);
                    setUpdatedData(!updatedData);
                    setNewUserBar(false);
                    setNewUserDetail({ email: '', title: "", first_name: '', last_name: '' });
                    handleCancelEditInfo();

                })
                .catch(error => {
                    toast.error(error?.response?.data || error?.message);
                });
        } else {
            toast.info("No changes detected");
        }
    };


    const getPermissionLevel = (pageId) => {
        const pageItem = pageList.find(item => item._id === pageId);

        // If it's a parent, determine permission from children
        if (pageItem?.children?.length > 0) {
            const childPermissions = pageItem.children.map(child =>
                permissionCustom.find(p => p.pageId === child._id)?.permissionLevel || "full access"
            );

            const uniquePermissions = [...new Set(childPermissions)];
            return uniquePermissions.length === 1 ? uniquePermissions[0] : "partial";
        }

        // Return directly for children
        return permissionCustom.find(p => p.pageId === pageId)?.permissionLevel || "full access";
    };

    const handleNext = (e) => {
        e.preventDefault();
        
        const requiredFields = ["first_name", "last_name", "email", "title"];
        let newErrors = {};
    
        setErrors((prevState) => {
            let updatedErrors = { ...prevState };
    
            // Check required fields and update errors
            requiredFields.forEach((field) => {
                if (!newUserDetail[field]) {
                    updatedErrors[field] = "This field is required";
                } else {
                    delete updatedErrors[field]; // Remove error if field is filled
                }
            });
    
            // Validate email format if email is provided
            if (newUserDetail.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newUserDetail.email)) {
                updatedErrors.email = "Please input a valid Email ID";
            } else {
                delete updatedErrors.email; // Remove error if email is valid
            }
    
            return updatedErrors;
        });
    
        // After setting errors, check if there are no errors, then switch tab
        setTimeout(() => {
            if (Object.keys(newErrors).length === 0) {
                setTabIndex(1);
            }
        }, 0);
    };
    
    
    const handleClear = () => {
        setNewUserDetail({ email: '', title: "", first_name: '', last_name: '' });
        setNewUserBar(false);
    };

    const buttonRef = useRef(null);

    return (

        < div className={`flex-1 max-w-full   z-40'}    relative overflow-x-auto  !2xl:font-[30px] `
        }>
            <div className='flex flex-row items-center  pt-7 pl-[19px] w-fit cursor-pointer hover:text-blue-800' onClick={handleBack}><MdOutlineKeyboardArrowLeft className='text-lg px-0' /> Back</div>

            <div className=''>
                <h1 className="font-semibold py-5 pl-5 text-xl text-gray-800">
                    {editWindow ? (
                        <>
                            Edit User: <span className="text-blue-600">{newUserDetail.email}</span>
                        </>
                    ) : (
                        "Create New User"
                    )}
                </h1>


                <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => {
                        if (index === 1 && tabIndex !== 1) {
                            const requiredFields = ["first_name", "last_name", "email", "title"];
                            let newErrors = {};

                            for (let field of requiredFields) {
                                if (!newUserDetail[field]) {
                                    newErrors[field] = "This field is required";
                                }
                            }

                            // Validate email format if email is provided
                            if (newUserDetail.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newUserDetail.email)) {
                                newErrors.email = "Please input a valid Email ID";
                            }

                            if (Object.keys(newErrors).length > 0) {
                                setErrors(newErrors);
                                const firstErrorField = Object.keys(newErrors)[0];
                                document.querySelector(`[name="${firstErrorField}"]`)?.focus(); // Focus the first empty field
                                return; // Stop tab switch
                            }

                            // If no errors, clear error messages and switch tab
                            setErrors({});
                        }

                        setTabIndex(index);
                    }}
                >


                    <TabList className="flex border-b pl-3">
                        <Tab className={`p-2 cursor-pointer   ${tabIndex === 0 ? ' border-b-2 font-[600] !border-b-[#4fd0ff]' : 'text-gray-600'}`}>Basic Information</Tab>
                        <Tab className={`p-2 cursor-pointer   ${tabIndex === 1 ? ' border-b-2 font-[600] !border-b-[#4fd0ff] text-gray-800' : 'text-gray-600'}`}>Permissions</Tab>

                    </TabList>
                    <TabPanel>
                        <div className=" pl-5 max-w-[70vw]">
                            <form onSubmit={handleNext}>
                                <div className='grid grid-cols-2 gap-x-[3vw] gap-y-[2vh]'>
                                    {/* First Name */}
                                    <div className='flex flex-col py-[1vh]'>
                                        <label className="py-[1vh] font-[500]">First name*:</label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            className="py-[0.8vh] rounded border border-gray-600 px-[0.5vw] bg-white h-[38px]"
                                            value={newUserDetail.first_name}
                                            onChange={handleSetDetail}
                                            placeholder="Enter first name"
                                            required
                                            maxLength='40'
                                        />    {errors.first_name && <span className="text-red-600 text-sm">{errors.first_name}</span>}

                                    </div>

                                    {/* Last Name */}
                                    <div className='flex flex-col py-[1vh]'>
                                        <label className="py-[1vh] font-[500]">Last name*:</label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            className="py-[0.8vh] rounded border border-gray-600 px-[0.5vw] bg-white h-[38px]"
                                            value={newUserDetail.last_name}
                                            onChange={handleSetDetail}
                                            placeholder="Enter last name"
                                            required
                                            maxLength='40'
                                        />{errors.last_name && <span className="text-red-600 text-sm">{errors.last_name}</span>}
                                    </div>

                                    {/* Email Address */}
                                    <div className='flex flex-col py-[1vh]'>
                                        <label className="py-[1vh] font-[500]">Email address*:</label>
                                        <input
                                            type="email"
                                            name="email"
                                            className={`py-[0.8vh] rounded border border-gray-600 px-[0.5vw]  h-[38px] ${editWindow && "bg-gray-200"}`}
                                            value={newUserDetail.email}
                                            onChange={handleSetDetail}
                                            placeholder="Enter email"
                                            disabled={editWindow}
                                            required
                                            maxLength='254'
                                        />    {errors.email && <span className="text-red-600 text-sm">{errors.email}</span>}

                                    </div>

                                    {/* Job Role (Select Box) */}
                                    <div className='flex flex-col py-[1vh]'>
                                        <label className='font-[500] py-[1vh]'>Role name*:</label>
                                        {/* <select
                                            name='title'
                                            value={newUserDetail.title}
                                            onChange={handleSetDetail}
                                            className='py-[0.8vh] rounded border border-gray-600 px-[0.5vw] bg-white h-[38px] appearance-none'
                                        >
                                            <option value="">Select...</option>
                                            {jobList.map((job, index) => (
                                                <option key={index} value={job}>
                                                    {job}
                                                </option>
                                            ))}
                                        </select> */}
                                        <input
                                            type="text"
                                            name="title"
                                            className="py-[0.8vh] rounded border border-gray-600 px-[0.5vw] bg-white h-[38px]"
                                            value={newUserDetail.title}
                                            onChange={handleSetDetail}
                                            placeholder="Enter role name"
                                            required
                                            maxLength='254'
                                        />{errors.title && <span className="text-red-600 text-sm">{errors.title}</span>}
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <button className='bg-blue-600 px-4 py-1 border border-blue-600 rounded-sm text-white hover:bg-white hover:text-blue-900 '>Next Step</button></div>
                                {/* Permissions and Help */}

                                {/* Add and Cancel Buttons */}

                            </form>

                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="relative h-[70vh] flex flex-col ">
                            {/* Scrollable Content - Takes Remaining Height */}
                            <div className="flex-1 overflow-y-auto p-3 text-[0.95em] pl-5">
                                {pageList.map((item) => (
                                    <div key={item._id}>
                                        {/* Parent Item */}
                                        <div className="flex flex-row items-center gap-x-2 py-2 border-b border-gray-200">
                                            <button
                                                onClick={() => toggleExpand(item._id)}
                                                className={`text-gray-600 w-6 h-6 flex items-center justify-center  ${expandedItems.includes(item._id) ? "text-xl" : "text-lg"} `}
                                            >
                                                {expandedItems.includes(item._id) ? "▼" : "▶"}
                                            </button>

                                            {/* <div className='w-1 h-1 bg-blue-600 rounded-full'></div> */}
                                            <div className="w-80 text-black bg-">{item.name}</div>
                                            <div>
                                                <select
                                                    className={`p-[5px] bg-gray-100 border border-blue-300 text-black rounded-md 
        ${getPermissionLevel(item._id) === 'partial' && "text-gray-700"}`}
                                                    value={getPermissionLevel(item._id)}
                                                    onChange={(e) => handleSetPermission(item._id, e.target.value)}
                                                >
                                                    <option value="full access">Full Access</option>
                                                    <option value="read">Read-Only</option>
                                                    <option value="no access">No Access</option>
                                                    <option value="partial" className='text-gray-600' disabled>Partial</option>
                                                </select>


                                            </div>
                                        </div>

                                        {/* Children Items */}
                                        {expandedItems.includes(item._id) && item.children?.length > 0 && (
                                            <div className="pl-11  border-b border-[#aec3ca]">
                                                {item.children.map((child) => (
                                                    <div key={child._id} className="flex flex-row items-center gap-x-2  py-2">
                                                        {/* <div className='w-1 h-1 bg-gray-400 rounded-full'></div> */}
                                                        <div className="w-[309px] text-gray-800">{child.name}</div>
                                                        <div>
                                                            <select
                                                                className="p-[5px] bg-gray-100 border border-blue-300 text-gray-700 rounded-md"
                                                                onChange={(e) => handleSetPermission(child._id, e.target.value)}
                                                                value={getPermissionLevel(child._id)}  // Dynamically fetch permission value

                                                            >
                                                                <option value="full access">Full Access</option>
                                                                <option value="read">Read-Only</option>
                                                                <option value="no access">No Access</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    </div>
                                ))}
                            </div>

                            {/* Reserved Bottom Bar (100px height) */}
                            <div className="min-h-[45px]  pr-9  py-1 border-t-2 border-t-blue-500 flex justify-end gap-3">
                                <button
                                    className="py-[1vh] max-h-[6vh] px-[1vw] bg-blue-600 text-white  rounded hover:bg-blue-700 transition"
                                    title={!permissions.some(permission => permission.pagePath === '/cms/userroles/' && permission.permissionLevel === 'full access') ? "You don't have permissions" : ""}
                                    onClick={(e) => { editWindow ? handleUpdateSubmit(e) : handleAddUser(e) }}
                                >
                                    Save
                                </button>
                                <button
                                    className="py-[1vh] max-h-[6vh] px-[1vw] border border-blue-600 text-blue-600  rounded hover:bg-blue-600 hover:text-white transition"
                                    type="button"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                                <ConfirmationDialog
                                    isOpen={showConfirmation}
                                    onClose={handleCloseDialog}
                                    onConfirm={handleConfirmCancel}
                                />
                            </div>
                        </div>
                    </TabPanel>

                </Tabs>
            </div>
        </div >

    )
}

export default AddPermission;