export const SET_COMPANY_ID = 'SET_COMPANY_ID';
export const SET_FILE_ID = 'SET_FILE_ID';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_INSTALLER_ID= 'SET_INSTALLER_ID';
export const SET_COMPUTER_NAME='SET_COMPUTER_NAME';
export const SET_CUSTOMER_NAME='SET_CUSTOMER_NAME';
export const SET_CURRENT_KEY='SET_CURRENT_KEY';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_ORG_ID = 'SET_ORG_ID';
export const SET_DEFAULT_COMPANY_ID = 'SET_DEFAULT_COMPANY_ID';
