// reducer.js

import { SET_COMPANY_ID, SET_FILE_ID, SET_USER_ID, SET_INSTALLER_ID, SET_COMPUTER_NAME, SET_CUSTOMER_NAME, SET_CURRENT_KEY, SET_PERMISSIONS, SET_PRODUCTS, SET_ORG_ID, SET_DEFAULT_COMPANY_ID } from './companyid.action_type';

const initialState = {
  companyId: "",
  fileId: "",
  userId: "",
  insatllerId: '',
  computerName: '',
  customerName: '',
  currentLicenseKey: '',
  permissions: [],
  products: [],
  orgId:'',
  defaultCompanyId:''
};

const companyidreducer = (state = initialState, action) => {
// console.log(state,"idsss");

  switch (action.type) {
    case SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload,
      };
      case SET_DEFAULT_COMPANY_ID:
        return {
          ...state,
          defaultCompanyId: action.payload,
        };
    case SET_FILE_ID:
      return {
        ...state,
        fileId: action.payload,
      }
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      }
      case SET_ORG_ID:
        return {
          ...state,
          orgId: action.payload,
        }
    case SET_INSTALLER_ID:
      return {
        ...state,
        insatllerId: action.payload
      }
    case SET_COMPUTER_NAME:
      return {
        ...state,
        computerName: action.payload
      }
    case SET_CUSTOMER_NAME:
      return {
        ...state,
        customerName: action.payload
      }
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      }
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case SET_CURRENT_KEY:
      return {
        ...state,
        currentLicenseKey: action.payload
      }
    default:
      return state;
  }
};

export { companyidreducer };
