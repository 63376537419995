// export const handleHttpError = (status) => {
//     const errorMessages = {
//         400: "Bad Request",
//         401: "Unauthorized",
//         403: "Forbidden",
//         404: "Not Found",
//         500: "Internal Server Error"
//     };
//     return errorMessages[status] || "Unknown Error"; // Always return a string
// };

import createError from "http-errors";

export const handleHttpError = (status) => {
    const error = createError(status);
    return error.message || "Unknown Error"; // Always return a string
};
