import React from 'react';

// ✅ Utility function to format a UTC date string into "DD/MM/YYYY" format
export const formatDate = (date) => {
  if (!date) return ""; // Return empty string if no date is provided

  const utcDate = new Date(date);
  if (isNaN(utcDate.getTime())) return ""; // Return empty string if invalid date

  const day = String(utcDate.getUTCDate()).padStart(2, '0');
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = String(utcDate.getUTCFullYear()); // Get full year

  return `${day}/${month}/${year}`;
};

// ✅ Reusable React component for displaying formatted dates
const FormatDate = ({ date }) => {
  const formattedDate = formatDate(date);
  return formattedDate ? <span>{formattedDate}</span> : null; // Show nothing if no valid date
};

export default FormatDate;
