import { saveAs } from "file-saver";

export const exportToCSV = (data, columns, fileName = "exported_data") => {
    if (!data || !data.length) {
        alert("No data to export!");
        return;
    }
// console.log(data,"data");

    // Generate CSV Header
    const header = columns.map(col => `"${col.name}"`).join(",") + "\n";

    // Generate CSV Rows with formatted date conversion
    const rows = data.map(item =>
        columns.map(col => {
            let value = item[col.value];

            // Convert date fields to "DD MMM YYYY" format
            if (col.type === "date" && value) {
                const date = new Date(value);
                value = isNaN(date) ? value : date.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" });
            }
   // If value is an array of objects, extract the "name" key and join with commas
   if (Array.isArray(value) && value.every(val => typeof val === "object" && val !== null && "name" in val)) {
    value = value.map(obj => obj.name).join(", "); // Extract "name" values
}
            // Convert arrays to a comma-separated string without extra quotes
            if (Array.isArray(value)) {
                value = value.join(", "); // Keeps array in one column
            }

            // Escape double quotes to avoid breaking CSV format
            if (typeof value === "string") {
                value = `"${value.replace(/"/g, '""')}"`; // Escape double quotes
            }

            return value;
        }).join(",")
    ).join("\n");

    // Create CSV Content
    const blob = new Blob([header + rows], { type: "text/csv;charset=utf-8;" });

    saveAs(blob, `${fileName}.csv`);
};



import { jsPDF } from "jspdf";
import "jspdf-autotable";

export const exportToPDF = (data, columns, fileName = "exported_data") => {
    if (!data || !data.length) {
        alert("No data to export!");
        return;
    }

    console.log("Starting PDF export...");
    console.log("Columns:", columns);
    console.log("Data:", data);

    const doc = new jsPDF();
    doc.text(`${fileName}`, 14, 10);

    try {
        // Extract headers (same as CSV logic)
        const tableColumn = columns.map(col => col.name);

        // Extract rows with proper formatting
        const tableRows = data.map(item =>
            columns.map(col => {
                let value = item[col.value];

                // Convert arrays to a comma-separated string
                if (Array.isArray(value)) {
                    value = value.join(", ");
                }

                // Handle undefined/null values
                return value !== undefined && value !== null ? value.toString() : "N/A";
            })
        );

        console.log("Table Columns:", tableColumn);
        console.log("Table Rows:", tableRows);

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 20,
            styles: { fontSize: 10 },
        });

        console.log("Saving PDF...");
        doc.save(`${fileName}.pdf`);
        console.log("PDF Saved Successfully!");
    } catch (error) {
        console.error("Error in autoTable:", error);
    }
};



