import React, { startTransition, useEffect, useState, useRef } from 'react'
import Sidebar from '../../common/Sidebar'
// import Loader from '../common/Loader'
import ClipboardJS from 'clipboard'
import Loader from '../../common/Loader'
import '../../css/index.css'
import { FaInfoCircle } from "react-icons/fa";
import { IoInformation } from "react-icons/io5";
import { IoInformationCircleOutline } from "react-icons/io5";

import { IoMdHome } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom'
// import FilterSection from '../common/FilterSection'
import { FaCheckDouble, FaFilter, FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setFileId, setCompanyId, setUserId } from '../../../Redux/companyid/companyid.action';
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import FilterSection from '../../common/FilterSection';
import DataEntriesNo from '../../components/pagination/DataEntriesNo'
import PageNumber from '../../components/pagination/PageNumber'
import { ImCross } from 'react-icons/im'
import { LuRefreshCcw } from 'react-icons/lu'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchLicenceList, setSortOptions, updateRow } from '../../../Redux/licence/licenceCreate.action';
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import SidebarLicense from '../../common/SidebarLicense';
import SidebarAdmin from '../../common/SidebarAdmin';
import { Helmet } from 'react-helmet';
import CopyButton from '../../../utils/CopyButton'
import { checkAuthentication } from '../../../Redux/auth/auth.action'
import ChangeLicenseCredit from '../../components/ChangeLicenseCredit'
import { RiComputerLine } from 'react-icons/ri'
import { SET_SORT_OPTIONS_LICENCE_LIST } from '../../../Redux/licence/licence.action_type'
import { type } from '@testing-library/user-event/dist/type'
import '../../css/license.css'
import Error from '../../../pages/Error'
import { calculateDailyRate, calculateExpiryDays } from '../../../utils/ExpiryDays'
import { FaHistory } from "react-icons/fa";
import { checkAdminAuth } from '../../../Redux/adminAuth/adminAuth.action'
import { TbExclamationCircle } from 'react-icons/tb'
import { TfiUnlink } from "react-icons/tfi";
import { FaUnlink } from "react-icons/fa";
import { setPageNumber } from '../../../Redux/licence/licenceCreate.action'
import FormatDate from '../../../utils/FormatDate'
import { DateFormat1 } from '../../../utils/DateFormat'
import { BiInfoCircle } from 'react-icons/bi'
import { FaCircleInfo } from 'react-icons/fa6'

const CreateLicense2 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openShowHide, setOpenShowHide] = useState(false);
    const tableRef = useRef(null);
    const [tableHeight, setTableHeight] = useState("auto");
    const { userId, licenceList,
        pageNumber, totalRecords, sortOrder, sortBy, filters, loading, actionList, lastCroneDateTime, nextRunAt, adminAuthData, errorAdminAuth } = useSelector((state) => ({
            //   companyId: state.companyid.companyId,
            userId: state.companyid.userId,
            licenceList: state.licence.licenceList,
            sortOrder: state.licence.sortOrder,
            pageNumber: state.licence.pageNumber,
            totalRecords: state.licence.totalRecords,
            sortBy: state.licence.sortBy,
            sortOrder: state.licence.sortOrder,
            filters: state.licence.filters,
            loading: state.licence.loading,
            actionList: state.licence.actionList,
            lastCroneDateTime: state.licence.lastCroneDateTime,
            nextRunAt: state.licence.nextRunAt,
            adminAuthData: state.adminAuth.adminAuthData,
            errorAdminAuth: state.adminAuth.errorAdminAuth
        }));
    let newSize;
    const fieldParam = new URLSearchParams(location.search).get('field');
    const operandParam = new URLSearchParams(location.search).get('operand');
    const valueParam = new URLSearchParams(location.search).get('value');
    const excludeParam = new URLSearchParams(location.search).get('exclude');
    const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
    const pageNumberParam = new URLSearchParams(location.search).get('page');
    const sortByParam = new URLSearchParams(location.search).get('sortBy');
    const sortOrderParam = new URLSearchParams(location.search).get('sortOrder');
    // Assuming the values are stored as JSON strings in the URL, parse them
    const field = fieldParam ? JSON.parse(fieldParam) : [];
    const operand = operandParam ? JSON.parse(operandParam) : [];
    const value = valueParam ? JSON.parse(valueParam) : [];
    const exclude = excludeParam ? JSON.parse(excludeParam) : [];
    const pageSize = pageSizeParam ? JSON.parse(pageSizeParam) : [];
    const page = pageNumberParam ? JSON.parse(pageNumberParam) : [];
    const sortByCol = sortByParam ? JSON.parse(sortByParam) : [];
    const sortOrderCol = sortOrderParam ? JSON.parse(sortOrderParam) : [];

    const [pageSizeFromURL, setPageSizeFromURL] = useState(100);
    const [currPage, setCurrPage] = useState(1);
    const [filterChange, setFilterChange] = useState();
    const [showFilter, setShowFilter] = useState(false);
    const handleFilter = () => {
        setShowFilter(!showFilter);
    }
    let filtersvalue = React.useMemo(() => {
        return {
            filters: {
                field,
                operand,
                value,
                exclude,
            },
        };
    }, [field, operand, value, exclude]);
    const filtersValueRef = React.createRef(filtersvalue);
    const pageSizeRef = React.createRef(pageSize);
    const [showConfirmationModal, setShowConfirmationModal] = useState(null);

    useEffect(() => {
        const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
        const parsedSize = pageSizeParam ? parseInt(pageSizeParam) : pageSizeFromURL; // Set default if not provided
        setPageSizeFromURL(parsedSize);
        const pageNumberParam = new URLSearchParams(location.search).get('page');
        const parsedPage = pageNumberParam ? parseInt(pageNumberParam) : currPage;
        setCurrPage(parsedPage);
    }, [location.search]);
    const [currentTime, setCurrentTime] = useState(getCurrentTime()); // Initial state with current time

    // Function to get current time
    function getCurrentTime() {
        const now = new Date();

        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = String(now.getFullYear()).slice(-2); // Get last two digits of the year
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }



    // Function to add leading zero if needed
    function addZeroIfNeeded(value) {
        return value < 10 ? '0' + value : value;
    }
    const [activeArrow, setActiveArrow] = useState({
        columnName: sortByCol.length > 0 ? sortByCol[0] : "Created at", // Extract first element or use default
        direction: sortOrderCol.length > 0 ? sortOrderCol[0] : -1, // Extract first element or use default
    });
    // Function to update current time
    function updateTime() {
        setCurrentTime(getCurrentTime());
        setUpdatedData(!updatedData);
    }
    const [updatedData, setUpdatedData] = useState(false);
    const [showNewLicence, setShowNewLicence] = useState(false);
    const [changeCredit, setChangeCredit] = useState({
        openWindow: 'false',
        type: '',
        productName: ''
    });
    const initialState = {
        name: '',
        product: [
            {
                name: "DLP", daily_rate: 0, on_installation: true, checked: true, start_date: '',
                no_computer: 0, on_account_link: false, on_duration: false, hint_computers: 5, product_code: 'DLP_EP', hint_checkbox: true,
                features: 'DC;NC;AC'
                // ,status:''
            },
            {
                name: "AMS", daily_rate: 0, on_installation: true, checked: true, start_date: ''
                , no_computer: 0, on_account_link: false, on_duration: false, hint_computers: 5, hint_checkbox: true,
                product_code: 'AMS_EP', features: 'Q;D;AS'
                // ,status:''
            },
            {
                name: "NAS", daily_rate: 0, on_installation: true, checked: false, start_date: ''
                , no_computer: 0, on_account_link: false, on_duration: false, hint_computers: 5, hint_checkbox: true,
                product_code: 'NAS_EP', features: 'N;A;S'
                // ,status:''
            }
        ],
        total_credits: 0,
        added_credits: 0,
        subtracted_credits: 0,
        status: '',
        remark: '',
        createdBy: "",
        createdAt: "",
        max_negative_credits: parseFloat((0.0833 * 0).toFixed(2)) // Initial value based on 8.83% of total_credits
    };
    const [licenceInfo, setLicenceInfo] = useState(initialState)
    const [duration, setDuration] = useState(false);
    const [changeLicense, setChangeLicence] = useState(false);


    useEffect(() => {
        const currentURL = window.location.pathname;

        // Remove any query parameters or fragments from the URL
        const cleanURL = currentURL.split('?')[0];

        // Split the cleaned URL by '/'
        const parts = cleanURL.split('/');

        // Extract the last part (userid in this case)
        const userid = parts[parts.length - 1];

        const fileid = null;  // Assuming you want fileid to be null

        dispatch(setUserId(userid))
        dispatch(setFileId(fileid));

        if (userid) {
            dispatch(fetchLicenceList(userid, pageSizeFromURL, currPage, sortBy, sortOrder, filtersvalue));
        }

        setCurrentTime(getCurrentTime());
    }, [dispatch, pageNumber, pageSizeFromURL, currPage, filterChange, currentTime, changeLicense, updatedData]);

    const columnsLicense = [
        { id: 0, name: "Linked to", value: "Linked to", type: 'string' },
        { id: 1, name: "Status", value: "status", type: 'string' },
        { id: 2, name: "Key", value: "key", type: 'string' },
        { id: 3, name: "Total Credits", value: "total_credits", type: 'number' },
        { id: 4, name: "Expiry date", value: "Due date", type: 'date' },

        { id: 5, name: "Channel partner", value: "channel partner", type: 'string' },

        // { id: 4, name: "Linked email", value: "Linked email", type: 'string' },
        // { id: 6, name: "Created by", value: "Created By", type: 'string' },
        // { id: 7, name: "Created at", value: 'Created at', type: 'date' },
    ]

    const [checkedRows, setCheckedRows] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(columnsLicense);
    const [checkedColumns, setCheckedColumns] = useState(columnsLicense.map(() => true));

    const handleCheckboxChange = (event, index) => {
        const { checked } = event.target;
        const column = columnsLicense[index];

        if (!column) {
            console.error("Column is undefined for index:", index);
            return;
        }

        setCheckedColumns((prevState) => {
            const updatedCheckedColumns = [...prevState];
            updatedCheckedColumns[index] = checked;
            return updatedCheckedColumns;
        });

        setVisibleColumns((prevState) => {
            // Avoid modifying state directly
            if (checked) {
                // Add column if not already in the list
                const updatedVisibleColumns = [...prevState];
                if (!updatedVisibleColumns.find((col) => col.id === column.id)) {
                    updatedVisibleColumns.push(column);
                    updatedVisibleColumns.sort((a, b) => a.id - b.id); // Keep columns sorted
                }
                return updatedVisibleColumns;
            } else {
                // Filter out the column if unchecked
                return prevState.filter((col) => col.id !== column.id);
            }
        });
    };

    const selectAllColumns = () => {
        setCheckedColumns(columnsLicense.map(() => true)); // Mark all columns as checked
        setVisibleColumns(columnsLicense);
    };

    // Function to deselect all columns
    const deselectAllColumns = () => {
        setCheckedColumns(columnsLicense.map(() => false)); // Mark all columns as unchecked
        setVisibleColumns([]); // Hide all columns
    };

    const handleSort = (columnName, newSortOrder) => {
        //   console.log(columnName,newSortOrder,"sortOrders");
        setActiveArrow({
            columnName: columnName,
            direction: newSortOrder
        })

        // dispatch(setSortOptions(columnName, newSortOrder));
        dispatch(setSortOptions(columnName, newSortOrder));
        const searchParams = new URLSearchParams(location.search);

        // Update the sortBy and sortOrder parameters
        searchParams.set('sortBy', JSON.stringify([columnName])); // Save the sorted column
        searchParams.set('sortOrder', JSON.stringify([newSortOrder])); // Save the sort order

        // Use navigate to update the URL with the new query parameters
        navigate({
            pathname: location.pathname, // Keep the current pathname
            search: searchParams.toString(), // Apply the updated search parameters
        });
    };

    const [showModal, setShowModal] = useState(false);
    const [uncheckedProduct, setUncheckedProduct] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [remark, setRemark] = useState('');
    const handleModalConfirm = () => {
        // Proceed with unchecking the product
        if (currentProduct) {
            const event = {
                target: {
                    name: currentProduct.name,
                    checked: false,
                },
            };
            handleLicenceInfoChange(event);
        }
        setShowModal(false);
        setCurrentProduct(null);
    };

    const handleModalCancel = () => {
        // Close the modal without making any changes
        setShowModal(false);
        setCurrentProduct(null);
    };


    let sortedData;
    if (licenceList !== undefined) {
        sortedData = licenceList?.sort((a, b) => {
            if (activeArrow.columnName) {
                // Define a function to handle nested properties
                const getNestedValue = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj) ?? '';

                // Determine the property path based on activeArrow.columnName
                let propertyPath;
                switch (activeArrow.columnName) {
                    case 'company_name':
                        propertyPath = 'linked_user_id.company_name';
                        break;
                    case 'email':
                        propertyPath = 'linked_user_id.email';
                        break;
                    case 'created_by':
                        propertyPath = 'created_by.first_name'; // Sort by first name as an example
                        break;
                    default:
                        propertyPath = activeArrow.columnName;
                }

                const getValue = obj => getNestedValue(obj, propertyPath);

                if (activeArrow.direction === 1) {
                    if (typeof getValue(a) === "number" && typeof getValue(b) === "number") {
                        // Number comparison for ascending order
                        return getValue(a) - getValue(b);
                    }
                    // String comparison for ascending order
                    return getValue(a).toString().localeCompare(getValue(b).toString());
                } else {
                    if (typeof getValue(a) === "number" && typeof getValue(b) === "number") {
                        // Number comparison for descending order
                        return getValue(b) - getValue(a);
                    }
                    // String comparison for descending order
                    return getValue(b).toString().localeCompare(getValue(a).toString());
                }
            }
            return 0;
        });
    }


    const handlePageSizeChange = (event) => {
        event.preventDefault();
        newSize = parseInt(event.target.value);
        // dispatch(setPageSize(newSize))
        const existingParams = new URLSearchParams(location.search);
        existingParams.set('pageSize', JSON.stringify(newSize)); // Update or add page size

        const queryString = existingParams.toString();

        navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

    };

    const [isPercentage, setIsPercentage] = useState(true); // State to track percentage option
    const [maxNegativeCredits, setMaxNegativeCredits] = useState(0); // State for max_negative_credits

    const handleOptionChange = (value) => {
        setIsPercentage(value === 'percentage');
        if (value === 'percentage') {
            const percentageValue = 0.0833 * licenceInfo.total_credits; // Calculate 8.33% of total_credits
            setMaxNegativeCredits(percentageValue.toFixed(2)); // Set to fixed 2 decimal places
        } else {
            setMaxNegativeCredits(licenceInfo.max_negative_credits); // Use current absolute value
        }
    };
    const [licenseUpdateStatus, setLicenseUpdateStatus] = useState({
        window: false,
        confirm: ""
    });
    const [licenseCreditsWarning, setLicenseCreditsWarning] = useState({
        window: false,
        confirm: ""
    });
    const handleLicenseStatusChange = (statusConfirm) => {
        // console.log(statusConfirm);

        if (statusConfirm === "Yes") {
            setLicenseUpdateStatus((prevState) => ({
                ...prevState,
                window: false,
                confirm: statusConfirm
            }));

            handleUpdateSubmit();  // Call the submit function if "Yes" is selected
        } else {
            setLicenseUpdateStatus({
                window: false,
                confirm: statusConfirm
            });
        }
    };

    const handleLicenseCreditsWarning = (statusConfirm) => {
        // console.log(statusConfirm);

        if (statusConfirm === "Yes") {
            setLicenseCreditsWarning((prevState) => ({
                ...prevState,
                window: false,
                confirm: statusConfirm
            }));

            handleUpdateSubmit();  // Call the submit function if "Yes" is selected
        } else {
            setLicenseCreditsWarning({
                window: false,
                confirm: statusConfirm
            });
        }
    };


    const [licenceId, setLicenceId] = useState('');
    const [addNew, setAddNew] = useState([]);
    const [currentKey, setCurrentKey] = useState('');
    const [licenseStatusActual, setLicenseStatusActual] = useState("");
    const [editWindowLinkedData, setEditWindowLinkedData] = useState({ dueDate: "", userId: '', licenseId: '', orgId: '' });
    const handleEditLicence = (index, orgId, orgName, product, totalCredits, id, remark, licenseStatus, key, linkedLicenseObject, licenseDueDate, createdBy, createdAt) => {
        // console.log(remark, "Remark");
        setLicenseStatusActual(licenseStatus);
        setShowNewLicence(true);
        setExpiryDays(0);
        setCurrentKey(key);
        setEditWindowLinkedData({
            dueDate: licenseDueDate, // or any specific date you want to set
            userId: linkedLicenseObject?._id,
            licenseId: linkedLicenseObject?.linked_license,
            orgId: orgId
        });
        const updatedProducts = initialState.product.map(initialProduct => {
            const existingProduct = product.find(item => item.name === initialProduct.name);
            if (existingProduct) {
                // let statusValue = "default"; 
                // Set the default status value here
                if (existingProduct?.on_account_link === false && existingProduct?.on_installation === false) {
                    // statusValue = "started";
                    return {
                        ...existingProduct,
                        // start_date: start_date,
                        on_duration: true,
                        on_account_link: false,
                        on_installation: false,
                        checked: true,
                        hint_computers: 5,

                    };
                } else if (existingProduct.on_installation === true) {
                    // statusValue = "installation";
                    return {
                        ...existingProduct,
                        on_account_link: false,
                        on_duration: false,
                        on_installation: true,
                        checked: true,
                        hint_computers: 5,

                    };
                } else if (existingProduct.on_account_link === true) {
                    // statusValue = "no_computer";
                    return {
                        ...existingProduct,
                        on_account_link: true,
                        on_duration: false,
                        on_installation: false,
                        checked: true,

                    };
                } else {
                    // statusValue = "checked";
                    return {
                        ...existingProduct,
                        checked: true,

                    };
                }
            } else {
                return {
                    ...initialProduct,
                    checked: false,
                    // Status for products in initialState but not in product array
                };
            }

        });
        setLicenceInfo(prevState => ({
            ...prevState,
            product: updatedProducts,
            total_credits: totalCredits,
            remark: remark,
            status: licenseStatus,
            orgName: orgName,
            createdAt: createdAt,
            createdBy: createdBy
        }));
        const newExpiryDays = calculateExpiryDays({ totalCredit: licenceInfo.total_credits, product: licenceInfo.product, lastCroneDateTime, nextRunAt });
        setExpiryDays(newExpiryDays);
        setLicenceId(id);
        setEditWindow(true);

    };

    const handleUpdateSubmit = async (e) => {
        if (e) e.preventDefault(); // Safely handle cases where the function is called without an event

        // Check if the license status is EXPIRED and user hasn't confirmed the change
        if (
            ["EXPIRED", "INVALID", "DEACTIVE"].includes(licenceInfo.status) &&
            licenseUpdateStatus.confirm !== "Yes"
        ) {
            setLicenseUpdateStatus((prevState) => ({
                ...prevState,
                window: true, // Show confirmation window for specific statuses
            }));
            return;
        }
        const remainingCredits = licenceInfo.total_credits / calculateDailyRate(licenceInfo.total_credits, licenceInfo.product);
        // console.log(remainingCredits, "remaining credits");

        // Return early if remainingCredits is less than 1


        if (
            licenceInfo.status === "ACTIVE" &&
            licenseCreditsWarning.confirm !== "Yes" && remainingCredits < 1
        ) {
            setLicenseCreditsWarning((prevState) => ({
                ...prevState,
                window: true, // Show confirmation window for specific statuses
            }));
            return;
        }


        const hasSelectedProduct = licenceInfo.product.some((item) => item.checked);

        if (!hasSelectedProduct) {
            toast.error(
                ("Please select at least one product."),
                {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            ); return;
        }
        // console.log("submitted");
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/license/update`,
            {
                // "total_credits": licenceInfo.total_credits,
                "added_credits": licenceInfo.added_credits,
                "subtracted_credits": licenceInfo.subtracted_credits,
                "remark": licenceInfo.remark,
                "created_by": userId,
                "product": specificFields,
                "licenseId": licenceId,
                "status": licenceInfo.status
                // "max_negative_credits": -licenceInfo.max_negative_credits
            }
        )
            .then(response => {
                if (response.headers['content-type'].includes('application/json')) {
                    // If the response is JSON, parse it and log the data
                    toast.success(response.data.message)

                } else {
                    // If the response is not JSON, log the response as text

                }
                setEditWindow(false);
                toast.success(response.data);
                setChangeLicence(!changeLicense);
                setLicenceInfo(initialState);
                setLicenseUpdateStatus({ window: false, confirm: "" });
                setLicenseCreditsWarning({ window: false, confirm: "" })
                setShowNewLicence(!showNewLicence);


            })
            .catch(error => {

                toast.error(error?.response?.data || error?.message);
                setLicenseUpdateStatus({ window: false, confirm: "" });
                setLicenseCreditsWarning({ window: false, confirm: "" })
                // setEditWindow(false);
            });

        // setLicenceId('');

        // setExpiryDays(0);
    }
    const handleDeleteLicence = (licenceId) => {
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/license/delete`, {
            "licenseId": licenceId,
            "created_by": userId
        })
            .then(response => {
                if (response.headers['content-type'].includes('application/json')) {
                    // If the response is JSON, parse it and log the data
                    setShowConfirmationModal(false);
                } else {
                    // If the response is not JSON, log the response as text
                    setShowConfirmationModal(false);

                }
                toast.success(response.data);
                // console.log(response.data, "delete message");
                setChangeLicence(!changeLicense);
            })
            .catch(error => {
                setShowConfirmationModal(false);

                toast.error(error.message);
            });

        setExpiryDays(0);

    }
    const [expiryDays, setExpiryDays] = useState('');

    const resetTime = (date) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    };
    const statusMapping = {
        INVALID: ["INVALID", "UNLINKED"],
        EXPIRED: ["EXPIRED", "ACTIVE"],
        ACTIVE: ["ACTIVE", "DEACTIVE"],
        DEACTIVE: ["DEACTIVE", "ACTIVE"],
        UNLINKED: ["UNLINKED", "INVALID"],
        REMOVED: ["REMOVED"],
    };

    // Get the allowed options for the current `licenseStatusActual`
    const filteredActionList = statusMapping[licenseStatusActual] || [];

    const [specificFields, setSpecificFields] = useState([]);
    const handleLicenceInfoChange = (e) => {
        const { name, value, type } = e.target;
        const newValue = type === 'checkbox' ? e.target.checked : value;

        setLicenceInfo((prevState) => {
            const updatedProducts = prevState.product.map((item) => {
                if (name.startsWith('deductionType_')) {
                    const productName = name.split('_')[1];
                    if (item.name === productName) {
                        return {
                            ...item,
                            on_installation: value === "onInstallation",
                            on_duration: value === "duration",
                            on_account_link: value === "onaccount_link",
                        };
                    }
                } else if (name.startsWith('startDate_') || name.startsWith('numberOfComputers_')) {
                    const [field, productName] = name.split('_');
                    if (item.name === productName) {
                        return {
                            ...item,
                            [field === 'startDate' ? 'start_date' : 'no_computer']: field === 'numberOfComputers' ? Number(newValue) : newValue ? new Date(newValue).toISOString() : '',
                        };
                    }
                } else if (name.startsWith('dailyRate_')) {
                    const productName = name.split('_')[1];
                    if (item.name === productName) {
                        return {
                            ...item,
                            daily_rate: Number(newValue),
                        };
                    }
                }
                else if (name.startsWith('productStatus_')) {
                    // console.log(newValue, "newValueforProduct");
                    const productName = name.split('_')[1];
                    if (item.name === productName) {
                        return {
                            ...item,
                            status: newValue,
                        };
                    }
                }
                else if (name.startsWith('productCode')) {
                    const productName = name.split('_')[1];
                    if (item.name === productName) {
                        return {
                            ...item,
                            product_code: newValue,
                        };
                    }
                } else if (name.startsWith('productFeatures')) {
                    const productName = name.split('_')[1];
                    if (item.name === productName) {
                        return {
                            ...item,
                            features: newValue,
                        };
                    }
                }
                else if (name.startsWith('hintComputers_')) {
                    const productName = name.split('_')[1];
                    if (item.name === productName) {
                        return {
                            ...item,
                            hint_computers: Number(newValue),
                        };
                    }

                }
                else if (name.startsWith('hintCheckbox_')) {
                    const productName = name.split('_')[1];
                    if (item.name === productName) {
                        return {
                            ...item,
                            hint_checkbox: (newValue),
                        };
                    }

                }
                else if (item.name === name) {
                    return {
                        ...item,
                        checked: newValue,
                    };
                }
                return item;
            });

            const totalCredits = name === 'totalCredits' ? Number(newValue) : prevState.total_credits;
            const remark = name === 'remark' ? newValue : prevState.remark;
            const status = name === 'status' ? newValue : prevState.status;

            let maxNegativeCredits;
            if (isPercentage) {
                maxNegativeCredits = name === 'totalCredits'
                    ? parseFloat((0.0833 * Number(newValue)).toFixed(2))
                    : name === 'maxNegativeCredits'
                        ? parseFloat(Number(newValue).toFixed(2))
                        : parseFloat(licenceInfo.max_negative_credits.toFixed(2));
            } else {
                maxNegativeCredits = name === 'maxNegativeCredits'
                    ? parseFloat(Number(newValue).toFixed(2))
                    : parseFloat(licenceInfo.max_negative_credits.toFixed(2));
            }
            const newExpiryDays = calculateExpiryDays({ totalCredit: licenceInfo.total_credits, product: licenceInfo.product, lastCroneDateTime, nextRunAt });
            setExpiryDays(newExpiryDays);

            return {
                ...prevState,
                product: updatedProducts,
                total_credits: totalCredits,
                remark: remark,
                status: status,
                max_negative_credits: maxNegativeCredits > totalCredits ? totalCredits : maxNegativeCredits,
            };
        });

        setNegativeCredit((0.0833 * licenceInfo.total_credits).toFixed(2));

        // Only update specificFields if the name does not start with 'hint_computers'
        //   console.log(licenceInfo.product,specificFields,"first_Licen  SEcons_specific");

        if (!name.startsWith('hintComputers')) {
            setSpecificFields((prevState) => {
                const filteredProducts = licenceInfo.product.filter(item => item.checked);

                const updatedProducts = filteredProducts.map(item => {
                    let { hint_computers, ...rest } = item;

                    if (rest.on_installation === true) {
                        const { start_date, no_computer, ...newRest } = rest;
                        rest = newRest;
                    }

                    let updatedRest = {
                        ...rest,
                        on_installation: item.on_installation,
                        on_duration: item.on_duration,
                        on_account_link: item.on_account_link,
                    };

                    const [field, productName] = name.split('_');

                    if (rest.name === productName) {
                        switch (field) {
                            case 'startDate':
                                if (!rest.on_installation) {
                                    updatedRest.start_date = newValue ? new Date(newValue).toISOString() : '';
                                    // console.log(updatedRest.start_date, "startDate");

                                }
                                break;

                            case 'numberOfComputers':
                                if (!rest.on_installation) {
                                    updatedRest.no_computer = Number(newValue);
                                }
                                break;

                            case 'dailyRate':
                                updatedRest.daily_rate = Number(newValue);
                                break;

                            case 'productCode':
                                updatedRest.product_code = newValue;
                                break;

                            case 'productFeatures':
                                updatedRest.features = newValue;
                                break;

                            default:
                                if (rest.name === name) {
                                    updatedRest.checked = newValue;
                                }
                                break;
                        }
                    }

                    return updatedRest;
                });

                return updatedProducts;
            });

        }
    };
    const handleProductCheckChange = (e, product) => {
        const { checked, name } = e.target;

        if (!checked) {
            // Show the modal confirmation when unchecking
            setCurrentProduct(product);
            setShowModal(true);
        } else {
            // Proceed with state update for checking
            handleLicenceInfoChange(e);
        }
    }
    const handleConfirmChange = (newCredits, addedCredit, subtractedCredit, updatedProduct, type) => {
        setLicenceInfo((prevState) => {
            let updatedLicenceInfo = { ...prevState };

            if (type === 'add') {
                updatedLicenceInfo = {
                    ...prevState,
                    total_credits: newCredits,
                    added_credits: prevState.added_credits + addedCredit
                };
            } else if (type === 'subtract') {
                updatedLicenceInfo = {
                    ...prevState,
                    total_credits: newCredits,
                    subtracted_credits: prevState.subtracted_credits + subtractedCredit,
                };
            } else if (type === 'addDays') {
                updatedLicenceInfo = {
                    ...prevState,
                    total_credits: Number(newCredits) + prevState.total_credits,
                    added_credits: prevState.added_credits + Number(newCredits)
                };
            } else if (type === 'dailyRate') {
                updatedLicenceInfo = {
                    ...prevState,
                    product: updatedProduct,
                };
            }

            // Update specificFields
            const filteredProducts = updatedLicenceInfo?.product?.filter(item => item.checked) ?? [];
            const updatedSpecificFields = filteredProducts.map(item => {
                let { hint_computers, ...rest } = item;

                if (rest.on_installation === true) {
                    const { start_date, no_computer, ...newRest } = rest;
                    rest = newRest;
                } else if (rest.on_account_link) {
                    const { start_date, ...newRest } = rest;
                    rest = newRest;
                }

                return {
                    ...rest,
                    on_installation: item.on_installation,
                    on_duration: item.on_duration,
                    on_account_link: item.on_account_link,
                };
            });
            setSpecificFields(updatedSpecificFields);

            return updatedLicenceInfo;
        });

    };
    const handleLicenseHistoryForKey = (key) => {
        if (key) {
            const queryParams = new URLSearchParams();

            // Add the key to the query parameters
            queryParams.set("Key", key);

            // Navigate to the desired route with query params
            navigate(`/cms/licenseHistory/${userId}?${queryParams.toString()}`);
        }
    };

    useEffect(() => {
        const filteredProducts = licenceInfo.product.filter(item => item.checked);

        const updatedSpecificFields = filteredProducts.map(item => {
            let { hint_computers, ...rest } = item;

            if (rest.on_installation === true) {
                const { start_date, no_computer, ...newRest } = rest;
                rest = newRest;
            } else if (rest.on_account_link) {
                const { ...newRest } = rest;
                rest = newRest;
            }

            let updatedRest = {
                ...rest,
                on_installation: item.on_installation,
                on_duration: item.on_duration,
                on_account_link: item.on_account_link,
            };

            return updatedRest;
        });

        setSpecificFields(updatedSpecificFields);
    }, [licenceInfo]);

    useEffect(() => {
        const newExpiry = calculateExpiryDays({ totalCredit: licenceInfo.total_credits, product: licenceInfo.product, lastCroneDateTime, nextRunAt })
        setExpiryDays(newExpiry);
    }, [changeCredit, licenceInfo])
    const [editWindow, setEditWindow] = useState(false);

    const handlePageNumberChange = (newPageNumber) => {
        setCurrPage(newPageNumber);
        const existingParams = new URLSearchParams(location.search);
        existingParams.set('page', JSON.stringify(newPageNumber)); // Update or add page number

        const queryString = existingParams.toString();

        navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

    };
    const handleClick = () => {

    };

    const [negativeCredit, setNegativeCredit] = useState(0.083 * (licenceInfo.total_credits));
    const handleNegativeCredits = (e) => {
        setNegativeCredit(e.target.value);
    }
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [showMoreStates, setShowMoreStates] = useState({});
    // Function to toggle show more/less state for 'details'
    const toggleShowMoreDetails = (rowIndex) => {

        const key = `${rowIndex}-details`;
        setShowMoreStates(prevState => ({
            ...prevState,
            [key]: !prevState[key] // Toggle the show more/less state for the specific row and 'details' value
        }));
    };

    const onApplyFilters = (filters) => {

        // console.log(filters, "filternewVLUE");
        setFilterChange(filters);

    }
    const handleNewLicence = () => {
        setNegativeCredit(0);
        setExpiryDays(0);
        setShowNewLicence(true);
        setLicenceInfo(initialState);
        setLicenseStatusActual("");
    }
    const [isSidebarExpanded, setSidebarExpanded] = useState(true);

    const toggleSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);

    };
    useEffect(() => {
        const updateHeight = () => {
            if (tableRef.current) {
                const offsetTop = tableRef.current.getBoundingClientRect().top;
                const availableHeight = window.innerHeight - offsetTop;
                setTableHeight(`${availableHeight}px`);
            }
        };

        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, []);
    const handleLinkedDeviceNavigate = (productCode, deviceCount, productName) => {
        const data = {
            productCode,
            deviceCount,
            productName,
            dueDate: editWindowLinkedData?.dueDate,
            LinkedUserId: editWindowLinkedData?.userId,
            licenseId: editWindowLinkedData?.licenseId,
            orgId: editWindowLinkedData?.orgId
        };

        // Generate query params
        const queryParams = new URLSearchParams(data).toString();

        // Save the data to localStorage if needed
        // localStorage.setItem(`${productName}linkedDeviceData`, JSON.stringify(data));

        // Navigate to the URL with query params
        navigate(`/cms/linkeddevices/${userId}?${queryParams}`);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const hasSelectedProduct = licenceInfo.product.some((item) => item.checked);

        if (!hasSelectedProduct) {
            toast.error(
                ("Please select at least one product."),
                {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            ); return;
        }
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/license/key/create`,

            {
                // "total_credits": licenceInfo.total_credits,
                "added_credits": licenceInfo.added_credits,
                "subtracted_credits": licenceInfo.subtracted_credits,
                "remark": licenceInfo.remark,
                "created_by": userId,
                "product": specificFields,
                // "max_negative_credits": -licenceInfo.max_negative_credits
            }
        )
            .then(response => {
                if (response.headers['content-type'].includes('application/json')) {
                    // If the response is JSON, parse it and log the data

                } else {

                }
                toast.success(response.data.message)
                setChangeLicence(!changeLicense);
                setLicenceInfo(initialState);
                setShowNewLicence(!showNewLicence);
                // setChangeLicence(!changeLicense);
                setExpiryDays(0);
            })
            .catch(error => {

                toast.error(error?.response?.data || error?.message)

                // setfileUploadMessage(error?.response?.data);
            });
      
    }
    const [unlinkStatusChange, setUnlinkStatusChange] = useState({
        window: false,
        licenseId: "",
        indexUnlink: null
    });

    const handleChangeLicenseStatusAction = () => {

        axios
            .post(`${process.env.REACT_APP_HOST_NAME}/api/license/key/unlink`, {
                userId: userId, // Ensure userId is defined in scope
                licenseId: unlinkStatusChange.licenseId,
            })
            .then((response) => {
                // Display success toast with the message from the response
                toast.success(response?.data?.message || "License status changed successfully!");

                // Update the state to indicate the unlink status change
                setUnlinkStatusChange({
                    window: false,
                    licenseId: ""
                });

                // Perform additional actions like updating page number
                dispatch(updateRow(response?.data?.data));
            })
            .catch((error) => {
                // Handle the error and display a toast message
                console.error(error, "unlink error");

                const errorMessage =
                    error?.response?.data?.message ||
                    error?.response?.data ||
                    error?.message ||
                    "An error occurred while changing the license status.";

                toast.error(errorMessage);

                // Perform additional error handling actions
                dispatch(setPageNumber(3));
            });
    };


    const buttonRef = useRef(null);

    if (loading) return <Loader />;
    // if (!loading && !(adminAuthData?.role?.includes("ADMINISTRATOR"))) {
    //     return <Error />;
    // }
    if (errorAdminAuth) {
        return <Error />;
    }
    return (
        <div className="flex h-screen">
            <Helmet>
                <title>Matisoft | License Management</title>
            </Helmet>
            <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25'} bg-gray-200 h-full z-[42] fixed `}>
                {/* Sidebar Content Goes Here */}
                <SidebarLicense isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
            </div>

            {/* Main Content */}
            <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%]'} overscroll-y-none relative overflow-hidden licenseCreation transition-all`}>
                <div className=" w-full bg-white z-50">
                    <div className={`flex flex-row justify-end items-center px-[2%] pt-[1%] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'}`}>

                        {showNewLicence && <div className={` ${showNewLicence ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full z-[50] bg-gray-600 opacity-50  `} ></div>}

                        {showNewLicence &&
                            <div className='fixed bg-blue-100 opacity-[0.98] border-white-100 border-[1px] rounded w-[90vw] left-[5vw] z-[100] top-[5vh] bottom-[5vh] text-[0.9em] ' style={{ zIndex: '120' }}>
                                <div className='filter-section-content relative z-[100]'>
                                    <button className='absolute right-[-5px] top-[-5px]' onClick={() => { editWindow ? (setEditWindow(false), setShowNewLicence(false)) : setShowNewLicence(false) }}>
                                        <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] cursor-pointer rounded-full' />
                                    </button>
                                    {editWindow === true ? <h1 className='text-[1.3em] font-[500] text-center  border-b border-b-blue-500'>Edit license for <span className='text-blue-700'>{currentKey}</span></h1> :
                                        <h1 className='text-[1.3em]  font-[500] text-center border-b border-b-blue-500'>Create New License</h1>
                                    }                                    <form
                                        onSubmit={(e) => { editWindow ? handleUpdateSubmit(e) : handleSubmit(e); }}
                                        className='flex flex-col text-[0.9em] overflow-y-auto' style={{ maxHeight: 'calc(100vh - 11vh  - 4rem)' }}
                                    >
                                        <div className='py-[1vh] flex flex-row items-center gap-x-[1vw] '>
                                            <label className='text-[1.2em] w-[11vw]' htmlFor="totalCredits">Total Credits*:</label>
                                            <input
                                                className='px-[2px] py-[0.5vh] h-[4vh] break-words resize-none
                                                 '
                                                id="totalCredits"
                                                name="totalCredits"
                                                // defaultValue={10}
                                                // value={licenceInfo.total_credits > 0 && licenceInfo.total_credits}
                                                value={licenceInfo.total_credits}
                                                // placeholder='Enter total credits'
                                                onChange={handleLicenceInfoChange}
                                                required
                                                disabled
                                                type='number'
                                                max='10000000000000'
                                                onKeyDown={(e) => {
                                                    const currentValue = parseInt(e.target.value + e.key);
                                                    if (currentValue > 10000000000000) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                            <div className='py-[1vh] flex flex-row text-[1.2em]'>


                                                <button className='hover:bg-[#4fd0ff] mr-[2vw] shadow-md border border-1 border-[#4fd0ff]  hover:text-black px-[1vw] py-[0.5vh] bg-white ' disabled={licenseStatusActual === "INVALID"} type='button' onClick={() => setChangeCredit((prev) => ({ ...prev, window: true, type: 'add' }))}>Add Credit</button>
                                                <button className='hover:bg-[#4fd0ff] mr-[2vw] shadow-md border border-1 border-[#4fd0ff]  hover:text-black px-[1vw] py-[0.5vh] bg-white ' disabled={licenseStatusActual === "INVALID"} type='button' onClick={() => setChangeCredit((prev) => ({ ...prev, window: true, type: 'subtract' }))}>Subtract Credit</button>

                                            </div>
                                            {/* <span className='text-purple-600'>( This number could vary according to changes in the product count and device count. )</span> */}
                                            {editWindow && (
                                                <div className="text-[1.1em]">
                                                    <label>License Status:</label>
                                                    <select
                                                        name="status"
                                                        style={{
                                                            color:
                                                                licenseStatusActual === "EXPIRED" || licenseStatusActual === "DEACTIVE"
                                                                    ? "red"
                                                                    : licenseStatusActual === "ACTIVE"
                                                                        ? "green"
                                                                        : "black",
                                                            backgroundColor: licenceInfo.status === "EXPIRED" ? "" : "white",
                                                        }}

                                                        className="ml-[1vw] w-[9vw] px-[0.5vw] py-[0.5vh] border border-1 border-[#4fd0ff]"
                                                        value={licenceInfo.status}
                                                        onChange={handleLicenceInfoChange}
                                                    >
                                                        {filteredActionList.map((item, index) => (
                                                            <option key={item} value={item}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}

                                        </div>

                                        {/* <hr /> */}
                                        <div className='flex flex-row items-center py-[1vh] text-[1.1em] border border-t-1 border-t-gray-300'>
                                            <label className='w-[17vw]'>
                                                Estimated Expiry Date <span title='For a more accurate expiration date, refer to the table on the License Management page.'>(±1 day)</span>:
                                            </label>
                                            <p className='font-semibold'>
                                                {
                                                    isNaN(expiryDays) || expiryDays < 0
                                                        ? 'NA'
                                                        : (() => {
                                                            const currentDate = new Date();
                                                            const futureDate = new Date(currentDate.setDate(currentDate.getDate() + expiryDays));
                                                            return isNaN(futureDate.getTime()) ? 'Invalid Date' : futureDate.toLocaleDateString('en-IN', {
                                                                day: 'numeric', // Day as a number (e.g., 25)
                                                                month: 'short',  // Full month name (e.g., October)
                                                                year: 'numeric' // Year as a number (e.g., 2023)
                                                            });;
                                                        })()
                                                }

                                            </p>

                                            {/*    <button className='hover:bg-[#4fd0ff] mr-[2vw] hover:text-black shadow-md border border-1 border-[#4fd0ff] px-[1vw] py-[0.5vh] ml-[5vw] bg-white' type='button'
                                                onClick={() => setChangeCredit((prev) => ({ ...prev, window: true, type: 'addDays' }))}>Add Days</button> */}
                                            <span className='text-purple-600 ml-1'>&nbsp;(*Expiry date will change with increase or decrease in number of product installations.)</span>

                                        </div>

                                        {/* <div className='py-[1vh] flex flex-row text-[1.2em]'>

                      <button className='bg-[#4fd0ff] mr-[2vw] text-white px-[1vw] py-[0.5vh] hover:bg-purple-700' type='button' onClick={()=>setChangeCredit((prev)=>({...prev,window:true,type:'add'}))}>Add Credit</button>
                      <button className='bg-[#4fd0ff] text-white px-[1vw] py-[0.5vh] hover:bg-purple-700' type='button' onClick={()=>setChangeCredit((prev)=>({...prev,window:true,type:'subtract'}))}>Subtract Credit</button>

                    </div> */}
                                        {/* <hr /> */}
                                        {changeCredit.window && <div className={` ${changeCredit.window ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full z-[60] bg-gray-600 opacity-50  `} ></div>}

                                        {changeCredit.window && <ChangeLicenseCredit changeCredit={changeCredit}

                                            setChangeCredit={setChangeCredit}
                                            product={licenceInfo?.product}
                                            totalCredits={licenceInfo.total_credits}
                                            productName={changeCredit.productName}
                                            onConfirmChange={handleConfirmChange}
                                            lastCroneDateTime={lastCroneDateTime}
                                            nextRunAt={nextRunAt}
                                        />}
                                        {licenseUpdateStatus.window && (
                                            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50">
                                                <div className="bg-white border-white border-1 rounded-lg shadow-lg w-[52vw] max-h-[95vh] p-4 overflow-y-auto">
                                                    <div className="relative py-[1vh]">
                                                        <button className="absolute right-[-10px] top-[-10px]" onClick={() => handleLicenseStatusChange("No")}>
                                                            <ImCross className="text-white bg-red-600 p-1 text-xl font-[200] rounded-full" />
                                                        </button>
                                                        <div className='text-[1.2em] flex flex-col gap-x-2  py-[2vh]'>
                                                            <div className="flex flex-row  gap-x-2">
                                                                <TbExclamationCircle className="text-[1.3em] w-[4vw] " />
                                                                {licenceInfo.status === "INVALID" ?
                                                                    <p className="text-[1.1em] font-[500] text-justify mb-0">You are setting the status to <span className='font-[600] uppercase'> Invalid</span>. In this case, this key cannot be linked to the customer. Do you still want to proceed with the current status?</p>
                                                                    :
                                                                    <p className="text-[1.1em] font-[500] text-justify mb-0"> You are setting the status to <span className="text-red-600">{licenceInfo?.status}</span> {licenceInfo?.orgName && (<> for <span className="font-semibold">{licenceInfo?.orgName}</span></>)}. In this status, the license will not be functional for customers. To enable usage, you must change the status to <span className="font-bold text-green-600">ACTIVE</span>. Do you still want to proceed with the current status? </p>

                                                                }


                                                            </div>

                                                            <div className='flex flex-row  justify-end gap-x-1 items-center mt-[1vh]'>
                                                                <button className='bg-secondary py-[1vh] text-[1.1em] px-[1.2vw] border border-1 border-secondary shadow-md text-primary hover:bg-transparent' onClick={() => handleLicenseStatusChange("Yes")}>Yes</button><button className='bg-secondary text-[1.1em] border border-1 border-secondary py-[1vh] px-[1.2vw] shadow-md text-primary hover:bg-transparent' onClick={() => handleLicenseStatusChange("No")}>No</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* License Warning Confirm For Low Credits */}

                                        {licenseCreditsWarning.window && (
                                            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50">
                                                <div className="bg-white border-white border-1 rounded-lg shadow-lg w-[52vw] max-h-[95vh] p-4 overflow-y-auto">
                                                    <div className="relative py-[1vh]">
                                                        <button className="absolute right-[-10px] top-[-10px]" onClick={() => handleLicenseCreditsWarning("No")}>
                                                            <ImCross className="text-white bg-red-600 p-1 text-xl font-[200] rounded-full" />
                                                        </button>
                                                        <div className='text-[1.2em] flex flex-col gap-x-2  py-[2vh]'>
                                                            <div className="flex flex-row  gap-x-2">
                                                                <TbExclamationCircle className="text-[1.3em] w-[4vw] " />
                                                                <p className="text-[1.1em] font-[500] text-justify mb-0">
                                                                    The license status is currently <span className="text-red-600">{licenseStatusActual}</span>
                                                                    {licenceInfo?.orgName && (
                                                                        <> for <span className="font-semibold">{licenceInfo?.orgName}</span></>
                                                                    )}. Your total credits are insufficient to keep it active. To enable usage, you must add enough credits to maintain <span className="font-bold text-green-600">ACTIVE</span> status. Do you still want to proceed with the current status?
                                                                </p>



                                                            </div>

                                                            <div className='flex flex-row  justify-end gap-x-1 items-center mt-[1vh]'>
                                                                <button className='bg-secondary py-[1vh] text-[1.1em] px-[1.2vw] border border-1 border-secondary shadow-md text-primary hover:bg-transparent' onClick={() => handleLicenseCreditsWarning("Yes")}>Yes</button><button className='bg-secondary text-[1.1em] border border-1 border-secondary py-[1vh] px-[1.2vw] shadow-md text-primary hover:bg-transparent' onClick={() => handleLicenseCreditsWarning("No")}>No</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className='py-[1vh] flex items-center gap-x-[1vw] border border-t-1 border-t-gray-300'>
                                            <label className='text-[1.2em] w-[12vw]' htmlFor="product">Choose Product*:</label>
                                            {licenceInfo.product.map((item, index) => (
                                                <div key={index} className='px-[1vw] flex flex-row items-center'>
                                                    <label className='px-[0.5vw]'>{item.name}</label>
                                                    <input
                                                        type='checkbox'
                                                        name={item.name}
                                                        checked={item.checked || false}
                                                        onChange={(e) => handleProductCheckChange(e, item)}
                                                        disabled={licenseStatusActual === "INVALID"}
                                                    />
                                                </div>
                                            ))}
                                            {/* <div className='px-[1vw] text-[1.1em]'><label>Keys generate:</label> <input name='noKeys' max='100000' value={licenceInfo.no_of_keys} onChange={handleLicenceInfoChange} className='w-[6vw] ml-[1vw] px-[1px] border border-1 border-blue-400 bg-white' type='number ' /></div> */}

                                        </div>
                                        {showModal && (
                                            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                                                <div className="bg-white p-6 rounded text-[1.2em]">
                                                    <p className='text-[1.2em]'>Are you sure you want to remove <span className='font-bold'>{currentProduct.name}</span> product?</p>
                                                    <div className="mt-4 flex justify-end gap-2">
                                                        <button
                                                            className="px-[1.2vw] py-[0.8vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                            onClick={handleModalConfirm}
                                                        >
                                                            Confirm
                                                        </button>
                                                        <button
                                                            className="px-[1.2vw] py-[0.8vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                            onClick={handleModalCancel}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* <hr />   */}
                                        <div className='pt-[2vh] grid grid-cols-2 gap-y-2 gap-x-2 justify-between text-[1.1em] border border-t-1 border-t-gray-300'>
                                            {licenceInfo.product.filter(item => item.checked).map((item, index) => (
                                                <div key={index} className='flex flex-col py-[1vh] border border-1 border-gray-500 px-[0.5vw] bg-[#fcfdfe]'>
                                                    <span className='font-bold text-[1.3em] text-blue-800 underline'>{item.name}</span>
                                                    <div className='flex flex-row gap-x-[0.5vw] py-[1vh] items-center '>
                                                        <label className='w-[8vw]' >Daily Rate*: </label>
                                                        <input
                                                            type='number'
                                                            className=' w-[5vw]  px-[1px]'
                                                            name={`dailyRate_${item.name}`}
                                                            value={item.daily_rate}
                                                            // placeholder='Enter daily rate'
                                                            disabled
                                                            max='100000'
                                                            onChange={handleLicenceInfoChange}
                                                            required
                                                            onKeyDown={(e) => {
                                                                const currentValue = parseInt(e.target.value + e.key);
                                                                if (currentValue > 10000000000000) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                        <button className='hover:bg-[#4fd0ff] hover:text-black shadow-md border border-1 border-[#4fd0ff]  px-[1vw] py-[0.5vh] bg-white'
                                                            type='button' disabled={licenseStatusActual === "INVALID"} onClick={() => setChangeCredit((prev) => ({ ...prev, window: true, type: 'dailyRate', productName: item.name }))}>Change Daily Rate</button>

                                                        <label className='pl-[2vw]'>Product Code*:</label>
                                                        <input
                                                            type='text'
                                                            className={`w-[5vw] px-[2px] border border-1 border-blue-400 `}
                                                            name={`productCode_${item.name}`}
                                                            value={item.product_code}
                                                            placeholder='A1'
                                                            // onChange={handleLicenceInfoChange}
                                                            required
                                                            maxLength='100'
                                                            readonly
                                                            disabled
                                                        />

                                                    </div>

                                                    {editWindow && <div className='flex flex-row items-center gap-x-[3vw]'>
                                                        {/* <div className=' py-[1vh] my-[1vh] flex flex-row items-center'>
                                                            <label>Product Status:</label>
                                                            <select
                                                                name={`productStatus_${item.name}`}
                                                                className='ml-[1vw] w-[9vw] px-[0.5vw] py-[0.5vh] border border-1 border-[#4fd0ff]'
                                                                value={item.status}
                                                                onChange={handleLicenceInfoChange} // Move the onChange handler here
                                                            >
                                                                {actionList.map((item, index) => (
                                                                    <option key={item} value={item} className='p-1'>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div> */}
                                                        <div className='flex flex-row items-center '>
                                                            <label className='w-[8vw]'>Active on:</label>
                                                            <div className="font-semibold text-blue-600 flex items-center">
                                                                <button type='button'
                                                                    onClick={() => {
                                                                        if (licenceInfo.status !== "UNLINKED") {
                                                                            handleLinkedDeviceNavigate(
                                                                                // item?.orgId?._id,
                                                                                item?.product_code,
                                                                                item?.installerIds?.length,
                                                                                item?.name
                                                                                // userLicenceList?.linked_license?.license_due_date
                                                                            );
                                                                        }
                                                                    }}
                                                                    title={
                                                                        licenceInfo.status !== "UNLINKED"
                                                                            ? "Click to navigate to linked devices"
                                                                            : "License is currently Unactive"
                                                                    }
                                                                    className={`flex flex-row items-center border-b border-b-1 border-b-blue-600 hover:text-purple-600 ${licenceInfo.status === "UNLINKED" ? "cursor-not-allowed" : "cursor-pointer"
                                                                        }`}
                                                                >
                                                                    &nbsp;{item?.installerIds?.length || 0}&nbsp;
                                                                    <RiComputerLine className="ml-1" />
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    }

                                                    <div className='flex flex-row gap-x-[1vw] py-[1vh]'>
                                                        <label className=''>Deduction Type*: </label>
                                                        <div className='flex flex-row items-center gap-x-[0.4vw]'>
                                                            <input
                                                                type="radio"
                                                                id={`onInstallation_${item.name}`}
                                                                name={`deductionType_${item.name}`}
                                                                value="onInstallation"
                                                                onChange={handleLicenceInfoChange}
                                                                disabled={editWindow === true}

                                                                checked={item.on_installation === true}
                                                                className='dark-label'
                                                            />
                                                            <label htmlFor={`onInstallation_${item.name}`}>On Installation</label>
                                                        </div>
                                                        <div className='flex flex-row items-center gap-x-[0.4vw]'>
                                                            <input
                                                                type="radio"
                                                                id={`duration_${item.name}`}
                                                                name={`deductionType_${item.name}`}
                                                                value="duration"
                                                                onChange={handleLicenceInfoChange}
                                                                disabled={editWindow === true}
                                                                checked={item.on_duration === true}
                                                            />
                                                            <label htmlFor={`duration_${item.name}`}>Duration</label>
                                                        </div>
                                                        <div className='flex flex-row items-center gap-x-[0.4vw]'>
                                                            <input
                                                                type="radio"
                                                                id={`onaccount_link_${item.name}`}
                                                                name={`deductionType_${item.name}`}
                                                                value="onaccount_link"
                                                                onChange={handleLicenceInfoChange}
                                                                disabled={editWindow === true}

                                                                checked={item.on_account_link === true}
                                                            />
                                                            <label htmlFor={`onaccount_link_${item.name}`} >On Linking</label>
                                                        </div>
                                                    </div>
                                                    {item.on_account_link && (
                                                        <div className='flex flex-row items-center gap-x-[1vw] py-[1vh]'>
                                                            <span>
                                                                <label className=''>No. of computers*: </label>
                                                                <input
                                                                    type='number'
                                                                    className='w-[8vw] px-[2px] py-[0.1vh] border border-1 border-blue-400'
                                                                    name={`numberOfComputers_${item.name}`}
                                                                    value={item.no_computer > 0 && item.no_computer}
                                                                    onChange={handleLicenceInfoChange}
                                                                    max='100000000'
                                                                    min='0'
                                                                    required
                                                                    onKeyDown={(e) => {
                                                                        // Prevent '+' or '-' keys

                                                                        if (e.key === '+' || e.key === '-' || e.key === '.' || e.key === 'e') {
                                                                            e.preventDefault();
                                                                        }

                                                                        // Prevent entering a value greater than the allowed max limit
                                                                        const currentValue = e.target.value + e.key;
                                                                        if (!isNaN(currentValue) && parseInt(currentValue) > 10000000000000) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    disabled={editWindow === true}
                                                                />
                                                            </span>
                                                        </div>
                                                    )}
                                                    {item.on_duration && (
                                                        <div className='flex flex-row items-center gap-x-[1vw] py-[1vh]'>
                                                            <span>
                                                                <label className=''>Start Date*: </label>
                                                                <input
                                                                    type='date'
                                                                    name={`startDate_${item.name}`}
                                                                    value={item.start_date ? new Date(item.start_date).toISOString().split('T')[0] : ''}


                                                                    min={new Date().toISOString().split('T')[0]}
                                                                    onKeyDown={(e) => e.preventDefault()} // Prevent manual input via keyboard
                                                                    onInput={(e) => e.preventDefault()} // Prevent manual input via other means
                                                                    onChange={handleLicenceInfoChange}
                                                                    className='px-[2px] cursor-pointer border border-1 border-blue-400'
                                                                    required
                                                                    disabled={editWindow === true}
                                                                />
                                                            </span>
                                                            <span>
                                                                <label className=''>No. of Computers*: </label>
                                                                <input
                                                                    type='number'
                                                                    className='w-[8vw] px-[2px] border border-1 border-blue-400'
                                                                    name={`numberOfComputers_${item.name}`}
                                                                    value={item.no_computer > 0 && item.no_computer}
                                                                    onChange={handleLicenceInfoChange}
                                                                    required
                                                                    min='0'
                                                                    max='1000000000'
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === '+' || e.key === '-' || e.key === '.' || e.key === 'e') {
                                                                            e.preventDefault();
                                                                        }

                                                                        const currentValue = parseInt(e.target.value + e.key);
                                                                        if (currentValue > 10000000000000) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    disabled={editWindow === true}

                                                                />
                                                            </span>
                                                        </div>
                                                    )}
                                                    {
                                                        item.on_installation && (
                                                            <div className='flex flex-row items-center gap-x-[1vw] py-[1vh]'>
                                                                <span>
                                                                    <label>Hint Devices*: </label>
                                                                    <input
                                                                        type='number'
                                                                        className='w-[8vw] px-[2px] border border-1 border-blue-400'
                                                                        name={`hintComputers_${item.name}`}
                                                                        value={item.hint_computers > 0 && item.hint_computers}
                                                                        onChange={handleLicenceInfoChange}
                                                                        required
                                                                        placeholder='5'
                                                                        min='0'
                                                                        max='1000000000'
                                                                        onKeyDown={(e) => {

                                                                            if (e.key === '+' || e.key === '-' || e.key === '.' || e.key === 'e') {
                                                                                e.preventDefault();
                                                                            }


                                                                            const currentValue = parseInt(e.target.value + e.key);
                                                                            if (currentValue > 10000000000000) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        disabled={licenseStatusActual === "INVALID"}
                                                                    />

                                                                </span>
                                                                <div className="relative group flex items-center">
                                                                    <IoInformationCircleOutline className="text-[1.2em] cursor-pointer" />

                                                                    <span className="absolute bottom-full mb-2 hidden group-hover:flex w-[200px] text-xs bg-gray-800 text-white p-2 rounded shadow-md">
                                                                        Hint Devices are used to calculate the estimated expiry date.
                                                                    </span>
                                                                </div>

                                                                {editWindow &&
                                                                    <div className='flex flex-row item-center'><label className='mr-1'>As per hint:</label>
                                                                        <input type='checkbox'
                                                                            name={`hintCheckbox_${item.name}`}
                                                                            value={item.hint_checkbox}
                                                                            onChange={handleLicenceInfoChange}
                                                                            defaultChecked
                                                                        />
                                                                    </div>}

                                                            </div>

                                                        )
                                                    }
                                                    <div className='py-[2vh]'>
                                                        <label>Product Features*: </label>
                                                        <input
                                                            type="text"
                                                            className='px-[1vw] py-[0.5vh] border border-1 border-blue-400'
                                                            required
                                                            name={`productFeatures_${item?.name}`}
                                                            value={item?.features}
                                                            // onChange={handleLicenceInfoChange}
                                                            maxLength='100'
                                                            disabled
                                                            readonly
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/* <hr  style={{ color: 'red', backgroundColor: 'red', height: '2px', border: 'none' }} /> */}
                                        {/* <p className='w-full h-1 partitionLine'></p>
                                      <hr className="custom-hr" /> */}
                                        <div className='flex flex-row items-start  text-[1.1em] py-[2vh] border border-t-1 border-t-gray-300'>
                                            <div className='w-[12vw]'>Remarks*:</div>
                                            <textarea
                                                type='text'
                                                className=' px-[0.5vw] py-[0.5vh] border border-blue-400 border-1 resize-none w-[25vw] max-h-[20vw]'
                                                value={licenceInfo?.remark?.length > 0 ? licenceInfo?.remark : ''}
                                                name='remark'
                                                onChange={handleLicenceInfoChange}
                                                placeholder='Add Remarks' required
                                                maxLength='100'
                                                disabled={licenseStatusActual === "INVALID"}
                                            />
                                        </div>
                                        <hr />
                                        {editWindow && <div className="py-[2vh] border border-t-1 border-t-gray-300">
                                            <div className="grid grid-cols-[75px_auto] gap-1">
                                                <span className="font-[600]">Created By:</span>
                                                <span>{licenceInfo.createdBy}</span>

                                                <span className="font-[600]">Created At:</span>
                                                <span><DateFormat1 date={licenceInfo.createdAt} /></span>
                                            </div>
                                        </div>}

                                        {/* {remark && <div className='text-red-600 flex flex-row justify-end '>{remark}</div>} */}
                                        <div className='flex flex-row justify-end gap-x-1 mr-[1vw]'>
                                            <button
                                                type="submit"
                                                className='bg-[#161625] shadow-md py-[1vh] my-[1vh] w-[10vw] text-white text-[1.1em] hover:bg-secondary hover:text-primary'
                                            >
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                className='bg-[#161625]  shadow-md py-[1vh] my-[1vh] w-[10vw] text-white text-[1.1em] hover:bg-secondary hover:text-primary'
                                                onClick={() => { editWindow ? (setEditWindow(false), setShowNewLicence(false)) : setShowNewLicence(false) }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div></div>
                        }
                    </div>
                    <div className={`flex flex-row items-center justify-between pl-[5vw] mx-auto pt-[2vh]  `}>
                        <div className='text-[1.2em] text-primary py-[1vh] font-[600] 2xl:text-[1.3em] '>License Management</div>
                    </div>
                    <div className='bg-[#bbe3f55e] text-gray-900 text-[0.9em] py-[1vh] relative w-[3000px] pl-[1%] flex flex-row items-center gap-x-[1%]'>

                        <div className=''>
                            <div className='cursor-pointer hover:text-blue-500' onClick={() => setOpenShowHide(true)} >Show/Hide</div>
                            {openShowHide && <>
                                <div
                                    className="fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50 z-[42] "

                                ></div>
                                <div className='showhide-section-container z-40'>
                                    <div className="modal-body filter-section-content  ">
                                        <button className=' absolute right-[-5px] top-[-5px]' onClick={() => setOpenShowHide(!openShowHide)}>
                                            <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' />
                                        </button>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                {columnsLicense.map((item, index) => (
                                                    <label className="cont flex flex-row gap-x-[1%] text-white">
                                                        <input
                                                            type="checkbox"
                                                            className="toggle-vis"
                                                            data-column={index + 1}
                                                            checked={checkedColumns[index]}
                                                            defaultChecked
                                                            onChange={(event) => handleCheckboxChange(event, index)}
                                                        />
                                                        <span className="checkmark"></span> <p>{item.name}</p>
                                                    </label>
                                                ))}
                                            </div>

                                        </div>
                                        <div className="row" style={{ marginTop: "20px" }}>
                                            <button className="btn btn-primary apply-button" style={{ marginRight: "10px", marginLeft: "15px" }} onClick={selectAllColumns}>Select All</button>
                                            <button className="btn btn-primary apply-button" onClick={deselectAllColumns}>Deselect All</button>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                        <div className={` ${showFilter ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50  z-[42]`} onClick={handleFilter}></div>
                        <div className='flex flex-row items-center hover:text-blue-500' onClick={handleFilter}>
                            <span>Filters &nbsp;</span>
                            <FaFilter className={` ${filtersvalue?.filters?.field?.length > 0 ? "text-green-500" : "text-yellow-400"} cursor-pointer`} />
                            {filtersvalue?.filters?.field.length > 0 && <span>&nbsp;{filtersvalue?.filters?.field?.length} applied</span>}
                        </div>
                        {showFilter && <FilterSection showFilter={showFilter} setShowFilter={setShowFilter} columns={columnsLicense} initialFilters={filtersvalue} onApplyFilters={onApplyFilters} />}
                        <div className='cursor-pointer flex flex-row items-center gap-x-[0.5vw] hover:text-blue-500' onClick={updateTime}>Refresh <LuRefreshCcw /></div>
                        <div>{`Last page updated at : ${currentTime}`}</div>
                        <button className='bg-blue-600 text-white rounded-sm px-[1vw] py-[0.5vh] shadow-md ml-[2vw]   shadow-blue-300 hover:bg-blue-800 ' onClick={() => handleNewLicence()}>Generate Key</button>

                    </div>
                    <div className={`flex flex-row justify-between items-center py-1 ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'} border-b-1 border-b-gray-500`}>
                        <DataEntriesNo pageSize={pageSize} handlePageSizeChange={handlePageSizeChange} />
                        <PageNumber
                            pageSize={pageSizeFromURL}
                            currPage={currPage}
                            totalRecords={totalRecords}
                            handlePageNumberChange={handlePageNumberChange}
                        />
                    </div>
                </div>

                {/* Main Content Goes Here */}

                <div ref={tableRef} className="overflow-auto w-full -z-10" style={{ maxHeight: tableHeight }}>
                    <table className="w-full top-0">
                        {/* Table Head */}
                        <thead className="sticky top-0 bg-blue-200 text-[0.9em] z-20">
                            <tr className="text-sm font-semibold text-gray-800">

                                {visibleColumns.map((column, index) => (
                                    <th key={index} className="py-2 px-3 min-w-[280px] w-[280px] text-center">
                                        <div
                                            className="flex items-center justify-center gap-x-1 cursor-pointer"
                                            onClick={() => handleSort(column.value, activeArrow.columnName === column.value ? -activeArrow.direction : 1)}
                                        >
                                            <span title={`${column.type === "date" ? "DD/MM/YYYY" : ""}`}>{column.name}</span>
                                            <p>
                                                <span className='font-thin text-[0.5em] text-blue-400 flex flex-row items-center arrow'>
                                                    <FaLongArrowAltUp title='Ascending' onClick={() => handleSort(column.value, 1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === 1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] px-0 h-[2vh] cursor-pointer arrow`} />
                                                    <FaLongArrowAltDown title='Descending' onClick={() => handleSort(column.value, -1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === -1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] h-[2vh] px-0 h-[1vh]cursor-pointer`} />
                                                </span>
                                            </p>
                                        </div>
                                    </th>
                                ))}

                                <th className="py-2 px-3  w-[150px] text-center">Delete</th>
                                {/* <th className="py-2 px-3  w-[150px] text-center">Unlink</th> */}

                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody className="text-sm text-gray-800 border border-b-1 border-b-gray-300">
                            {sortedData.length > 0 ? (
                                sortedData.map((item, indexVal) => (
                                    <tr key={indexVal} className={`${indexVal % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        {/* Dynamic Columns */}
                                        {visibleColumns.map((column, colIndex) => {
                                            const value = column.path ? getValueByPath(item, column.path) : item[column.value];
                                            return (
                                                <td
                                                    key={`${indexVal}-${colIndex}`}
                                                    className="py-2 px-3 text-center text-[0.9em] w-[250px] break-words"
                                                >
                                                    {(() => {
                                                        // if (column.type === "date" && value) {
                                                        //     return <span>{new Date(value).toLocaleString()}</span>;
                                                        // }
                                                        if (column.value === 'key' && item?.key) {
                                                            return (
                                                                <div className="flex flex-row justify-between items-center gap-2 min-w-[290px] ">
                                                                    <a
                                                                        href="#"
                                                                        className={`underline ${item?.status === "REMOVED" ? "text-gray-500" : "text-blue-700  hover:text-purple-700"} `}
                                                                        onClick={() => {
                                                                            item?.status !== "REMOVED" &&
                                                                                handleEditLicence(
                                                                                    indexVal,
                                                                                    item?.orgId?._id,
                                                                                    item?.orgId?.name,
                                                                                    item?.products,
                                                                                    item?.total_credits,
                                                                                    item?._id,
                                                                                    item?.remark,
                                                                                    item?.status,
                                                                                    item?.key,
                                                                                    item?.linked_user_id,
                                                                                    item?.license_due_date,
                                                                                    item?.["Created By"], // Passing "Created By"
                                                                                    item?.["Created at"]  // Passing "Created at"
                                                                                )
                                                                        }}
                                                                        rel="noopener noreferrer"
                                                                        title={item?.status === "REMOVED" ? "This license key's status is Removed, so it cannot be edited" : "License Key"}
                                                                    >
                                                                        {
                                                                            item?.key}
                                                                    </a>
                                                                    <div className="flex items-center gap-2">
                                                                        <CopyButton text={item?.key} />
                                                                        <FaHistory
                                                                            className="hover:text-blue-600 cursor-pointer"
                                                                            title={`License History for ${item?.key}`}
                                                                            onClick={() => handleLicenseHistoryForKey(item?.key)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        }


                                                        if (column.type === "date" && value) {
                                                            return <span><FormatDate date={value} /></span>;
                                                        }

                                                        // Handle arrays (current_dlp_provider, current_antivirus, etc.)
                                                        if (Array.isArray(value)) {
                                                            return <span>{value.join(", ")}</span>;
                                                        }
                                                        if (value && typeof value === "string" && value.length > 200) {
                                                            // Handle long text with "Show More/Show Less"
                                                            return (
                                                                <div className="text-container">
                                                                    {expandedStates[`${indexVal}-${colIndex}`] ? (
                                                                        <>
                                                                            <span>{value}</span>
                                                                            <button
                                                                                className="show-more-btn text-blue-600"
                                                                                onClick={() => toggleExpand(indexVal, colIndex)}
                                                                            >
                                                                                ...Show Less
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="text-truncated">{value.substring(0, 200)}...</span>
                                                                            <button
                                                                                className="show-more-btn text-blue-600"
                                                                                onClick={() => toggleExpand(indexVal, colIndex)}
                                                                            >
                                                                                ...Show More
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                        // Default case for all other columns
                                                        return value || value === 0 ? <span>{value}</span> : <span>-</span>;
                                                    })()}
                                                </td>
                                            );
                                        })}



                                        {/* Delete Column */}
                                        <td className="py-2 px-3 w-[150px] text-center mx-auto">
                                            <MdDelete className={` mx-auto cursor-pointer ${showConfirmationModal === indexVal ? 'text-orange-500' : 'text-red-600'}  text-[1.5em] hover:text-orange-500`} onClick={() => setShowConfirmationModal(indexVal)} />
                                        </td>
                                        {/* <td
                                            className="w-[100px] pl-[0.1vw] text-[1.2em]"
                                        >
                                            <FaUnlink
                                                onClick={() => {
                                                    if (item?.orgId?.name) {
                                                        setUnlinkStatusChange({ window: true, licenseId: item?._id, indexUnlink: indexVal });
                                                    }
                                                }}
                                                className={`mx-auto ${item?.orgId?.name
                                                    ? "bg-opacity-50 cursor-pointer"
                                                    : "text-gray-300 cursor-not-allowed"
                                                    }`}
                                            />
                                        </td> */}

                                        {showConfirmationModal === indexVal && (
                                            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[60] leading-[1.5]">
                                                <div className="bg-white text-[1.3em] border-gray-300 border-1 rounded-lg shadow-lg w-[42vw] max-h-[95vh] p-4 overflow-y-auto">
                                                    <p>Are you sure you want to delete this <span className='font-semibold text-blue-600'>{item.key}</span>  key ?</p>
                                                    <div className="modal-buttons pr-[1vw] gap-x-1 pt-[2vh] flex flex-row justify-end">
                                                        <button
                                                            className="px-[1.2vw] py-[0.8vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                            onClick={() => handleDeleteLicence(item._id)}
                                                        >
                                                            Yes
                                                        </button>
                                                        <button
                                                            className="px-[1.2vw] py-[0.8vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                            onClick={() => setShowConfirmationModal(null)}
                                                        >
                                                            No
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        )}

                                        {unlinkStatusChange.indexUnlink === indexVal && (
                                            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[60]">
                                                <div className="bg-white text-[1.2em] border-gray-300 border-1 rounded-lg shadow-lg w-[42vw] max-h-[95vh] p-4 overflow-y-auto">
                                                    <div className="flex flex-row gap-x-[0.5vw] text-[1em]">
                                                        <TbExclamationCircle className="text-[1.7em]" />
                                                        <p className="text-[1.2em] leading-[1.5]  font-[500] text-justify mb-0">
                                                            Are you sure you want to unlink this license? This action will result in the loss of all associated credits.
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-row justify-end gap-x-1 items-center mt-[2vh] text-[1.1em]">
                                                        <button
                                                            className="px-[1.2vw] py-[0.8vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                            onClick={handleChangeLicenseStatusAction}
                                                        >
                                                            Yes
                                                        </button>
                                                        <button
                                                            className="px-[1.2vw] py-[0.8vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                            onClick={() => setUnlinkStatusChange({ window: false, licenseId: "" })}
                                                        >
                                                            No
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={visibleColumns.length + 3} className="py-2 pl-2">
                                        No matching data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div >

        </div >
    )
}

export default CreateLicense2;