
import { userCredntial } from "./userAuth.action"
import { REQUEST_USER_AUTH, ERROR_USER_AUTH, SET_USER_AUTH, SET_USER_CREDENTIAL } from "./userAuth.action_type"
const intialState = {
    loading: false,
    userAuthData:{},
    errorUserAuth: null,
    userCrdential:null
}

const reducer = (state = intialState, action) => {
    // console.log(state,"user state");
    
    switch (action.type) {
        case REQUEST_USER_AUTH:
            return {
                ...state,
                loading: true,

            }
        case SET_USER_AUTH:
            return {
                ...state,
                loading: false,
                userAuthData: action.payload.data,
                errorUserAuth: null
            }
            case SET_USER_CREDENTIAL:
                return {
                    ...state,
                    userCredntial:action.payload
                }
            
        case ERROR_USER_AUTH:
            return {
                ...state,
                errorUserAuth: "No data found",
            }
        default: return state
    }


}
export { reducer };