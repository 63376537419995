import React, { useEffect } from "react";
import DlpHero from "../components/DlpHero";
import Usp from "../components/Usp";
import Feature from "../components/Feature"
import UseCase from "../components/UseCase";
import Customers from "../components/Customers";
import Award from "../components/Award";
import Partner from "../components/Partner";
import '../css/dlp.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from "../Footer";
import Header from "../Header1";
import CookiesBanner from "../components/Cookie";
import DlpHero1 from "../components/DlpHero1";
import DlpProduct1 from "../components/DlpProduct1";
import { Helmet } from "react-helmet";
// import { Helmet } from "react-helmet-async";
const Home = () => {


  return (
    <>
       <Helmet>
        <title>Matisoft | DLP</title>
      </Helmet>
     <Header />
     <DlpHero1/>
      {/* <DlpHero/> */}
      {/* <Usp /> */}
      <DlpProduct1/>
      <Feature />
      <UseCase />
      <Customers />
      <Award />
      <Partner />
      <CookiesBanner/>
      <Footer/>
    </>
  );
};

export default Home;