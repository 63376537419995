// LoginWithPassword.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';

const LoginWithPassword = ({ email, setEmail, password, setPassword }) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const [loginRequest, setLoginRequest] = useState(false);
    const handleSubmitPassword = async (e) => {
        e.preventDefault();
        if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error("Please input a valid Email ID");
            return;
        }
        setLoginRequest(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/login`, { email, password });
            // toast.success('Login successful');
            setErrorMessage(null);
            // console.log(response?.data?.data?.data,"Login response");
            const credential = response?.data?.data;
            // console.log(credential);


            // Save the credential object as a string in localStorage
            // localStorage.setItem("userCredential", JSON.stringify(credential));
            if (response?.data?.data?.licenseStatus === "ACTIVE") {
                window.location.href = '/cms/company/list';
            }

            else {

                navigate(`/cms/licenseInfo/${response?.data?.data?.id}`)

            }
            // console.log(response ,"Login value    ");
            
            setLoginRequest(false);


        } catch (error) {
            if (window.innerWidth >= 768) {
                toast.error(error?.response?.data || error?.message);
            } setErrorMessage(error?.response?.data || error?.message)
            console.error("Error", error);
            setLoginRequest(false);
        }
    };
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };
    return (
        <form onSubmit={handleSubmitPassword}>
            {errorMessage && <div className='text-red-600 text-justify px-[0.5vw] md:hidden' >{errorMessage}</div>}
            <div className='mb-4'>
                <label className='block text-gray-800 py-[1vh] font-[600]'>Email Id</label>
                <input
                    type='email'
                    name='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Enter your email'
                    required
                    maxLength='254'
                    className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                />
            </div>
            <div className='mb-4 relative'>
                <label className='block text-gray-800 py-[1vh] font-[600]'>Password</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    maxLength='16'
                    placeholder='Enter your password'
                    className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                />
                <span
                    className='absolute inset-y-0 right-3 top-[44%] flex items-center cursor-pointer'
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Eye icons for toggling */}
                </span>
            </div>
            <div className='flex items-center justify-end mb-4'>
                <Link to='/forgotPassword' className='text-blue-600 hover:underline'>
                    Forgot Password?
                </Link>
            </div>
            <button
                type='submit'
                className='w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200'
                disabled={loginRequest}
            >
                {loginRequest ? "Loading..." : "Login"}
            </button>
            <div className='text-center mt-4'>
                <p className='text-gray-800'>Don’t have an account yet?</p>
                <Link to='/presignup' className='text-blue-600 p-[5px] hover:underline'>
                    Sign Up
                </Link>
            </div>
        </form>
    );
};

export default LoginWithPassword;
