

const resetTime = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
};
const calculateExpiryDays = ({totalCredit, product,lastCroneDateTime,nextRunAt}) => {
    if (isNaN(totalCredit) || totalCredit <= 0) {
        return 'NA';
    }

    let creditsToBeDeductEveryDay = 0;
    let daysUntilExpiry = 0;
    const futureStartDateArray = [];
    const currentDate = new Date();
    let remainingCredit = totalCredit;

    product.forEach((item) => {
        if (item.checked) {
            const hintComputers = isNaN(item.hint_computers) ? 0 : item.hint_computers;
            const noComputer = isNaN(item.no_computer) ? 0 : item.no_computer;
            const dailyRate = isNaN(item.daily_rate) ? 0 : item.daily_rate;
            const installedComputers = isNaN(item?.installerIds?.length) ? 0 : item.installerIds.length;
            const effectiveComputers = Math.max(noComputer, installedComputers);

            if (item.on_installation) {
                if(item.hint_checkbox===false){
                    creditsToBeDeductEveryDay += item?.installerIds?.length * dailyRate;
                }
                else{
                creditsToBeDeductEveryDay += hintComputers * dailyRate;
                }
            } else if (item.on_account_link) {
                creditsToBeDeductEveryDay += effectiveComputers * dailyRate;
            } else if (item.on_duration) {
                const startDate = new Date(item.start_date);
                if (!isNaN(startDate)) {
                    if (startDate <= currentDate) {
                        creditsToBeDeductEveryDay += effectiveComputers * dailyRate;
                    } else {
                        futureStartDateArray.push({
                            startDate: startDate,
                            noComputer: effectiveComputers,
                            dailyRate: dailyRate,
                        });
                    }
                }
            }
        }
    });

    futureStartDateArray.sort((a, b) => a.startDate - b.startDate);
    futureStartDateArray.forEach((item) => {
        // console.log(new Date(item.startDate).toLocaleString(), item.dailyRate, "futureArray");
    });

    let prevStartDate = currentDate;

    for (let i = 0; i < futureStartDateArray.length; i++) {
        const item = futureStartDateArray[i];
        const daysUntilStartDate = ((resetTime(item.startDate).getTime() - resetTime(prevStartDate).getTime()) / (1000 * 60 * 60 * 24));

        // const daysUntilStartDate = Math.floor((item.startDate.getTime() - prevStartDate.getTime()) / (1000 * 60 * 60 * 24));
        // console.log(daysUntilStartDate, "daysssssssssssssss");

        if ((creditsToBeDeductEveryDay * daysUntilStartDate) >= remainingCredit) {
            break;
        }

        remainingCredit -= creditsToBeDeductEveryDay * daysUntilStartDate;
        daysUntilExpiry += daysUntilStartDate;
        // console.log(daysUntilExpiry, "expiry1");

        creditsToBeDeductEveryDay += item.dailyRate * item.noComputer;
        prevStartDate = item.startDate;
    }

    daysUntilExpiry += remainingCredit / creditsToBeDeductEveryDay;
    // let lastCroneTime=  resetTime(lastCroneDateTime);
    // console.log(resetTime(lastCroneDateTime) - resetTime(currentDate),"diff in cron and current");

    ///// Prev logics for conditions  before 20 Aug 2024

//     if(lastCroneDateTime === null && resetTime(nextRunAt) ===resetTime(currentDate) ){
//         return isNaN(daysUntilExpiry) || creditsToBeDeductEveryDay <= 0 ? 'NA' : Math.max(0,Math.floor(daysUntilExpiry));

//     }
//     else if (lastCroneDateTime === null && resetTime(nextRunAt)-resetTime(currentDate)>0){
//         return isNaN(daysUntilExpiry) || creditsToBeDeductEveryDay <= 0 ? 'NA' : Math.floor(daysUntilExpiry);

//     }
//    else if (resetTime(lastCroneDateTime) - resetTime(currentDate) === 0) {
//         return isNaN(daysUntilExpiry) || creditsToBeDeductEveryDay <= 0 ? 'NA' : Math.floor(daysUntilExpiry);

//     }
//     else {
//         return isNaN(daysUntilExpiry) || creditsToBeDeductEveryDay <= 0 ? 'NA' : Math.max(0,Math.floor(daysUntilExpiry) );
//     }
  
///////////////New logics implemented on 22 Aug 2024
// console.log(lastCroneDateTime,nextRunAt,daysUntilExpiry,"last CRone & next Run at & days untillexpiry");

    if(lastCroneDateTime === null && resetTime(nextRunAt) ===resetTime(currentDate) ){
        return isNaN(daysUntilExpiry) || creditsToBeDeductEveryDay <= 0 ? 'NA' : Math.max(0,Math.floor(daysUntilExpiry))-1;

    }
    else if (lastCroneDateTime === null && resetTime(nextRunAt)-resetTime(currentDate)>0){
        return isNaN(daysUntilExpiry) || creditsToBeDeductEveryDay <= 0 ? 'NA' : Math.max(0,Math.floor(daysUntilExpiry)-1);

    }
   else if (resetTime(lastCroneDateTime) - resetTime(currentDate) === 0) {
        return isNaN(daysUntilExpiry) || creditsToBeDeductEveryDay <= 0 ? 'NA' : Math.max(0,Math.floor(daysUntilExpiry)-1);

    }
    else {
        return isNaN(daysUntilExpiry) || creditsToBeDeductEveryDay <= 0 ? 'NA' : Math.max(0,Math.floor(daysUntilExpiry) -1 );
    }

        // return isNaN(daysUntilExpiry) || creditsToBeDeductEveryDay <= 0 ? 'NA' : Math.max(0,Math.floor(daysUntilExpiry) );

};
             //////////// CalculateDailyRate 

const calculateDailyRate =(totalCredit, product)=>{
    if (isNaN(totalCredit) || totalCredit <= 0) {
        return 'NA';
    }

    let creditsToBeDeductEveryDay = 0;
    let daysUntilExpiry = 0;
    const futureStartDateArray = [];
    const currentDate = new Date();
    let remainingCredit = totalCredit;

    product.forEach((item) => {
        if (item.checked) {
            const hintComputers = isNaN(item.hint_computers) ? 0 : item.hint_computers;
            const noComputer = isNaN(item.no_computer) ? 0 : item.no_computer;
            const dailyRate = isNaN(item.daily_rate) ? 0 : item.daily_rate;
            const installedComputers = isNaN(item?.installerIds?.length) ? 0 : item.installerIds.length;
            const effectiveComputers = Math.max(noComputer, installedComputers);

            if (item.on_installation) {
                if(item.hint_checkbox===false){
                    creditsToBeDeductEveryDay += item?.installerIds?.length * dailyRate;
                }
                else{
                creditsToBeDeductEveryDay += hintComputers * dailyRate;
                }
            } else if (item.on_account_link) {
                creditsToBeDeductEveryDay += effectiveComputers * dailyRate;
            } else if (item.on_duration) {
                const startDate = new Date(item.start_date);
                if (!isNaN(startDate)) {
                    if (startDate <= currentDate) {
                        creditsToBeDeductEveryDay += effectiveComputers * dailyRate;
                    } else {
                        futureStartDateArray.push({
                            startDate: startDate,
                            noComputer: effectiveComputers,
                            dailyRate: dailyRate,
                        });
                    }
                }
            }
        }
    });

    futureStartDateArray.sort((a, b) => a.startDate - b.startDate);
    futureStartDateArray.forEach((item) => {
        // console.log(new Date(item.startDate).toLocaleString(), item.dailyRate, "futureArray");
    });

    let prevStartDate = currentDate;

    for (let i = 0; i < futureStartDateArray.length; i++) {
        const item = futureStartDateArray[i];
        const daysUntilStartDate = ((resetTime(item.startDate).getTime() - resetTime(prevStartDate).getTime()) / (1000 * 60 * 60 * 24));

        // const daysUntilStartDate = Math.floor((item.startDate.getTime() - prevStartDate.getTime()) / (1000 * 60 * 60 * 24));
        // console.log(daysUntilStartDate, "daysssssssssssssss");

        if ((creditsToBeDeductEveryDay * daysUntilStartDate) >= remainingCredit) {
            break;
        }

        remainingCredit -= creditsToBeDeductEveryDay * daysUntilStartDate;
        daysUntilExpiry += daysUntilStartDate;
        // console.log(daysUntilExpiry, "expiry1");

        creditsToBeDeductEveryDay += item.dailyRate * item.noComputer;
        prevStartDate = item.startDate;
    }
    return creditsToBeDeductEveryDay;
}

const calculateAddedCredits = ({ desiredExtraDays, totalCredit, product, prevExpiryDays }) => {
    if (isNaN(desiredExtraDays) || desiredExtraDays <= 0 || isNaN(prevExpiryDays) || prevExpiryDays < 0) {
        return 'NA';
    }

    let creditsToBeDeductEveryDay = 0;
    const futureStartDateArray = [];
    const currentDate = new Date();
    let remainingCredit = totalCredit;

    product.forEach((item) => {
        if (item.checked) {
            const hintComputers = isNaN(item.hint_computers) ? 0 : item.hint_computers;
            const noComputer = isNaN(item.no_computer) ? 0 : item.no_computer;
            const dailyRate = isNaN(item.daily_rate) ? 0 : item.daily_rate;
            const installedComputers = isNaN(item?.installerIds?.length) ? 0 : item.installerIds.length;
            const effectiveComputers = Math.max(noComputer, installedComputers);

            if (item.on_installation) {
                creditsToBeDeductEveryDay += (hintComputers * dailyRate) * desiredExtraDays;
            } else if (item.on_account_link) {
                creditsToBeDeductEveryDay += (effectiveComputers * dailyRate) * desiredExtraDays;
            } else if (item.on_duration) {
                const startDate = new Date(item.start_date);
                if (!isNaN(startDate)) {
                    // console.log(prevExpiryDays, "expiryDays");
                
                    // Correctly add prevExpiryDays to the current date
                    const adjustedStartDate = new Date(currentDate.getTime() + (prevExpiryDays * 24 * 60 * 60 * 1000));
                
                    // console.log(adjustedStartDate, "sum of date");
                    // console.log(adjustedStartDate - startDate, "diff in date");
                
                    if (adjustedStartDate > startDate) {
                        creditsToBeDeductEveryDay += (effectiveComputers * dailyRate) * desiredExtraDays;
                    } else {
                        futureStartDateArray.push({
                            startDate: startDate,
                            noComputer: effectiveComputers,
                            dailyRate: dailyRate,
                        });
                    }
                }
                
            }

        }
    });

    futureStartDateArray.sort((a, b) => a.startDate - b.startDate);

    let prevStartDate = currentDate;
    let totalDays = prevExpiryDays;
    let daysLeft = desiredExtraDays;
     // Initialize the variable to store the total credits to be deducted
    
    for (let i = 0; i < futureStartDateArray.length; i++) {
        const item = futureStartDateArray[i];
        const futureStartDate = resetTime(item.startDate);
    
        // Calculate the target date by adding totalDays and daysLeft to the currentDate
        const targetDate = new Date(currentDate.getTime() + (totalDays + daysLeft) * 24 * 60 * 60 * 1000);
    
        // If target date is greater than or equal to future start date, calculate credits
        if (targetDate >= futureStartDate) {
            // Calculate the number of extra days beyond the future start date
            const extraDays = Math.floor((targetDate.getTime() - futureStartDate.getTime()) / (1000 * 60 * 60 * 24));
            
            // Add credits for the extra days after the current expiry date and the product's start date
            creditsToBeDeductEveryDay += item.dailyRate * item.noComputer * extraDays;
    
            // Update prevStartDate and totalDays
            prevStartDate = futureStartDate;
            totalDays += extraDays;
    
            // Reduce daysLeft by the extra days
            daysLeft -= extraDays;
    
            // If daysLeft is zero or less, break out of the loop
            if (daysLeft <= 0) {
                break;
            }
        } else {
            break;
        }
    }
    
    
    // creditsToBeDeducted now contains the total extra credits needed
    

    let creditsNeededForRemainingDays = 0;
    // for (let i = 0; i < desiredExtraDays; i++) {
    //     creditsNeededForRemainingDays += creditsToBeDeductEveryDay;
    // }
    // console.log(creditsToBeDeductEveryDay, "exrta credits");

    return creditsToBeDeductEveryDay;
};


export { calculateExpiryDays, calculateAddedCredits , calculateDailyRate };
