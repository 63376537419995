import React, { useState } from 'react';
import Sidebar1 from '../webapplication/common/Sidebar1';
import { Helmet } from 'react-helmet';
import { FaExclamationTriangle } from "react-icons/fa";  
import { LuBan } from "react-icons/lu";

const Error1 = () => {
  const [isSidebarExpanded, setSidebarExpanded] = useState(true);

  const toggleSidebar = () => {
    setSidebarExpanded(!isSidebarExpanded);
  };

  return (
    <div className="flex h-screen bg-gray-50">
    <Helmet>
      <title>Matisoft | Access Denied</title>
    </Helmet>

    {/* Sidebar */}
    <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-[5%]'} bg-gray-200 h-full z-[42] fixed`}>
      <Sidebar1 isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
    </div>

    {/* Main Content */}
    <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[5%]'} flex flex-col justify-center items-center`}>
      
      {/* Icon and Title */}
      <div className="text-center flex flex-col justify-center items-center">
        <FaExclamationTriangle className="text-red-600 text-[4rem] mb-6" />
        <h1 className="text-[30px] font-semibold text-gray-800 uppercase">403 - Access Denied</h1>
      </div>
      
      {/* Error Message */}
      <p className="text-gray-600 text-lg mt-2 mb-8">
         You don't have the necessary permissions, to access this page.
      </p>

      {/* Redirect Button */}
      <a href='/cms/dashboard' className=' border-secondary bg-secondary text-primary hover:bg-primary hover:text-secondary  px-6 py-3 rounded-full text-base font-semibold transition-colors duration-300'>
        Return to Home Page
      </a>
    </div>
  </div>
  );
}

export default Error1;
