import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { IoIosMenu, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { CgNotes } from "react-icons/cg";
import { FaLayerGroup, FaRegUser } from "react-icons/fa";
import '../css/sidebar.css';
import { checkCompanyAuthentication } from '../../Redux/companyDetail/companyDetail.action';
import { checkUserAuth, userCredential } from '../../Redux/userAuth/userAuth.action';
import { checkAdminAuth } from '../../Redux/adminAuth/adminAuth.action';
import { HiOutlineDocumentReport } from "react-icons/hi";
import { RiFocus2Fill } from 'react-icons/ri';
import { MdAdminPanelSettings, MdKeyboardArrowUp } from "react-icons/md";
import { setCompanyId, setOrgId, setPermissions, setUserId } from '../../Redux/companyid/companyid.action';
import { GoDeviceDesktop } from "react-icons/go";
import { TbSettingsDown } from "react-icons/tb";
import { AiOutlineSetting } from "react-icons/ai";
import { GoAlertFill } from "react-icons/go";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { FiAlertTriangle } from "react-icons/fi";
import { GoAlert } from "react-icons/go";
import { FaUserShield } from 'react-icons/fa6';
import { handleLogout } from '../../pages/login/Logout';

const SidebarInstaller = ({ isExpanded, toggleSidebar, pageName }) => {
    const dispatch = useDispatch();
    const { companyId, orgId, userId, companyAuthenticate, userAuthData, adminAuthData, permissions } = useSelector((state) => ({
        companyId: state.companyid.companyId,
        orgId: state.companyid.orgId,
        userId: state.companyid.userId,
        permissions: state.companyid.permissions,
        companyAuthenticate: state.companyReducer.companyAuthenticate,
        userAuthData: state.userAuth.userAuthData,
        userCredential: state.userAuth.userCredential,

        adminAuthData: state.adminAuth.adminAuthData,

    }));
    const [data, setData] = useState(null);
    const location = useLocation(); // Get current path

    // useEffect(() => {
    //     const currentURL = location.pathname;
    //     const parts = currentURL.split("?")[0].split("/");
    //     const extractedUserId = parts[parts.length - 1];
    //     const extractedCompanyId = parts[parts.length - 2];
    
    //     console.log(extractedCompanyId, "Extracted Company ID");
    //     console.log(extractedUserId, "Extracted User ID");
    
    //     if (extractedCompanyId !== companyId) {
    //         dispatch(setCompanyId(extractedCompanyId));
    //     }
    //     if (extractedUserId !== userId) {
    //         dispatch(setUserId(extractedUserId));
    //     }
    // }, [location.pathname, dispatch]); 
    useEffect(() => {
        if (companyId) {
            dispatch(checkCompanyAuthentication(companyId));
        }
    
        if (userId) {
            dispatch(checkUserAuth(userId));
            dispatch(checkAdminAuth(userId));
        }
    }, [companyId, userId, dispatch]);

    useEffect(() => {
        dispatch(setPermissions(userAuthData?.permissions));
        dispatch(setOrgId(userAuthData?.orgId))
    }, [userAuthData])
    const [hoveredItem, setHoveredItem] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const [lowerWindow, setLowerWindow] = useState(false);
    const [activeItemCollapse, setActiveItemCollapse] = useState(null);

    const handleMouseEnterCollapse = (id) => {
        setActiveItemCollapse(id);
    };

    const handleMouseLeaveCollapse = (event) => {
        setActiveItemCollapse(null);
    };
    const handleItemClick = (item) => {
        setActiveItem(activeItem === item ? null : item);
    };

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    return (
        <>
            {isExpanded ? (
                <div className={`sidebar-container bg-[#0d1321] h-screen ${isExpanded ? '' : 'collapsed'}`}>

                    <div className='px-[1vw] pt-[1vh]'>
                        <IoIosMenu className='text-[26px] text-white' onClick={toggleSidebar} />
                    </div>

                    <div className='flex flex-row items-center justify-center h-[10%] mx-auto'>
                        <img src='/small-logo.png' className='flex h-[60%] px-[3%] object-contain' alt='company-logo' />
                        <div className='flex flex-col items-start'>
                            <span className='text-[0.9em] text-white'>Matisoft Cloud Platform</span>
                            <span className='text-secondary text-[0.7em]'>
                                {companyAuthenticate ?
                                    <a href={`/cms/dashboard`}>{companyAuthenticate?.name}</a> :
                                    <a href={`/cms/dashboard`}>Matisoft Cyber Security Labs </a>}
                            </span>
                        </div>
                    </div>

                    <div
                        style={{ maxHeight: '75vh', marginBottom: '10vh' }}
                        className='flex flex-col items-center text-gray-200 pt-[3vh] overflow-y-auto' >
                        <ul className='w-full mx-auto'>
                            <li
                                className={`nav-item ${hoveredItem === 'reports' ? 'hovered bg-[#62c1e4] text-black' : ''}`}
                                onMouseEnter={() => handleMouseEnter('reports')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('reports')}>
                                    <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                        <CgNotes className={` font-thin text-[0.9em] ${hoveredItem !== 'reports' && 'text-gray-400'} `} />
                                        <span className="title text-[0.9em]">Reports</span>
                                    </span>
                                    <IoIosArrowForward />
                                </div>
                                {(activeItem === 'reports' || hoveredItem === 'reports') && (
                                    <ul className='submenu'>
                                        <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em] hover:text-black'>
                                            <a href={`/cms/reports/threat/${companyId}`} className='pl-[2vw]'>
                                                Threat Reports
                                            </a>
                                        </li>
                                        <li className='text-[0.9em] '>
                                            <a href={`/cms/reports/malware/${companyId}`} target="_blank" rel="noopener noreferrer" className='pl-[2vw]'>
                                                Malware Reports
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            {adminAuthData?.role?.includes('ADMINISTRATOR') && (
                                <li
                                    className={`nav-item ${hoveredItem === 'logs' ? ' hovered bg-[#62c1e4] text-black' : ''}`}
                                    onMouseEnter={() => handleMouseEnter('logs')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div
                                        className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]"
                                        onClick={() => handleItemClick('reports')}
                                    >
                                        <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                            <HiOutlineDocumentReport className={` font-thin text-[0.9em] ${hoveredItem !== "logs" && "text-gray-400"}`} />
                                            <span className="title text-[0.9em]">Logs</span>
                                        </span>
                                        <IoIosArrowForward />
                                    </div>
                                    {/* {(activeItem === 'logs' || hoveredItem === 'logs') && (
                                        <ul className='submenu'>
                                            <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]'>
                                                <NavLink to={`/cms/logs/program/${companyId}/${orgId}/${userId}`} className='pl-[2vw]'>
                                                    Program Logs
                                                </NavLink>
                                            </li>
                                            <li className='text-[0.9em] '>
                                                <NavLink to={`/cms/logs/system/${companyId}/${orgId}/${userId}`} rel="noopener noreferrer" className='pl-[2vw]'>
                                                    System Logs
                                                </NavLink>
                                            </li>
                                        </ul>
                                    )} */}

                                    {(activeItem === 'logs' || hoveredItem === 'logs') && (
                                        <ul className='submenu'>
                                            <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]'>
                                                <a href={`/cms/logs/program/${companyId}`} className='pl-[2vw]'>
                                                    Program Logs
                                                </a>
                                            </li>
                                            <li className='text-[0.9em] '>
                                                <a href={`/cms/logs/system/${companyId}`} rel="noopener noreferrer" className='pl-[2vw]'>
                                                    System Logs
                                                </a>
                                            </li>
                                        </ul>

                                    )}
                                </li>)}
                            <li
                                className={`nav-item ${hoveredItem === 'devices' ? 'hovered bg-[#62c1e4] text-black' : ''}`}
                                onMouseEnter={() => handleMouseEnter('devices')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('devices')}>
                                    <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                        <GoDeviceDesktop className='font-thin text-[0.9em]' />
                                        <span className="title text-[0.9em]">Devices</span>
                                    </span>
                                    <IoIosArrowForward />
                                </div>
                                {(activeItem === 'devices' || hoveredItem === 'devices') && (
                                    <ul className='submenu'>
                                        <li className='text-[0.9em]'>
                                            <a href={`/cms/devices/${companyId}`} className='pl-[2vw]'>
                                                Devices
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li
                                className={`nav-item ${hoveredItem === 'installer' ? 'hovered bg-[#62c1e4] text-black' : 'installer'}`}
                                onMouseEnter={() => handleMouseEnter('installer')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('installer')}>
                                    <span className='flex flex-row justify-start  items-baseline gap-x-[1vw] '>
                                        <RiFocus2Fill className={` font-thin text-[1em] ${hoveredItem !== 'installer' && 'text-gray-400'} `} />
                                        {/* <AiOutlineSetting className='font-thin text-[1em]' /> */}

                                        <span className="title text-[0.9em]">Installer</span>
                                    </span>
                                    <IoIosArrowForward />
                                </div>
                                {(activeItem === 'installer' || hoveredItem === 'installer') && (
                                    <ul className='submenu'>
                                        <li className='text-[0.9em] '>
                                            <NavLink to={`/cms/installer/${companyId}/${userId}`} className='pl-[2vw]'>
                                                Installer
                                            </NavLink>
                                        </li>
                                    </ul>
                                )}
                            </li>


                            {userAuthData?.role?.includes('WEBSITE_USER') &&

                                <li
                                    className={`nav-item ${hoveredItem === 'administrator' ? 'hovered bg-[#62c1e4] text-black' : 'administrator'}`}
                                    onMouseEnter={() => handleMouseEnter('administrator')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('administrator')}>
                                        <span className='flex flex-row justify-start  items-baseline gap-x-[0.8vw] '>
                                            <FaUserShield className={` font-thin text-[1.1em] ${hoveredItem !== "administrator" && "text-gray-400"} `} />
                                            <span className="title text-[0.9em]">Administration</span>
                                        </span>
                                        <IoIosArrowForward />
                                    </div>
                                    {(activeItem === 'administrator' || hoveredItem === 'administrator') && (
                                        <ul className='submenu'>
                                            <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]  '>
                                                <a href={`/cms/admin/notifications/${userId}`} className='pl-[2vw]'>
                                                    Notifications
                                                </a>
                                            </li>
                                            <li className='text-[0.9em]  border-b-blue-400 border-b-[0.1em] '>
                                                <a href={`/cms/userroles/${userId}`} target="_blank" rel="noopener noreferrer" className='pl-[2vw]'>
                                                    View/Edit New Users
                                                </a>
                                            </li>
                                            <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em] '>
                                                <a href={`/cms/licenseInfo/${userId}`} className='pl-[2vw]'>
                                                    License Information
                                                </a>
                                            </li>
                                            <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em] '>
                                                <a href={`/cms/admin/organisation/${userId}`} target="_blank" rel="noopener noreferrer" className='pl-[2vw]'>
                                                    View/Edit Account
                                                </a>
                                            </li>

                                            <li className='text-[0.9em]'>
                                                <a href='/cms/company/list' target="_blank" rel="noopener noreferrer" className='pl-[2vw]'>
                                                    View/Edit Companies
                                                </a>
                                            </li>
                                        </ul>
                                    )}
                                </li>}
                        </ul>
                    </div>
                    {lowerWindow && (
                        userAuthData?.role?.includes('WEBSITE_USER') ?

                            (<div className='h-[16vh] px-[2vw] pt-[4vh] absolute bottom-[10vh] font-[400] text-[0.9em] text-[#A6ADBA] bg-[#2C3850] w-full flex flex-col items-start gap-y-[1vh]' onClick={() => setLowerWindow(!lowerWindow)}>

                                <div className='hover:text-white'>
                                    <a href={`/cms/account/${userId}`}>View/Edit User Account</a>
                                </div>
                                <div className='hover:text-white'>
                                    <a href='/cms/logout'>Sign Out</a>
                                </div>
                            </div>) :

                            <div className='h-[26vh] px-[2vw] pt-[4vh] absolute bottom-[10vh] font-[400] text-[0.9em] text-[#A6ADBA] bg-[#2C3850] w-full flex flex-col items-start gap-y-[1vh]' onClick={() => setLowerWindow(!lowerWindow)}>
                                <div className='hover:text-white'>
                                    <a href='/cms/update-profile'>Manage User Account</a>
                                </div>
                                <div className='hover:text-white'>
                                    <a href='/cms/company/list'>Manage Workspaces</a>
                                </div>

                                <div className='hover:text-white'>
                                    <a href='/cms/logout'>Sign Out</a>
                                </div>
                            </div>
                    )}

                    <div className='h-[10vh] px-[0.5vw] pt-[2vh] absolute bottom-0 bg-[#2C3850] w-full flex flex-row justify-between text-white items-start' onClick={() => setLowerWindow(!lowerWindow)}>
                        <div className='flex flex-row items-start'>
                            <FaRegUser className='text-[0.9em]' />
                            {isExpanded && (
                                <div className='flex flex-col ml-[0.6vw]'>
                                    <div className='text-[0.9em]'>
                                        {userAuthData?.email || adminAuthData?.email || ""}
                                    </div>

                                    <div className="text-[0.8em] font-[300] text-gray-300">
                                        <span className="text-secondary font-[400]">Page Access:</span>{" "}
                                        {(() => {
                                            let permission = "";

                                            if (pageName) {
                                                // Check using pageName
                                                permission =
                                                    permissions?.find(({ pageData }) => pageData?.name === pageName)
                                                        ?.permissionLevel || userAuthData?.role1?.toLowerCase();
                                            } else {
                                                // Check using URL
                                                const currentPath = window.location.pathname; // Get current URL path
                                                permission =
                                                    permissions?.find(({ pageData }) =>
                                                        new Function(
                                                            "companyId",
                                                            "orgId",
                                                            "userId",
                                                            `return ${pageData?.path}`
                                                        )(companyId, orgId, userId) === currentPath
                                                    )?.permissionLevel || userAuthData?.role1?.toLowerCase();
                                            }

                                            // Capitalize first letter of each word
                                            return permission
                                                ? permission?.replace(/\b\w/g, (char) => char.toUpperCase())
                                                : "";
                                        })()}
                                    </div>



                                </div>

                            )}
                        </div>
                        <IoIosArrowUp />
                    </div>  </div>

            ) :
                (
                    <div className='collapsed-view bg-[#0d1321] h-screen w-[4vw] fixed z-50 flex flex-col justify-between'>
                        <div>
                            <div className='px-[1vw] pt-[1vh]'>
                                <IoIosMenu className='text-[26px] text-white cursor-pointer' onClick={toggleSidebar} />
                            </div>

                            {/* Logo at the top */}
                            <div className="flex justify-center py-[2vh]">
                                <a href='/cms/dashboard'>
                                    <img
                                        src='/small-logo.png'
                                        className={`h-[60px] ${isExpanded ? 'w-[80%]' : 'w-[26px] object-contain'}`}
                                        alt='company-logo'
                                    />
                                </a>
                            </div>

                            {/* Sidebar Items */}
                            <div className="flex flex-col text-white items-center pt-[5vh] gap-y-[2vh]">
                                {/* Sidebar Item 1 */}
                                <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                                    <div
                                        className={`p-3 ${activeItemCollapse === 1 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                        onMouseEnter={() => handleMouseEnterCollapse(1)}
                                    >
                                        <CgNotes className='font-thin text-[0.9em] hover:cursor-pointer' />
                                    </div>
                                    {activeItemCollapse === 1 && (
                                        <div className="absolute top-0 left-full w-[15vw] bg-[#62c1e4] transition-opacity z-[101]">
                                            <a href="#" className="block p-2 text-[#0d1321] transition-colors">Reports</a>
                                            <a href={`/cms/reports/threat/${companyId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Threat Report</a>
                                            <a href={`/cms/reports/malware/${companyId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Malware Report</a>

                                        </div>
                                    )}
                                </div>

                                {/* Sidebar Item 2 */}
                                {adminAuthData?.role?.includes('ADMINISTRATOR') && (

                                    <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                                        <div
                                            className={`p-3 ${activeItemCollapse === 2 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                            onMouseEnter={() => handleMouseEnterCollapse(2)}
                                        >
                                            < HiOutlineDocumentReport className='font-thin text-[0.9em] hover:cursor-pointer' />
                                        </div>
                                        {activeItemCollapse === 2 && (
                                            <div className="absolute top-0 left-full w-[15vw] bg-[#62c1e4] transition-opacity z-[101]">
                                                <a href="#" className="block p-2 text-[#0d1321] transition-colors">Logs</a>
                                                <a href={`/cms/logs/program/${companyId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Program Logs</a>
                                                <a href={`/cms/logs/system/${companyId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">System Logs</a>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {/* Sidebar Item 3 */}
                                <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                                    <div
                                        className={`p-3 ${activeItemCollapse === 3 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                        onMouseEnter={() => handleMouseEnterCollapse(3)}
                                    >
                                        <GoDeviceDesktop className='font-thin text-[0.9em] hover:cursor-pointer' />
                                    </div>
                                    {activeItemCollapse === 3 && (
                                        <div className="absolute top-0 left-full w-[15vw] bg-[#62c1e4] transition-opacity z-[101]">
                                            <a href="#" className="block p-2 text-[#0d1321] transition-colors">Devices</a>
                                            <a href={`/cms/devices/${companyId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Devices</a>
                                        </div>
                                    )}
                                </div>

                                {/* Sidebar Item 4 */}
                                <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                                    <div
                                        className={`p-3 ${activeItemCollapse === 4 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                        onMouseEnter={() => handleMouseEnterCollapse(4)}
                                    >
                                        <RiFocus2Fill className='font-thin text-[0.9em] hover:cursor-pointer' />
                                    </div>
                                    {activeItemCollapse === 4 && (
                                        <div className="absolute top-0 left-full w-[15vw] bg-[#62c1e4] transition-opacity z-[101]">
                                            <a href="#" className="block p-2 text-[#0d1321] transition-colors">Installer</a>
                                            <a href={`/cms/installer/${companyId}/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Installer</a>
                                        </div>
                                    )}
                                </div>

                                {/* Sidebar Item 5 */}

                                {userAuthData?.role?.includes("WEBSITE_USER") && <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                                    <div
                                        className={`p-3 ${activeItemCollapse === 5 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                        onMouseEnter={() => handleMouseEnterCollapse(5)}
                                    >
                                        <FaUserShield className='font-thin text-[1.1em] hover:cursor-pointer' />
                                    </div>
                                    {activeItemCollapse === 5 && (
                                        <div className="absolute top-0 left-full w-[15vw] bg-[#62c1e4] transition-opacity z-[101]">
                                            <a href="#" className="block p-2 text-[#0d1321] transition-colors">Administration</a>
                                            <a href={`/cms/admin/notifications/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Notification</a>
                                            <a href={`/cms/userroles/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View/Edit New Users</a>
                                            <a href={`/cms/licenseInfo/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">License Information</a>
                                            <a href={`/cms/admin/organisation/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View/Edit Account</a>
                                            <a href={`/cms/company/list`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">  View/Edit Companies</a>

                                        </div>
                                    )}
                                </div>}

                            </div>
                        </div>

                        {/* Bottom User Icon */}
                        <div className='relative'>
                            <div className='absolute bottom-0 left-0 h-[9vh]  w-full flex justify-center py-[2vh] bg-[#2C3850]' onClick={() => setLowerWindow(!lowerWindow)}>
                                <MdKeyboardArrowUp className='text-white' />
                                <FaRegUser className='text-white cursor-pointer' />
                            </div>
                            {lowerWindow && (
                                userAuthData?.role?.includes("WEBSITE_USER") ?
                                    (
                                        <div className='h-[12vh] px-[2vw] pt-[2vh] w-[15vw] z-50 absolute left-[4vw] bottom-[9vh] font-[400] text-[0.9em] text-[#A6ADBA] bg-[#2C3850] flex flex-col items-start gap-y-[1vh]' onClick={() => setLowerWindow(!lowerWindow)}>
                                            <div className='hover:text-white'>
                                                <a href={`/cms/account/${userId}`}>Manage User Account</a>
                                            </div>
                                            <div className='hover:text-white'>
                                                <a href='#' onClick={handleLogout}>Sign Out</a>
                                            </div>
                                        </div>)
                                    :
                                    <div className='h-[26vh] px-[2vw] pt-[4vh] w-[15vw] z-50 absolute left-[4vw] bottom-[9vh] font-[400] text-[0.9em] text-[#A6ADBA] bg-[#2C3850] flex flex-col items-start gap-y-[1vh]' onClick={() => setLowerWindow(!lowerWindow)}>
                                        <div className='hover:text-white'>
                                            <a href='/cms/update-profile'>Manage User Account</a>
                                        </div>
                                        <div className='hover:text-white'>
                                            <a href='/cms/company/list'>Manage Workspaces</a>
                                        </div>

                                        <div className='hover:text-white'>
                                            <a href='#' onClick={handleLogout}>Sign Out</a>
                                        </div>
                                    </div>
                            )}
                        </div>
                    </div>

                )

            }
        </>
    );
};

export default SidebarInstaller;
