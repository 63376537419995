import React, { useEffect, useState, useRef } from 'react'
import Loader from '../../common/Loader'
import '../../css/index.css'
import { IoMdHome } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Link, NavLink, useNavigate } from 'react-router-dom'
// import FilterSection from '../common/FilterSection'
import { FaCheckDouble, FaFilter, FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setFileId, setCompanyId, setUserId } from '../../../Redux/companyid/companyid.action';
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import FilterSection from '../../common/FilterSection';
import DataEntriesNo from '../../components/pagination/DataEntriesNo'
import PageNumber from '../../components/pagination/PageNumber'
import { ImCross } from 'react-icons/im'
import { LuRefreshCcw } from 'react-icons/lu'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCross, FaPlus } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import { FaCheck } from "react-icons/fa";
import SidebarLicense from '../../common/SidebarLicense';
import { MdEmail } from "react-icons/md";
import { FaInfo } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { IoInformationCircleOutline } from "react-icons/io5";

import {
    Circles,
    Oval,
    TailSpin,
    Puff,
    Rings,
    BallTriangle,
    Bars,
    Hearts,
    Grid,
    MutatingDots,
    Audio,
    Watch,
    RevolvingDot
} from 'react-loader-spinner';
import { allOrgList, setSortOptions } from '../../../Redux/allOrganisation/allOrg.action';
import Error from '../../../pages/Error';
import { useSortedData } from '../../../utils/sortUtils';
import FormatDate from '../../../utils/FormatDate';

const AllOrganisation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openShowHide, setOpenShowHide] = useState(false);
  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState("auto");
    const {
        userId, allOrgData, pageNumber, totalRecords, sortOrder, sortBy, filters, loading ,errorAdminAuth } = useSelector((state) => ({
            userId: state.companyid.userId,
            allOrgData: state.allOrg.allOrgList,
            sortOrder: state.allOrg.sortOrder, // Removed duplicate
            pageNumber: state.allOrg.pageNumber,
            totalRecords: state.allOrg.totalRecords,
            sortBy: state.allOrg.sortBy,
            filters: state.allOrg.filters,
            loading: state.allOrg.loading,
            errorAdminAuth:state.adminAuth.errorAdminAuth

        }));
  

    let newSize;
    const fieldParam = new URLSearchParams(location.search).get('field');
    const operandParam = new URLSearchParams(location.search).get('operand');
    const valueParam = new URLSearchParams(location.search).get('value');
    const excludeParam = new URLSearchParams(location.search).get('exclude');
    const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
    const pageNumberParam = new URLSearchParams(location.search).get('page');
    const sortByParam = new URLSearchParams(location.search).get('sortBy');
    const sortOrderParam = new URLSearchParams(location.search).get('sortOrder');
    // Assuming the values are stored as JSON strings in the URL, parse them
    const field = fieldParam ? JSON.parse(fieldParam) : [];
    const operand = operandParam ? JSON.parse(operandParam) : [];
    const value = valueParam ? JSON.parse(valueParam) : [];
    const exclude = excludeParam ? JSON.parse(excludeParam) : [];
    const pageSize = pageSizeParam ? JSON.parse(pageSizeParam) : [];
    const page = pageNumberParam ? JSON.parse(pageNumberParam) : [];
    const sortByCol = sortByParam ? JSON.parse(sortByParam) : [];
    const sortOrderCol = sortOrderParam ? JSON.parse(sortOrderParam) : [];

    const [pageSizeFromURL, setPageSizeFromURL] = useState(100);
    const [currPage, setCurrPage] = useState(1);
    const [filterChange, setFilterChange] = useState();
    const [showFilter, setShowFilter] = useState(false);
    const handleFilter = () => {
        setShowFilter(!showFilter);
    }
    let filtersvalue = React.useMemo(() => {
        return {
            filters: {
                field,
                operand,
                value,
                exclude,
            },
        };
    }, [field, operand, value, exclude]);
    const filtersValueRef = React.createRef(filtersvalue);
    const pageSizeRef = React.createRef(pageSize);
    const [showConfirmationModal, setShowConfirmationModal] = useState(null);
    const [organisationId, setOrganisationId] = useState('');
   const [activeArrow, setActiveArrow] = useState({
        columnName: sortByCol.length > 0 ? sortByCol[0] : "createdAt", // Extract first element or use default
        direction: sortOrderCol.length > 0 ? sortOrderCol[0] : -1, // Extract first element or use default
    });
    useEffect(() => {
        const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
        const parsedSize = pageSizeParam ? parseInt(pageSizeParam) : pageSizeFromURL; // Set default if not provided
        setPageSizeFromURL(parsedSize);
        const pageNumberParam = new URLSearchParams(location.search).get('page');
        const parsedPage = pageNumberParam ? parseInt(pageNumberParam) : currPage;
        setCurrPage(parsedPage);
    }, [location.search]);
    const [currentTime, setCurrentTime] = useState(getCurrentTime()); // Initial state with current time

    // Function to get current time
    function getCurrentTime() {
        const now = new Date();
    
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = String(now.getFullYear()).slice(-2); // Get last two digits of the year
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
    
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
    
    

    // Function to add leading zero if needed
    function addZeroIfNeeded(value) {
        return value < 10 ? '0' + value : value;
    }

    // Function to update current time
    function updateTime() {
        setCurrentTime(getCurrentTime());
        setUpdatedData(!updatedData);
    }
    const [updatedData, setUpdatedData] = useState(false);

    const [duration, setDuration] = useState(false);

    useEffect(() => {
        const cookie = document.cookie;
        // console.log("All cookies:", cookie);
    }, []);
    useEffect(() => {
        const currentURL = window.location.pathname;

        // const parts = currentURL.split('/');
        const parts = currentURL.split('?')[0].split('/');

        const fileid = null;
        const userid = parts[parts?.length - 1]

        dispatch(setUserId(userid))
        dispatch(setFileId(fileid));
        const params = new URLSearchParams(location.search);

        const orgId = params.get('organisationId');

        // Store it in state
        if (orgId) {
            setOrganisationId(orgId);
        }

        if (userid) {
            dispatch(allOrgList(userid, pageSizeFromURL, currPage, sortBy, sortOrder, filtersvalue));
        }
        // dispatch(checkAuthentication())

        setCurrentTime(getCurrentTime());
    }, [ pageNumber, pageSizeFromURL,  filterChange, currentTime, updatedData]);

    const columnsallOrg = [

        {id:0, name: "Organization name", value: "name", type: 'string' },
        {id:1, name: "Country", value: "country", type: 'string' },
        {id:2, name: "State", value: "state", type: 'string' },
        {id:3, name: "Created at", value: "createdAt", type: 'date' },
        {id:4, name: "Updated at", value: "updatedAt", type: 'date' },
        // { name: "Industry", value: "industry", type: 'string' },
        {id:5, name: "Current antivirus", value: "current_antivirus", type: 'string' },
        // { name: "Job role", value: "job_role", type: 'string' },
        // { name: "Business email", value: "email", type: 'string' },
        // { name: "Phone number", value: "phone", type: 'string' },
        // { name: "Company size", value: "company_size", type: 'number' },

    ]

    const [checkedRows, setCheckedRows] = useState([]);
    const actionColumn = { name: "Action", value: "actionCol" };
    const [visibleColumns, setVisibleColumns] = useState(columnsallOrg);
    const [checkedColumns, setCheckedColumns] = useState(columnsallOrg.map(() => true));
    const handleCheckboxChange = (event, index) => {
        const { checked } = event.target;
        const column = columnsallOrg[index];

        setCheckedColumns(prevState => {
            const updatedCheckedColumns = [...prevState];
            updatedCheckedColumns[index] = checked;
            return updatedCheckedColumns;
        });
        setVisibleColumns(prevState => {
            const updatedVisibleColumns = [...prevState];
            // console.log(updatedVisibleColumns, "visibleColumn");
            if (checked) {

                if (!updatedVisibleColumns.find(col => col.id === column.id)) {
                    updatedVisibleColumns.push(column);
                    updatedVisibleColumns.sort((a, b) => a.id - b.id);
                }
            } else {

                const filteredColumns = updatedVisibleColumns.filter(col => col.id !== column.id);
                return filteredColumns;
            }

            return updatedVisibleColumns;
        });
    };

    const selectAllColumns = () => {
        setCheckedColumns(columnsallOrg.map(() => true)); // Mark all columns as checked
        setVisibleColumns(columnsallOrg);
    };

    // Function to deselect all columns
    const deselectAllColumns = () => {
        setCheckedColumns(columnsallOrg.map(() => false)); // Mark all columns as unchecked
        setVisibleColumns([]); // Hide all columns
    };
useEffect(() => {
    const updateHeight = () => {
      if (tableRef.current) {
        const offsetTop = tableRef.current.getBoundingClientRect().top;
        const availableHeight = window.innerHeight - offsetTop;
        setTableHeight(`${availableHeight}px`);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
    const handleSort = (columnName, newSortOrder) => {
        setActiveArrow({
            columnName: columnName,
            direction: newSortOrder
        })

        dispatch(setSortOptions(columnName, newSortOrder));
        const searchParams = new URLSearchParams(location.search);

        // Update the sortBy and sortOrder parameters
        searchParams.set('sortBy', JSON.stringify([columnName])); // Save the sorted column
        searchParams.set('sortOrder', JSON.stringify([newSortOrder])); // Save the sort order
    
        // Use navigate to update the URL with the new query parameters
        navigate({
          pathname: location.pathname, // Keep the current pathname
          search: searchParams.toString(), // Apply the updated search parameters
        });
    };

    const sortedData = useSortedData(allOrgData, activeArrow.columnName,activeArrow.direction);

    const handlePageSizeChange = (event) => {
        event.preventDefault();
        newSize = parseInt(event.target.value);

        const existingParams = new URLSearchParams(location.search);
        existingParams.set('pageSize', JSON.stringify(newSize)); // Update or add page size

        const queryString = existingParams.toString();

        navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

    };
    const [confirmAction, setConfirmAction] = useState(false);
    const [actionStatuses, setActionStatuses] = useState({});
    const handleUserAction = (Id, action, resendMail) => {
        setConfirmAction(true);

        // Build the payload conditionally
        const payload = {
            userId: Id,
            adminId: userId
        };

        if (resendMail) {
            payload.reSendMail = resendMail;
        } else {
            payload.verificationStatus = action;
        }

        // Store the action status in state immediately
        setActionStatuses(prevStatuses => ({
            ...prevStatuses,
            [Id]: 'pending'
        }));

        // Store the action status in localStorage
        localStorage.setItem(Id, JSON.stringify({ actionStatus: 'pending', errMessage: '' }));

        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/update`, payload)
            .then(response => {
                toast.success(response?.data);
                setUpdatedData(!updatedData);

                // Update state and localStorage with 'success' status
                setActionStatuses(prevStatuses => ({
                    ...prevStatuses,
                    [Id]: 'success'
                }));
                localStorage.setItem(Id, JSON.stringify({ actionStatus: 'success', errMessage: '' }));
            })
            .catch(error => {
                // console.error('Error:', error?.message);
                toast.error(error?.response?.data || error?.message);

                // Update state and localStorage with 'failed' status
                setActionStatuses(prevStatuses => ({
                    ...prevStatuses,
                    [Id]: 'failed'
                }));
                localStorage.setItem(Id, JSON.stringify({ actionStatus: 'failed', errMessage: error?.response?.data || error?.message }));
            })
            .finally(() => {
                setConfirmAction(false);
                setShowConfirmationModal(false);
            });
    };

    const handleDeleteUser = (Id) => {
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/delete`, {
            "userId": Id,

            "adminId": userId
        })
            .then(response => {

                toast.success(response?.data);
                setUpdatedData(!updatedData);

            })
            .catch(error => {

                // console.error('Error:', error?.message);
                toast.error(error?.response?.data || error?.message);
            });
        setShowConfirmationModal(false);
    }

    const resetTime = (date) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    };

    const handlePageNumberChange = (newPageNumber) => {
        setCurrPage(newPageNumber);
        const existingParams = new URLSearchParams(location.search);
        existingParams.set('page', JSON.stringify(newPageNumber)); // Update or add page number
        const queryString = existingParams.toString();
        navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

    };
    const handleClick = () => {

    };

    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [showMoreStates, setShowMoreStates] = useState({});
    // Function to toggle show more/less state for 'details'
    const toggleShowMoreDetails = (rowIndex) => {

        const key = `${rowIndex}-details`;
        setShowMoreStates(prevState => ({
            ...prevState,
            [key]: !prevState[key] // Toggle the show more/less state for the specific row and 'details' value
        }));
    };

    const onApplyFilters = (filters) => {

        setFilterChange(filters);

    }

    const [isSidebarExpanded, setSidebarExpanded] = useState(true);

    const toggleSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);
    };

    const buttonRef = useRef(null);

    if (loading) return <Loader />;
    if (errorAdminAuth) {
        return <Error />;
    }
    return (
        <div className="flex h-screen">
            <Helmet>
                <title>Matisoft | All Organization
                </title>
            </Helmet>
            <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25'} bg-gray-200 h-full z-[42] fixed `}>
                {/* Sidebar Content Goes Here */}
                <SidebarLicense isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
            </div>

            {/* Main Content */}
            <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%]'} overscroll-y-none relative overflow-hidden licenseCreation transition-all`}>
            <div className={` ${showFilter || showConfirmationModal ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50 z-[42] `} onClick={handleFilter}></div>

                <div className=" w-full bg-white z-50">
                    <div className={`flex flex-row justify-end items-center px-[2%] pt-[1%] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'}`}>

                    </div>
                    <div className={`flex flex-row items-center justify-between pl-[5vw] mx-auto pt-[2vh] `}>
                        <div className='text-[1.2em] text-primary pb-[1vh] font-[600] py-[2vh] '>All Organization</div>
                    </div>
                    <div className='bg-[#bbe3f55e] text-gray-900 text-[0.9em] py-[1vh] relative w-[3000px] pl-[1%] flex flex-row items-center gap-x-[1%]'>

                        <div className=''>
                            <div className='cursor-pointer hover:text-blue-500' onClick={() => setOpenShowHide(true)} >Show/Hide</div>
                            {openShowHide && <>
                                <div
                                    className="fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50 z-[42] "

                                ></div>
                                <div className='showhide-section-container z-40'>
                                    <div className="modal-body filter-section-content  ">
                                        <button className=' absolute right-[-5px] top-[-5px]' onClick={() => setOpenShowHide(!openShowHide)}>
                                            <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' />
                                        </button>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                {columnsallOrg.map((item, index) => (
                                                    <label className="cont flex flex-row gap-x-[1%] text-white">
                                                        <input
                                                            type="checkbox"
                                                            className="toggle-vis"
                                                            data-column={index + 1}
                                                            checked={checkedColumns[index]}
                                                            defaultChecked
                                                            onChange={(event) => handleCheckboxChange(event, index)}
                                                        />
                                                        <span className="checkmark"></span> <p>{item.name}</p>
                                                    </label>
                                                ))}
                                            </div>
                                            <div></div>
                                        </div>
                                        <div className="row" style={{ marginTop: "20px" }}>
                                            <button className="btn btn-primary apply-button" style={{ marginRight: "10px", marginLeft: "15px" }} onClick={selectAllColumns}>Select All</button>
                                            <button className="btn btn-primary apply-button" onClick={deselectAllColumns}>Deselect All</button>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                        <div className='flex flex-row items-center hover:text-blue-500' onClick={handleFilter}>
                            <span>Filters &nbsp;</span>
                            <FaFilter className={` ${filtersvalue?.filters?.field?.length > 0 ? "text-green-500" : "text-yellow-400"} cursor-pointer`} />
                            {filtersvalue?.filters?.field.length > 0 && <span>&nbsp;{filtersvalue?.filters?.field?.length} applied</span>}
                        </div>
                        {showFilter && <FilterSection showFilter={showFilter} setShowFilter={setShowFilter} columns={columnsallOrg} initialFilters={filtersvalue} onApplyFilters={onApplyFilters} />}
                        <div className='cursor-pointer flex flex-row items-center gap-x-[0.5vw] hover:text-blue-500' onClick={updateTime}>Refresh <LuRefreshCcw /></div>
                        <div>{`Last page updated at : ${currentTime}`}</div>
                    </div>
                    <div className={`flex flex-row justify-between items-center py-1 ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'} border-b-1 border-b-gray-500`}>
                        <DataEntriesNo pageSize={pageSize} handlePageSizeChange={handlePageSizeChange} />
                        <PageNumber
                            pageSize={pageSizeFromURL}
                            currPage={currPage}
                            totalRecords={totalRecords}
                            handlePageNumberChange={handlePageNumberChange}
                        />
                    </div>
                </div>

                {/* Main Content Goes Here */}

     
                <div ref={tableRef} className="overflow-auto w-full -z-10" style={{ maxHeight: tableHeight }}>
                <table className="w-full top-0">
                                      {/* Table Head */}
                                      <thead className="sticky top-0 bg-blue-200 text-[0.9em]">
                                          <tr className=" font-semibold text-gray-800">
              
            
                                              {visibleColumns.map((column, index) => (
                                                  <th key={index} className="py-2 px-3 min-w-[280px] w-[280px] text-center">
                                                      <div
                                                          className="flex items-center justify-center gap-x-1 cursor-pointer"
                                                          onClick={() => handleSort(column.value, activeArrow.columnName === column.value ? -activeArrow.direction : 1)}
                                                      >
                                                          <span title={`${column.type === "date" ? "DD/MM/YYYY": ""}`}>{column.name}</span>
                                                          <p>
                                                              <span className='font-thin text-[0.5em] text-blue-400 flex flex-row items-center arrow'>
                                                                  <FaLongArrowAltUp onClick={() => handleSort(column.value, 1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === 1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] px-0 h-[2vh] cursor-pointer arrow`} />
                                                                  <FaLongArrowAltDown onClick={() => handleSort(column.value, -1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === -1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] h-[2vh] px-0 h-[1vh]cursor-pointer`} />
                                                              </span>
                                                          </p>
                                                      </div>
                                                  </th>
                                              ))}
              
                                          </tr>
                                      </thead>
              
                                      {/* Table Body */}
                                      <tbody className=" text-[0.9em] text-gray-800 border border-b-1 border-b-gray-300">
                                          {sortedData.length > 0 ? (
                                              sortedData.map((item, indexVal) => (
                                                  <tr key={indexVal} className={`${indexVal % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                                      {/* Action Column */}
              
              
                                                      {/* Dynamic Columns */}
              
                                                      {visibleColumns.map((column, colIndex) => {
                                                          const value = column.path ? getValueByPath(item, column.path) : item[column.value];
                                                          return (
                                                              <td
                                                                  key={`${indexVal}-${colIndex}`}
                                                                  className="py-2 px-3 w-[280px] text-center text-[0.9em] break-words"
                                                              >
                                                                  {(() => {
                                                                        if (column.type === "date" && value) {
                                                                            return <span><FormatDate date={value} /></span>;
                                                                          }
                                                                      if (column.value === "name" && value) {
                                                                          // Convert file size to KB and format it to two decimal places
                                                                          return <a href={`/cms/allCompany/${userId}?OrgId=${item._id}`} className='text-blue-600 underline hover:text-blue-800 hover:underline'>
                                                                              {value}
                                                                          </a>;
                                                                      }
                                                                      if (value && typeof value === "string" && value.length > 200) {
                                                                          // Handle long text with "Show More/Show Less"
                                                                          return (
                                                                              <div className="text-container">
                                                                                  {expandedStates[`${indexVal}-${colIndex}`] ? (
                                                                                      <>
                                                                                          <span>{value}</span>
                                                                                          <button
                                                                                              className="show-more-btn text-blue-600"
                                                                                              onClick={() => toggleExpand(indexVal, colIndex)}
                                                                                          >
                                                                                              ...Show Less
                                                                                          </button>
                                                                                      </>
                                                                                  ) : (
                                                                                      <>
                                                                                          <span className="text-truncated">{value.substring(0, 200)}...</span>
                                                                                          <button
                                                                                              className="show-more-btn text-blue-600"
                                                                                              onClick={() => toggleExpand(indexVal, colIndex)}
                                                                                          >
                                                                                              ...Show More
                                                                                          </button>
                                                                                      </>
                                                                                  )}
                                                                              </div>
                                                                          );
                                                                      }
                                                                      // Default case for all other columns
                                                                      return value ? <span>{value}</span> : <span>-</span>; // Handle null/undefined cases
                                                                  })()}
                                                              </td>
                                                          );
                                                      })}
              
              
              
              
              
                                                  </tr>
                                              ))
                                          ) : (
                                              <tr>
                                                  <td colSpan={visibleColumns.length + 3} className="py-2 pl-2">
                                                      No matching data available
                                                  </td>
                                              </tr>
                                          )}
                                      </tbody>
                                  </table>
                              </div>

            </div>

        </div>
    )
}

export default AllOrganisation;