import React from 'react';

const DateFormat = ({ date }) => {
  if (!date || isNaN(new Date(date).getTime())) {
    return <div></div>; // Return an empty div if the date is invalid
  }

  const localDate = new Date(date).toLocaleString("en-IN", {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false, // 24-hour format
  });

  return <div>{localDate}</div>;
};

const DateFormat1 = ({ date }) => {
  if (!date || isNaN(new Date(date).getTime())) {
    return <div></div>; // Return an empty div if the date is invalid
  }

  const localDate = new Date(date).toLocaleString(undefined, {
    year: 'numeric',
    month: 'short', // Short month name (e.g., "Jan", "Feb")
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false, // 24-hour format
  });

  return <div>{localDate}</div>;
};

/**
 * Utility function to format a UTC date string into DD/MM/YY format.
 * @param {string | Date} date - UTC date string or Date object
 * @returns {string} - Formatted date as DD/MM/YY
 */
const FormatDate = (date) => {
  if (!date) return ""; // Return empty string if no date is provided

  const utcDate = new Date(date);
  if (isNaN(utcDate.getTime())) return ""; // Return empty string if invalid date

  const day = String(utcDate.getUTCDate()).padStart(2, '0');
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = String(utcDate.getUTCFullYear()).slice(-2); // Get last two digits of the year

  return `${day}/${month}/${year}`;
};

export { DateFormat, DateFormat1};
