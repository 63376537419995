import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

const FreeTrialBot = () => {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) return null;

    return (
        <div className="fixed bottom-4 right-4 bg-[#1d1d30] opacity-90  text-white shadow-xl p-4 w-[316px] z-[20] rounded-lg border border-gray-300">
            <div className="relative">
                <h3 className="text-md tracking-wider font-[500]  text-gray-200">Discover how Matisoft DLP can help you achieve DPDP Act compliance.</h3>
                <button onClick={() => setIsVisible(false)} className="absolute font-[600] right-0 top-0 text-secondary hover:text-red-500 text-xl"><RxCross2 /></button>
            </div>
            {/* <p className="text-gray-300 text-sm mt-2">
    Want to achieve DPDP compliance?
</p> */}
            <NavLink to='/presignup' target="_blank">
                <button className="mt-3 w-full  button1 uppercase">
                    Start your free trial today
                </button>
            </NavLink>

        </div>
    );
};

export default FreeTrialBot;
