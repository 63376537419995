import { useState, useRef, useEffect } from "react";
import { IoMdNotifications } from "react-icons/io";
import { useSelector } from "react-redux";
import axios from "axios";
import { DateFormat1 } from "./DateFormat";
import { handleHttpError } from "./ErrorMessage";
import { useNavigate } from "react-router-dom";

const NotificationAlert = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [limit, setLimit] = useState(100);
    const [page, setPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [unreadIds, setUnreadIds] = useState([]);
    const [unReadCount, setUnReadCount] = useState({ total: 0, severity: "" });
    const [activeTab, setActiveTab] = useState("informative");
    const [loading, setLoading] = useState(false);

    const dropdownRef = useRef(null);
    const observerRef = useRef(null);
    const notificationRefs = useRef(new Map());
    const navigate = useNavigate();

    const { userId, orgId, permissions, companyId } = useSelector((state) => ({
        userId: state.companyid.userId,
        orgId: state.companyid.orgId,
        permissions: state.companyid.permissions,
        companyId: state.companyid.companyId,
    }));
    // console.log(orgId,"orgId");

    // Fetch count when dropdown is closed
    useEffect(() => {
        if (!isOpen && orgId && userId) {
            handleFetchList("count");
        }
    }, [isOpen, orgId, userId]);

    // Fetch notification list when dropdown is opened
    useEffect(() => {
        if (isOpen) {
            handleFetchList("message", limit, 1);
        }
    }, [isOpen, orgId]);

    // Fetch notifications
    const handleFetchList = (type, limit, page) => {
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/message/log/list`, {
            orgId,
            userId,
            responseType: type,
            ...(type === "message" && { limit, page }),
        })
            .then(response => {
                if (type === "message") {
                    const newMessages = response?.data?.messageList || [];
                    setTotalRecords(response?.data?.countObj?.total || 0);
                    setNotificationList(prevList =>
                        page === 1 ? newMessages : [...prevList, ...newMessages]
                    );
                    setHasMore(limit * page < response?.data?.countObj?.total);
                } else {
                    setUnReadCount(response?.data?.countObj);
                }
            })
            .catch(error => console.error("Error fetching notifications:", error))
            .finally(() => setLoading(false)); // Ensure loading state is reset
    };


    // Load More Handler
    const handleLoadMore = () => {
        if (loading || !hasMore) return; // Prevent multiple API calls

        setLoading(true); // Set loading to true before fetching data
        const nextPage = page + 1;

        handleFetchList("message", limit, nextPage);
        setPage(nextPage);
    };



    // Mark notifications as read
    const updateNotificationList = () => {
        if (unreadIds.length === 0) return;
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/message/log/update`, {
            orgId,
            userId,
            messageIds: unreadIds,
        })
            .then(() => setUnreadIds([]))
            .catch(error => console.error("Error updating notifications:", error));
    };

    const handleRedirect = (pageId) => {
        const matchedPermission = permissions.find(permission => permission.pageData?._id === pageId);
        updateNotificationList(); // Mark as read when clicking outside

        if (matchedPermission) {
            let pagePath = matchedPermission.pageData.path;
            setIsOpen(false);
            if (pagePath) {
                // Remove backticks and evaluate the template string properly
                pagePath = pagePath.replace(/`/g, "").replace(/\${(companyId|orgId|userId)}/g, (_, key) => {
                    return { companyId, orgId, userId }[key] || "";
                });

                console.log("Navigating to:", pagePath);
                window.location.href = pagePath;
                // navigate(pagePath);
            } else {
                console.error("Path not found for the given pageId");
            }
        } else {
            console.error("Permission denied or pageId not found");
        }
    };
    // Handle observer to detect unread notifications
    useEffect(() => {
        if (!isOpen) return;
        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const notificationId = entry.target.getAttribute("data-id");
                if (entry.isIntersecting && notificationId && !unreadIds.includes(notificationId)) {
                    setUnreadIds(prevUnread => [...new Set([...prevUnread, notificationId])]);
                }
            });
        }, { threshold: 1.0 });

        notificationList.forEach(notification => {
            if (!notification.isRead) {
                const element = notificationRefs.current.get(notification._id);
                if (element) observerRef.current.observe(element);
            }
        });

        return () => observerRef.current.disconnect();
    }, [notificationList, isOpen]);

    // Handle clicks outside the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                if (unreadIds.length > 0) updateNotificationList();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isOpen, unreadIds]);


    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => {
                    setIsOpen(!isOpen);

                }}
                className="absolute right-6 top-3 z-[100] rounded-full p-[2px] bg-blue-100 border border-gray-400 hover:bg-blue-200"
            >
                <IoMdNotifications className="text-[1.4em]" />
                {unReadCount?.total > 0 && (
                  <div className={`flex items-center justify-center text-xs font-bold rounded-full 
                    ${unReadCount.severity === "high" ? "bg-red-600 text-white" :
                            unReadCount.severity === "medium" ? "bg-yellow-500 text-gray-800" :
                                "bg-blue-700 text-gray-100"} ${unReadCount.total > 99 ? "right-[-12px] px-[2px]": "right-[-7px] px-[3px]"} absolute  top-[-7px] min-w-[1rem]  aspect-square `}>
                        {unReadCount.total > 99 ? <>99+</> : unReadCount.total}
                    </div>
                )}
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-[370px] bg-[#1E1E1E] opacity-[0.96] text-white shadow-lg rounded-lg p-3 z-[100]">
                    <div className="flex pb-2">
                        <button
                            className={`flex-1 text-sm p-2 ${activeTab === "informative" ? "border-b-2 border-blue-500" : "text-gray-500 hover:border-b-2 hover:border-gray-300"}`}
                            onClick={() => setActiveTab("informative")}
                        >
                            Informative {unReadCount.total > 99 ? '99+' : `(${unReadCount.total})`}
                        </button>
                        {/* <button
                            className={`flex-1 text-sm p-2 ${activeTab === "tasks" ? "border-b-2 border-blue-500" : "text-gray-500 hover:border-b-2 hover:border-gray-300"}`}
                            onClick={() => setActiveTab("tasks")}
                        >
                            Tasks
                        </button> */}
                    </div>
                    <p className="text-gray-500 text-[13px] px-2 pb-[2px]">
                        Showing notifications from the last 30 days
                    </p>

                    <div className="mt-2 text-sm h-[45vh] overflow-y-auto thin-scrollbar">
                        {notificationList.length > 0 ? (
                            notificationList.map((notification) => (
                                <div
                                    key={notification._id}
                                    data-id={notification._id}
                                    ref={(el) => notificationRefs.current.set(notification._id, el)}
                                    className={`border-b p-2 flex flex-row items-start gap-x-2 cursor-pointer
                                        ${!notification.isRead
                                            ? "bg-[#242424] text-white hover:bg-[#252525]"  // Unread emails
                                            : "bg-[#121212] text-[#B0B0B0] hover:bg-[#333333]"}`} // Read emails
                                    onClick={() => handleRedirect(notification?.pageId)}
                                >
                                    <IoMdNotifications
                                        className={`text-xl mr-2 ${notification.severity === "medium"
                                            ? "text-yellow-500"
                                            : notification.severity === "low"
                                                ? "text-blue-500"
                                                : "text-red-500"
                                            } shadowSpecific`}
                                    />
                                    <div className={`${!notification.isRead && "text-white "}`}>
                                        <div className={`${notification.isRead ? "font-[500]" : "font-[600] text-[1.1em]"}`}>
                                            {notification.title}:
                                        </div>
                                        {notification?.groupName && notification?.errorCode && (
                                            <div className={`${!notification.isRead ? "text-white font-[600]" : ""}`}>{`${notification.groupName}: ${handleHttpError(notification?.errorCode)}`}</div>
                                        )}
                                        <div className={`${notification?.isRead ? "text-gray-600" : "text-gray-400"}`}>
                                            <DateFormat1 date={new Date(notification?.createdAt)} />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-400">No notifications to display</p>
                        )}

                        {hasMore && (
                            <button
                                onClick={handleLoadMore}
                                className="w-full text-center text-blue-400 hover:text-blue-300 p-2"
                            >
                                Load More
                            </button>
                        )}
                    </div>

                </div>
            )}
        </div>
    );
};

export default NotificationAlert;
