import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar1 from '../../common/Sidebar1';
import Loader from '../../common/Loader'

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setPermissions, setUserId } from '../../../Redux/companyid/companyid.action';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify'; // Assuming toast is being used for notifications
import { FaAsterisk } from "react-icons/fa";
import ConfirmationDialog from '../../../utils/ConfirmationDialog';
import Error from '../../../pages/Error';
import { LuLoader } from 'react-icons/lu';
// import Loader from '../../common/Loader';
const Notification = () => {
    const dispatch = useDispatch();
    const { loading, userId, permissions, userAuthData, errorUserAuth } = useSelector((state) => ({
        loading: state.userAuth.loading,

        userId: state.companyid.userId,
        permissions: state.companyid.permissions,
        userAuthData: state.userAuth.userAuthData,
        errorUserAuth: state.userAuth.errorUserAuth
    }));
    const [loadingPage, setLoadingPage] = useState(false);
    const [alreadyAddedEmails, setAlreadyAddedEmails] = useState([]);
    const [isSidebarExpanded, setSidebarExpanded] = useState(true);
    const [updatePage, setUpdatePage] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [subscriberEmailOrPhone, setSubscriberEmailOrPhone] = useState({
        email_or_phone: '',
        type: 'email',
        category: 'license',
        allow: true,
    });
    const [notifications, setNotifications] = useState([]);
    const [toBeDeletedEmailId, setToBeDeletedEmailId] = useState([]);
    const [needToUpdateEmail, setNeedToUpdateEmail] = useState([]);
    // console.log(needToUpdateEmail, "need to update");

    const toggleSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);
    };

    useEffect(() => {
        const currentURL = window.location.pathname;
        const parts = currentURL.split('/');
        const userid = parts[parts.length - 1];
        dispatch(setUserId(userid));
    }, [dispatch, updatePage]);
    const [errorList, setErrorList] = useState(null);

    useEffect(() => {
        if (userId) {
            setLoadingPage(true);
            axios.post(`${process.env.REACT_APP_HOST_NAME}/api/notifications/subscription/list`, {
                userId: userId,
            })
                .then((response) => {
                    setAlreadyAddedEmails(response?.data?.data || []);
                    if (response?.data?.permissions) {
                        dispatch(setPermissions(response?.data?.permissions));
                    }
                    setLoadingPage(false);
                    setErrorList(null);
                })
                .catch((err) => {
                    setLoadingPage(false);
                    if (err.response?.status === 401) {
                setErrorList("Unauthorized"); // Add "Unauthorized" in case of 401
            } else {
                setErrorList("Something went wrong"); // Handle other errors
            }
                });
        }
    }, [userId, updatePage]);

    const handleAddEmailOrPhone = () => {




        // if (subscriberEmailOrPhone.email_or_phone.trim() !== '' && isEmailValid) {
        // if (notifications.length >= 5) {
        //     alert('Maximum of 5 entries can be added.');
        //     return;
        // }	
        // console.log('Hello 2');

        const emailOrPhone = subscriberEmailOrPhone.email_or_phone.trim();
        if (emailOrPhone && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailOrPhone)) {
            toast.error("Please input a valid Email ID");

        }
        // Check if email or phone already exists in either notifications or alreadyAddedEmails
        const existsInAlreadyAdded = alreadyAddedEmails.some(
            (entry) => entry.email_or_phone.toLowerCase() === emailOrPhone.toLowerCase()
        );

        const existsInNotifications = notifications.some(
            (entry) => entry.email_or_phone.toLowerCase() === emailOrPhone.toLowerCase()
        );

        if (existsInAlreadyAdded || existsInNotifications) {
            toast.error("This email already exists.")
            return;
        }

        // Proceed if it's valid and not a duplicate
        if (emailOrPhone !== '' && isEmailValid) {
            const newEntry = {
                type: subscriberEmailOrPhone.type,
                category: subscriberEmailOrPhone.category,
                email_or_phone: subscriberEmailOrPhone.email_or_phone,
                allow: subscriberEmailOrPhone.allow,
            };

            setNotifications((prevNotifications) => [...prevNotifications, newEntry]);
            setSubscriberEmailOrPhone({
                email_or_phone: '',
                type: 'email',
                category: 'license',
                allow: true,
            });
            setIsEmailValid(false);
        }
        // }
    };
    const handleDeleteFromTemporaryAddedList = (index) => {
        // Filter out the item at the specified index
        setNotifications((prevNotifications) => prevNotifications.filter((_, i) => i !== index));
    };

    const handleManageToBeDeletedEmail = (id) => {
        setToBeDeletedEmailId((prev) => [...prev, id]);
    };
    const handleCheckboxChange = (index) => {
        const updatedNotifications = notifications.map((item, i) =>
            i === index ? { ...item, allow: !item.allow } : item
        );
        setNotifications(updatedNotifications);
    };
    // const handleManageToBeUpdateEmail = (e, item) => {
    //     const updatedItem = { ...item, action: e.target.checked };
    //     setNeedToUpdateEmail((prev) => {
    //         const exists = prev.some((email) => email._id === updatedItem._id);
    //         if (exists) {
    //             return prev.map((email) => (email._id === updatedItem._id ? updatedItem : email));
    //         } else {
    //             return [...prev, updatedItem];
    //         }
    //     });
    // };
    const handleManageToBeUpdateEmail = (e, item) => {
        const updatedItem = { notificationId: item._id, allow: e.target.checked }; // Update the `allow` field based on checkbox state

        setNeedToUpdateEmail((prev) => {
            const exists = prev.some((email) => email._id === updatedItem.notificationId);
            if (exists) {
                // If it exists, update the entry
                return prev.map((email) => email._id === updatedItem.notificationId ? updatedItem : email);
            } else {
                // If not, add the new entry
                return [...prev, updatedItem];
            }
        });
    };

    const handleConfirmChanges = async () => {
        setLoadingPage(true);

        const promises = [];

        // 1. Add new notifications
        if (notifications.length > 0) {
            promises.push(
                axios.post(`${process.env.REACT_APP_HOST_NAME}/api/notifications/subscription/add`, {
                    userId: userId,
                    notifications: notifications,
                })
                    .then((response) => {
                        toast.success(response?.data);
                        setNotifications([]);
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data || error?.message);
                    })
            );
        }

        // 2. Delete notifications
        if (toBeDeletedEmailId.length > 0) {
            promises.push(
                axios.post(`${process.env.REACT_APP_HOST_NAME}/api/notifications/subscription/remove`, {
                    userId: userId,
                    notificationIds: toBeDeletedEmailId,
                })
                    .then((response) => {
                        toast.success(response?.data);
                        setToBeDeletedEmailId([]);
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data || error?.message);
                    })
            );
        }

        // 3. Update notifications
        if (needToUpdateEmail.length > 0) {
            promises.push(
                axios.post(`${process.env.REACT_APP_HOST_NAME}/api/notifications/subscription/update`, {
                    userId: userId,
                    notificationsToUpdate: needToUpdateEmail,
                })
                    .then((response) => {
                        toast.success(response?.data);
                        setNeedToUpdateEmail([]);
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data || error?.message);
                    })
            );
        }

        // Wait for all requests to complete
        Promise.all(promises)
            .then(() => {
                setUpdatePage(!updatePage); // Trigger page update after all requests
            })
            .finally(() => {
                setLoadingPage(false); // Ensure loading is stopped no matter what
            });
    };

    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleCancel = () => {
        // Show the confirmation dialog
        setShowConfirmation(true);
    };

    const handleConfirmCancel = () => {
        // Handle the cancellation (e.g., reset form, navigate away, etc.)
        setNotifications([]);
        setNeedToUpdateEmail([]);
        setToBeDeletedEmailId([]);
        setSubscriberEmailOrPhone({
            email_or_phone: '',
            allow: true,
            category: 'license',
            type: 'email'
        })
        // setFormData(initialFormData);
        setShowConfirmation(false);
        // Reset or clear the form data if needed
    };

    const handleCloseDialog = () => {
        // Close the confirmation dialog
        setShowConfirmation(false);
    };
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailOrPhoneChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSubscriberEmailOrPhone((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));

        if (name === 'email_or_phone' && subscriberEmailOrPhone.type === 'email') {
            setIsEmailValid(validateEmail(value));
        } else if (name === 'email_or_phone' && subscriberEmailOrPhone.type === 'phone') {
            setIsEmailValid(value.trim() !== '');
        }
    };


    if (loadingPage) return <Loader />;
    // console.log(errorList,"user Auth");

    if (errorUserAuth || errorList === "Unauthorized") {
        return <Error />;
    }
    return (
        <div className="flex h-screen overflow-hidden">
            <Helmet>
                <title>Matisoft | Notifications</title>
            </Helmet>

            {/* Sidebar */}
            <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-[4%]'} bg-gray-200 h-full z-[42] fixed`}>
                <Sidebar1 isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} pageName="Notification" />
            </div>

            {/* Main Content */}
            <div className={`flex-1 ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%]'}  relative overflow-y-auto`}>
                <div className=" w-full bg-white z-50">
                    <h1 className='text-[1.1em] font-[600]  text-primary pt-[4vh] shadow-md pl-[1vw]'>
                        Add Email for License Related Notifications
                    </h1>
                    <div className='flex flex-col py-[1vh] pl-[1vw]'>
                        <form onSubmit={(e) => {
                            e.preventDefault(); // Prevent form submission if condition is false
                            const hasAccess = permissions.some(
                                (permission) =>
                                    permission.pageData?.name === "Notification" &&
                                    permission.permissionLevel === "full access"
                            );

                            if (hasAccess) {
                                handleAddEmailOrPhone();
                            }
                        }}>
                            <div className='py-[1vh] flex flex-row items-center'>

                                <div>
                                    <input
                                        // type={subscriberEmailOrPhone.type === 'email' ? 'email' : 'text'}
                                        type='email'
                                        required
                                        name="email_or_phone"
                                        value={subscriberEmailOrPhone.email_or_phone}
                                        onChange={handleEmailOrPhoneChange}
                                        placeholder={subscriberEmailOrPhone.type === 'email' ? "Enter email address" : ""}
                                        maxLength='254'
                                        className='border-[1px] border-secondary py-[0.5vh] ml-[1vw] px-1 w-[25vw]'
                                    />
                                </div>
                                <button
                                    type="submit"
                                    title={permissions.some(
                                        (permission) =>
                                            permission.pageData?.name === "Notification" &&
                                            permission.permissionLevel === "full access"
                                    ) ? 'Submit' : "You don't have permission"}
                                    className='ml-2 bg-blue-600 px-3 py-1 text-white rounded hover:bg-blue-800'
                                    disabled={!permissions.some(
                                        (permission) =>
                                            permission.pageData?.name === "Notification" &&
                                            permission.permissionLevel === "full access"
                                    )}

                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div style={{ height: '68vh', marginBottom: '12vh' }}>
                    {/* Header for the grid */}
                    <div className="grid font-[600] border  py-2 bg-blue-200 text-gray-900 text-[0.9em]"
                        style={{ gridTemplateColumns: '46% 25% 25%' }}> {/* Enforcing custom widths for grid columns */}
                        <div className="text-center px-3 border-r border-gray-300">Email</div>
                        <div className="text-center border-r border-gray-300 whitespace-nowrap">
                            License Notification
                        </div>
                        <div className="text-center px-3">Remove</div>
                    </div>

                    {/* Display Notifications from the first array */}
                    <div style={{ height: '63vh', marginBottom: '1vh', overflowY: 'auto' }}>
                        <div >
                            {notifications.map((item, index) => (
                                <div className="grid grid-cols-3 py-[0.5vh] border-b border-b-gray-200 text-[0.9em]" key={index}
                                    style={{ gridTemplateColumns: '46% 25% 25%' }}> {/* Updated grid column widths */}
                                    {/* Email section with wrapping */}
                                    <span
                                        className="relative border-r border-gray-300 text-gray-900 flex items-center justify-center px-3 break-words"
                                        style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} // Added styles for wrapping
                                    >
                                        <span className='text-blue-600 absolute left-[2vw] text-[1.5em]'>*</span> {/* Asterisk in blue, positioned left */}
                                        {item.email_or_phone}
                                    </span>

                                    {/* License Notification checkbox, centrally aligned */}
                                    <span className="border-r border-gray-300 flex items-center justify-center px-3">
                                        <input
                                            type="checkbox"
                                            checked={item.allow}
                                            onChange={() => handleCheckboxChange(index)}

                                        />
                                    </span>

                                    {/* Delete Icon, centrally aligned */}
                                    <span className="flex items-center justify-center px-3">
                                        {permissions.some(
                                            (permission) =>
                                                permission.pageData?.name === "Notification" &&
                                                permission.permissionLevel === "full access"
                                        ) && <MdDelete className='text-[1.4em] text-blue-600' onClick={() => handleDeleteFromTemporaryAddedList(index)} />
                                        }                                  </span>
                                </div>
                            ))}
                        </div>

                        {/* Display Already Added Emails from the second array */}
                        <div>
                            {alreadyAddedEmails.length > 0 && alreadyAddedEmails.map((item, index) => {
                                const isDisabled =
                                    toBeDeletedEmailId.includes(item._id) ||
                                    item.email_or_phone === userAuthData.email;
                                const isChanged = needToUpdateEmail.some((email) => email.notificationId === item._id); // Check if the item has been changed

                                return (
                                    <div
                                        key={item._id} className={`grid grid-cols-3 py-[0.5vh] border-b border-b-gray-300 text-[0.9em] ${isDisabled ? 'opacity-60' : ''}`}
                                        style={{ gridTemplateColumns: '46% 25% 25%' }} // Updated grid column widths
                                    >
                                        {/* Email section with wrapping */}
                                        <span
                                            className="border-r border-gray-300 text-gray-900 flex items-center justify-center px-3 break-words"
                                            style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} // Added styles for wrapping
                                        >
                                            {item?.email_or_phone}
                                        </span>

                                        {/* License Notification checkbox, centrally aligned */}
                                        <span className="border-r border-gray-300 flex items-center justify-center px-3">
                                            <input
                                                type="checkbox"
                                                checked={isChanged ? isChanged.allow : item?.allow}
                                                onChange={(e) => handleManageToBeUpdateEmail(e, item)}
                                                disabled={isDisabled}
                                                className={isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}

                                            />
                                        </span>

                                        {/* Delete Icon, centrally aligned */}
                                        <span className="flex items-center justify-center px-3">
                                            {permissions.some(
                                                (permission) =>
                                                    permission.pageData?.name === "Notification" &&
                                                    permission.permissionLevel === "full access"
                                            ) && <MdDelete
                                                    className={`text-[1.4em] text-red-600 hover:text-orange-600 ${isDisabled ? 'text-gray-400 cursor-not-allowed' : 'cursor-pointer'}`} // Change icon color if disabled
                                                    onClick={() => !isDisabled && handleManageToBeDeletedEmail(item._id)} // Prevent deletion if disabled
                                                />}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* Buttons */}
                <div className="fixed bottom-0 right-0 max-w-[96vw] w-full  h-[12vh] bg-blue-100 z-20 flex flex-row justify-between items-center px-2">
                <p className=' flex space-x-2 pl-[16vw] text-[0.9em] '>
                        Asterisk (*) indicates newly added emails that will be saved after confirming changes.
                    </p>
                    <div className='flex space-x-2 flex-nowrap '>
                        <button
                            className='bg-secondary font-[500] text-black border border-secondary px-3 py-2 rounded-sm hover:bg-white hover:text-[#3991b1]'
                            onClick={() => {
                                permissions.some(
                                    (permission) =>
                                        permission.pageData?.name === "Notification" &&
                                        permission.permissionLevel === "full access"
                                ) && handleConfirmChanges()
                            }}
                            title={
                                !permissions.some(
                                    (permission) =>
                                        permission.pageData?.name === "Notification" &&
                                        permission.permissionLevel === "full access"
                                ) ? "You don't have the required permissions to make changes."
                                    : !(notifications.length > 0 || toBeDeletedEmailId.length > 0 || needToUpdateEmail.length > 0)
                                        ? "Make a change before confirming."
                                        : ""
                            }
                            disabled={
                                !(notifications.length > 0 || toBeDeletedEmailId.length > 0 || needToUpdateEmail.length > 0) ||
                                !permissions.some(
                                    (permission) =>
                                        permission.pageData?.name === "Notification" &&
                                        permission.permissionLevel === "full access"
                                )}
                        >
                            Confirm Changes
                        </button>

                        <button className='bg-secondary px-3 py-2 font-[500] border border-secondary rounded-sm hover:bg-white'
                            //  disabled={permissions[0]?.permissionLevel!=='read_write'}
                            onClick={() => {
                                permissions.some(
                                    (permission) =>
                                        permission.pageData?.name === "Notification" &&
                                        permission.permissionLevel === "full access"
                                ) && handleCancel()
                            }}
                            disabled={
                                !(notifications.length > 0 || toBeDeletedEmailId.length > 0 || needToUpdateEmail.length > 0) ||
                                !permissions.some(
                                    (permission) =>
                                        permission.pageData?.name === "Notification" &&
                                        permission.permissionLevel === "full access"
                                )}
                        >Discard Changes</button>

                    </div>

                   
                </div>
                <ConfirmationDialog
                    isOpen={showConfirmation}
                    onClose={handleCloseDialog}
                    onConfirm={handleConfirmCancel}
                />
            </div>
        </div>
    );
};

export default Notification;
