import React, { useEffect, useState, useRef } from "react";
import Footer from "../Footer";
import Header from "../Header1";
import { Helmet } from "react-helmet";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { BsTable } from "react-icons/bs";


const DpDpAct = () => {
  const [activeSection, setActiveSection] = useState("why-dpdp-introduced");
  const sectionRefs = useRef({});
  const [isToCOpen, setIsToCOpen] = useState(false);

  const dataTable = [
    { type: "Personal Data Breach", penalty: "Up to INR 250 Crores" },
    { type: "Failure to Notify Data Breach", penalty: "Up to INR 200 Crores" },
    { type: "Breach in Observance of Additional Obligations in Relation to Children", penalty: "Up to INR 200 Crores" },
    { type: "Breach of Additional Obligations of Significant Data Fiduciary", penalty: "Up to INR 150 Crores" },
    { type: "Breach of Duties under Section 15 (Ensure compliance, authenticity, and truthful data)", penalty: "Up to INR 10 thousand" },
    { type: "Breach of Voluntary Undertakings", penalty: "Penalties corresponding to the relevant breach" },
    { type: "Other Breaches", penalty: "Up to INR 50 Crores" }
  ];
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    Object.values(sectionRefs.current).forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      Object.values(sectionRefs.current).forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  const [scrollProgress, setScrollProgress] = useState(0);


  // Handle Scroll Progress
  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);

      // Determine which section is in view
      Object.keys(sectionRefs.current).forEach((id) => {
        const section = sectionRefs.current[id];
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top >= 0 && rect.top < window.innerHeight * 0.3) {
            setActiveSection(id);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Smooth Scroll to Section
  const handleScrollToSection = (id) => {
    const section = sectionRefs.current[id];
    if (section) {
      const navbarHeight = document.querySelector("nav")?.offsetHeight || 90; // Get dynamic navbar height
      const topPos = section.getBoundingClientRect().top + window.scrollY - navbarHeight;
      window.scrollTo({ top: topPos, behavior: "smooth" });
      setActiveSection(id); // Ensure active section updates on click
    }
  };

  return (
    <>
      <Helmet>
        <title>Matisoft | DPDP</title>
      </Helmet>
      {/* <Header /> */}
      <div className="w-full text-justify">
        {/* Hero Section */}
        <section className=" flex flex-col min-h-[90vh]  md:flex-row gap-y-[2vh] justify-start pt-[5vh] md:pt-0   items-center  text-white text-center md:px-[4vw] relative dpdp-hero">
          {/* Left Text Content */}
          <div className="px-8  gap-y-[2vh] flex flex-col items-center  md:w-1/2 md:items-start">
            <h1 className="text-2xl md:text-2xl xl:text-4xl 2xl:text-5xl font-bold   text-justify  ">
              Understanding the Digital Personal Data Protection Act (DPDP Act)
            </h1>
            <p className="mt-4 tracking-wider max-w-2xl text-xl md:text-xl xl:text-[22px] text-justify 2xl:text-2xl text-gray-100 ">
              The DPDP Act sets clear rules on how your personal data should be collected, stored, and used. It aims to prevent misuse, ensure privacy, and hold organizations accountable for data handling.            </p>
          </div>
          <img className=" px-[5vw] max-w-[400px]  rounded-md  md:hidden" src="images/dpdp-side.jpg" alt="dpdp-security" />
          {/* Image Section */}


        </section>

        {/* Horizontal Scroll Progress Bar */}
        <div className="sticky top-[77px] md:top-[80px] left-0 w-full h-[5px] bg-gray-200 z-10">
          <div className="h-[8px] bg-[#55accc]" style={{ width: `${scrollProgress}%` }}></div>
        </div>
        {/* TOC & Content Section */}
        <div className="flex flex-col md:flex-row">
          {/* Table of Contents - Sidebar */}
          <aside className="w-full md:w-1/4 md:sticky top-[100px] h-auto md:h-[calc(100vh-100px)] p-4 md:p-6 bg-gray-100 border-b md:border-r overflow-y-auto max-h-[80vh]">
            {/* Mobile ToC Button */}

            <div className="md:hidden">
              <button
                onClick={() => setIsToCOpen(!isToCOpen)}
                className="w-full flex justify-between items-center px-4 py-2 bg-gray-200 rounded-md"
              >
                <span className="font-semibold">Table of Contents</span>
                <IoIosArrowDown className={`transition-transform ${isToCOpen ? "rotate-180" : ""}`} />
              </button>
            </div>
            <h3 className="font-semibold text-gray-800 text-lg 2xl:text-xl text-center md:flex flex-row items-center justify-between hidden ">Table of Contents </h3>

            {/* Table of Contents List */}
            <ul className={`mt-4 text-sm 2xl:text-[15px] text-justify space-y-2 ${isToCOpen ? "block" : "hidden"} md:block`}>
              {[
                { id: "why-dpdp-introduced", title: "Why was the Digital Personal Data Protection Act (DPDP Act) introduced?" },
                { id: "overview", title: "Overview" },
                { id: "what-is-dpdp-act", title: "What is the Digital Personal Data Protection Act (DPDP Act)?" },
                { id: "key-rules-compliance", title: "Key Rules and Compliance under the DPDP Act" },
                { id: "matisoft-dlp-safeguard", title: "How does Matisoft Data Leak Prevention (DLP) safeguard personal data under the DPDP Act?" },
                { id: "matisoft-dlp-use-case", title: "Use Case: How Matisoft DLP can help prevent penalties?" },
                { id: "personal-data-pii", title: "What is Personal Data/Personally Identifiable Information (PII)?" },
                { id: "key-stakeholders", title: "Key stakeholders in the DPDP Act" },
                { id: "key-provisions-dpdp-act", title: "Key provisions of the DPDP Act" },
                { id: "who-does-dpdp-impact", title: "Who does the DPDP Act impact?" },
                { id: "penalties-for-noncompliance", title: "Penalties for Non-Compliance" },
                { id: "kickstart-your-compliance", title: "Kickstart your Compliance journey with Matisoft DLP" }


                
              ].map(({ id, title }) => (
                <li key={id} className="text-justify">
                  <button
                    onClick={() => handleScrollToSection(id)}
                    className={`block w-full  px-3 py-2 rounded-md text-justify transition ${activeSection === id ? "bg-secondary text-gray-800" : "text-gray-700 hover:bg-gray-200"
                      }`}
                  >
                    {title}
                  </button>
                </li>
              ))}
            </ul>
          </aside>

          {/* Main Content */}
          <main className="w-full md:w-3/4 p-4 md:p-8">
            {/* Section: Introduction */}


            <section
              id="why-dpdp-introduced"
              ref={(el) => (sectionRefs.current["why-dpdp-introduced"] = el)}
              className=" border-b py-8 scroll-mt-[120px]"
            >
              <h2 className="text-2xl font-bold pb-[1vh]">Why was the Digital Personal Data Protection Act (DPDP Act) introduced?</h2>
              <p className="mt-4 text-gray-700 tracking-wider">
                Have you ever opened a bank account or applied for a loan and then suddenly started receiving nonstop calls about personal loans, credit cards, health insurance, or life insurance?
                <p className="py-[2vh]">This happens because banks often don’t prioritize the protection of your personal data. They may not keep it confidential and might share it with other departments to push more products and services your way. In some cases, they may even sell your personal information to third-party companies, leading to those annoying sales calls.</p>
                <p className="py-[2vh]"> The personal details you entrusted them with were exploited and shared without your explicit consent.</p>
                To put an end to this misuse and enforce strict privacy protection, the<strong> Digital Personal Data Protection Act</strong> (DPDP Act) was introduced.              </p>
            </section>

            <section
              id="overview"
              ref={(el) => (sectionRefs.current["overview"] = el)}
              className="border-b py-8 scroll-mt-[120px]"
            >
              <h2 className="text-2xl font-bold pb-[1vh]">Overview</h2>
              <p className="mt-4 text-gray-700 tracking-wider text-justify">
                To address concerns about personal data being shared without consent, the Indian government has taken legislative steps to safeguard your identity.
              </p>

              <p className="py-[2vh] text-gray-700">
                The Digital Personal Data Protection Bill was introduced on 3rd August, 2023.
              </p>

              <p className="py-[2vh] text-gray-700">
                On 3rd January, 2025, the <strong>Ministry of Electronics and Information Technology</strong> (MeitY) released a draft of the <strong>Digital Personal Data Protection Act</strong> for public feedback before final implementation. The government aims to notify and begin implementing these rules by mid-2025.
              </p>

              <p className="py-[2vh] text-gray-700">
                To ensure effective enforcement, the government has also allocated ₹5 crore for the <strong>Data Protection Board of India</strong> (DPI), a 2.5x increase for FY25-26.
              </p>

            </section>

            <section
              id="what-is-dpdp-act"
              ref={(el) => (sectionRefs.current["what-is-dpdp-act"] = el)}
              className=" border-b py-8 scroll-mt-[120px]"
            >
              <h2 className="text-2xl font-bold ">What is the Digital Personal Data Protection Act (DPDP Act)?</h2>
              <p className="mt-4 text-gray-700 tracking-wider text-justify">
                <p className="py-[2vh]">   The <strong> Digital Personal Data Protection Act</strong> (DPDP Act) is a law enacted by the Parliament of India to regulate the processing of <strong>digital personal data</strong>. It aims to balance individuals' rights to protect their personal data with the need for such data to be processed for lawful purposes.
                </p><p className="py-[2vh]">      Much like other global data protection laws, such as the<strong> GDPR</strong> (General Data Protection Regulation) in the European Union and<strong> CCPA</strong> (California Consumer Privacy Act) in the United States, the <strong>DPDP Act</strong> applies to organizations both within and outside India that offer goods or services to Indian citizens and process their personal data.               </p>
              </p>   </section>


            <section
              id="key-rules-compliance"
              ref={(el) => (sectionRefs.current["key-rules-compliance"] = el)}
              className="  border-b py-8 scroll-mt-[120px]"
            >
              <h2 className="text-2xl font-bold pb-[2vh] mb-4">Key Rules and Compliance under the DPDP Act</h2>
              <ul className="list-disc pl-6 gap-y-[1vh] mt-4 text-gray-700 space-y-[2vh] tracking-wider">
                <li><span className="font-bold">Mandatory Consent:</span> Organizations must obtain explicit consent from individuals before collecting their personal data. </li>
                <li><span className="font-bold">Clear Communication:</span> Individuals should be informed in simple language about the data being collected and its intended use. </li>
                <li><span className="font-bold">Data Retention Limitation: </span> Personal data must be deleted once its purpose is fulfilled or upon withdrawal of consent by the individual.</li>
                <li><span className="font-bold">Advance Deletion Notice:</span> Organizations are required to provide a <strong>48-hour</strong> notice to individuals before deleting their personal data. </li>

                <li><span className="font-bold">Purpose-Specific Consent:</span> Separate consent is required if the collected data is to be used for a different purpose than originally stated. </li>
                <li><span className="font-bold">Fresh Consent for Reuse:</span> If an organization intends to process the data for a new purpose or use it beyond what was originally agreed upon, it must obtain new consent from the individual. </li>
                <li><span className="font-bold">Only necessary data can be collected: </span> Organizations cannot collect excessive or irrelevant personal information.</li>
                <li><span className="font-bold">Consent records must be maintained:</span> Companies must keep track of all consents given and ensure compliance. </li>


              </ul>
            </section>

            <section
              id="matisoft-dlp-safeguard"
              ref={(el) => (sectionRefs.current["matisoft-dlp-safeguard"] = el)}
              className=" border-b py-8 scroll-mt-[120px]"
            >
              <h2 className="text-2xl font-bold py-[2vh]">How does Matisoft Data Leak Prevention (DLP) safeguard personal data under the DPDP Act?</h2>
              <p className="mt-4 text-gray-700 tracking-wider">
                Matisoft Data Loss Prevention (DLP) solution safeguards <strong> personal data </strong> from leaving your organization’s systems, ensuring enhanced compliance with <strong>  DPDP Act regulations</strong>.
                <p className="py-[2vh]">  Files containing personal data such as <strong> invoices, resumes, customer forms, emails,</strong> and <strong>employee records</strong>, are often at risk of being accidentally or intentionally shared outside the organization by employees. Such incidents can make the company liable under the DPDP Act, resulting in severe penalties.   </p>

                <p className="py-[2vh]"> To prevent data leaks,<strong> Matisoft DLP</strong> continuously <strong> monitors</strong> and <strong> blocks</strong> all potential <strong> channels</strong> through which sensitive data could be leaked outside your organization. This includes <strong> emails, cloud uploads, USB devices,</strong> and more.
                </p>It <strong> scans</strong> for <strong> sensitive keywords</strong>, such as personal information, and automatically <strong> blocks </strong> any <strong> files</strong> or <strong> emails</strong> containing such data, generating real-time alerts to keep you informed.              </p>

              <p className="py-[3vh] text-gray-700">By implementing Matisoft DLP, your organization can: - </p>
              <ul className=" text-gray-700 list-inside list-disc space-y-[1vh] tracking-wider">
                <li>Effectively safeguard sensitive data, </li>
                <li>Achieve greater compliance with DPDP Act regulations, </li>
                <li>Reduce the risk of breaches and penalties, and </li>
                <li>Ensure that your business secrets are not leaked. </li>

              </ul>

            </section>

            <section
              id="matisoft-dlp-use-case"
              ref={(el) => (sectionRefs.current["matisoft-dlp-use-case"] = el)}
              className=" border-b py-8 scroll-mt-[120px]"
            >
              <h2 className="text-2xl font-bold pb-[2vh]">Use Case: How Matisoft DLP can help prevent penalties?</h2>
              <p className="mt-4 text-gray-700 tracking-wider">
                <p>   If a team member, whether deliberately or accidentally,<strong> shares</strong> a <strong>file</strong> containing <strong> customers' </strong> or <strong> vendors' personal data</strong> via email or personal cloud storage<strong> outside</strong> the <strong>organization</strong>, the <strong> organization becomes liable</strong> for the <strong> data leak</strong>. This means the organization is responsible for informing both the <strong> Data Protection Board of India (DPBI)</strong> and the individual whose data was exposed.</p>
                <p className="py-[2vh]">The<strong> DPBI</strong> will then <strong> investigate</strong>, and if shortcomings or shortfalls in data protection are found, it may <strong> impose penalties</strong> of up to <strong> ₹250 crore</strong>.</p>
                Matisoft DLP helps organizations prevent such incidents by <strong> blocking</strong> this <strong> data sharing outside your organization</strong>, ensuring compliance, and minimizing the risk of legal and financial consequences.              </p>

              <p className="text-gray-700 py-[2vh]"><NavLink to="/presignup" target="_blank" className="text-blue-600 underline hover:text-blue-700 font-semibold"> Start Your Free Trial of Matisoft DLP Today</NavLink> and take the first step toward robust data protection.</p>
            </section>

            {/* Section: What is Personal Data */}
            <section
              id="personal-data-pii"
              ref={(el) => (sectionRefs.current["personal-data-pii"] = el)}
              className=" border-b py-8 scroll-mt-[120px]"
            >
              <h1 className="text-3xl font-bold pb-[2vh] mb-[2vh] text-gray-700 ">Technical Aspects of the DPDP Act</h1>
              <h2 className="text-2xl font-bold pb-[1vh]">What is Personal Data/Personally Identifiable Information (PII)?</h2>
              <p className="mt-4 text-gray-700 tracking-wider text-justify">
                Personal data/PII refers to any information that can identify an individual, such as names, contact details or financial information.
                <p className="py-[2vh]">    Examples of files or documents that may contain personal data include customer forms, invoices, emails, employee records, health information etc.              </p>
              </p>  </section>
            {/* </main>
    </div> */}
            {/* Section 2: What is Personal Data */}






            {/* Section 3: DPDP Act Key Highlights */}
            <section
              id="key-stakeholders"
              ref={(el) => (sectionRefs.current["key-stakeholders"] = el)}
              className=" border-b py-8 scroll-mt-[120px]"
            >
              <h2 className="text-2xl font-bold pb-[1vh]">Key stakeholders in the DPDP Act</h2>
              <ul className="list-disc  mt-4 text-gray-700 space-y-[2vh] tracking-wider pl-6">
                <li><span className="font-bold">Data Principal:</span> Individuals within the territory of India whose personal data is being processed.</li>
                <li><span className="font-bold">Data Fiduciary:</span> Data Fiduciaries are organizations deciding what data is to be collected and how it is to be collected and the purposes for which it needs to be used.</li>
                <li><span className="font-bold">Data Processors:</span> Data Processors are organizations that process data on behalf of Fiduciaries based on their instructions.</li>
                <li><span className="font-bold">Consent Manager: </span>Consent Managers would assist Data Principals and Data Fiduciaries to give, manage, review and withdraw consent.</li>
                <li><span className="font-bold">Significant Data Fiduciary (SDF): </span>Organization that processes large volumes of sensitive data sets will be declared by the Government as Significant Data Fiduciary.</li>
              </ul>
            </section>
            <section
              id="key-provisions-dpdp-act"
              ref={(el) => (sectionRefs.current["key-provisions-dpdp-act"] = el)}
              className=" border-b py-8 scroll-mt-[120px]"
            >
              <h2 className="text-2xl font-bold pb-[1vh] mb-4">Key Provisions of the DPDP Act</h2>

              <ul className=" text-gray-700 list-decimal list-inside space-y-[3vh] tracking-wider">
                <li><strong className="text-gray-900 text-lg">Data Collection and Consent:</strong>
                  <ul className="list-disc  pt-[2vh] pl-9 space-y-3">
                    <li><strong>Explicit Consent: </strong>  Organizations must obtain clear, informed, and voluntary consent from individuals before collecting or processing personal data. The consent must be granular, meaning individuals must approve specific uses of their data. </li>

                    <li><strong>Purpose Limitation: </strong> Data can only be collected for a specific, legitimate purpose, and it cannot be used for unrelated purposes later.</li>
                    <li><strong>Data Minimization: </strong>Data collected should only be what is necessary for the intended purpose. Organizations should avoid excessive data collection. </li>

                  </ul>

                </li>
                <li><strong className="text-gray-900 text-lg">Rights of Data Subjects:</strong>

                  <ul className="list-disc  space-y-3 pt-[2vh] pl-9">
                    <li><strong>Right to Access:</strong>  Individuals have the right to request access to the personal data organizations hold about them. This ensures transparency in data collection and processing.</li>

                    <li><strong>Right to Correction and Erasure: </strong>If the data held is inaccurate or outdated, individuals can request corrections. Moreover, they can ask for their data to be deleted if it is no longer required for the purpose for which it was collected.</li>
                    <li><strong>Right to Withdraw Consent: </strong>Individuals can withdraw consent for the use of their personal data at any time, which means that any ongoing processing based on consent must cease. </li>
                    <li><strong>Right to Data Portability: </strong>This provision allows individuals to transfer their personal data from one data controller to another without hindrance. </li>

                  </ul>
                </li>
                <li><strong className="text-gray-900 text-lg">Data Protection Impact Assessments (DPIA):</strong>

                  <ul className="list-disc  pt-[2vh] pl-9 space-y-3">
                    <li><strong>Mandatory DPIA: </strong>  Organizations must carry out a Data Protection Impact Assessment (DPIA) before engaging in processing activities that may impact the privacy of individuals. This is essential for high-risk activities like large-scale data processing or processing sensitive data. The DPIA helps identify and mitigate risks associated with data processing activities. </li>

                  </ul>

                </li>


                <li><strong className="text-gray-900 text-lg">Appointment of Data Protection Officer (DPO):</strong>

                  <ul className="list-disc  pt-[2vh] pl-9">
                    <li><strong>Obligations for Large-Scale Data Processors: </strong>  Certain organizations, particularly those processing large volumes of personal data, must appoint a Data Protection Officer (DPO). The DPO is responsible for ensuring compliance with the provisions of the Act.  </li>

                  </ul>

                </li>
                <li><strong className="text-gray-900 text-lg">Cross-Border Data Transfers:</strong>

                  <ul className="list-disc  pt-[2vh] pl-9">
                    <li><strong>Regulation of International Transfers: </strong> The Act sets out guidelines for transferring personal data outside of India. This includes certain restrictions where personal data can only be transferred to countries or regions with adequate data protection laws. </li>

                  </ul>
                </li>

                <li><strong className="text-gray-900 text-lg">Data Security and Protection Measures:</strong>

                  <ul className="list-disc  pt-[2vh] pl-9">
                    <li><strong>Data Security Obligations: </strong> Organizations must implement stringent data security measures to protect the personal data they hold. These measures include encryption, access controls, regular audits, and risk assessments. Failure to adopt such measures could result in severe penalties.  </li>

                  </ul>

                </li>

                <li><strong className="text-gray-900 text-lg">Penalties for Non-Compliance:</strong>

                  <ul className="list-disc  pt-[2vh] pl-9">
                    <li><strong>Fines and Penalties: </strong> The Act outlines heavy penalties for non-compliance. These can be up to <strong> INR 250 crore</strong> depending on the nature of the violation. </li>

                  </ul>

                </li>

                <li><strong className="text-gray-900 text-lg">Data Breach Notification:</strong>

                  <ul className="list-disc  pt-[2vh] pl-9">
                    <li><strong>72-Hour Breach Notification:</strong> In case of a data breach, organizations are required to notify both the Data Protection Authority (DPA) and the affected individuals within 72 hours. Timely reporting is essential to mitigate the damage caused by breaches and ensure that data subjects are aware of any potential risks. </li>

                  </ul>

                </li>

                <li><strong className="text-gray-900 text-lg">Enforcement by the Data Protection Authority (DPA):</strong>

                  <ul className="list-disc  pt-[2vh] pl-9">
                    <li><strong>Role of the DPA:</strong> The Data Protection Authority (DPA), an independent body, is responsible for enforcing the provisions of the Act. The DPA investigates violations, issues guidelines, and takes enforcement action, including imposing fines on organizations that fail to comply. </li>

                  </ul>
                </li>

                <li><strong className="text-gray-900 text-lg" >Children's Data Protection:</strong>

                  <ul className="list-disc  pt-[2vh] pl-9">
                    <li><strong>Special Provisions for Children:</strong> Data collection from children under the age of 18 requires specific safeguards, and organizations must ensure they obtain parental consent for processing children's data. </li>

                  </ul>

                </li>
              </ul>
            </section>




            <section id="who-does-dpdp-impact" ref={(el) => (sectionRefs.current["who-does-dpdp-impact"] = el)} className=" border-b py-8 scroll-mt-[120px]">
              <h2 className="text-2xl font-bold pb-[1vh] mb-1">Who does the DPDP Act impact?</h2>
              <p className="tracking-wider py-[2vh] text-gray-700">The DPDP Act applies to any organization handling digital personal data. Key scenarios include:</p>

              <ol className="mt-1 text-gray-700 space-y-[2vh] pl-6 list-disc tracking-wider">
                <li> Managing data in digital form or converting offline data to digital.</li>
                <li>Processing data within India or offering goods/services to people in India from abroad.</li>
                <li>Industries like IT, healthcare, e-commerce, and finance are particularly affected, as they handle vast amounts of sensitive data daily.</li>

              </ol>
            </section>

            <section id="penalties-for-noncompliance" ref={(el) => (sectionRefs.current["penalties-for-noncompliance"] = el)} className=" border-b py-8 scroll-mt-[120px]">
              <h2 className="text-2xl font-bold pb-[1vh]">Penalties for Non-Compliance</h2>
              <p className="tracking-wider text-gray-700 pb-[4vh] mt-4 mb-1">The DPDP Act enforces strict penalties for violations, ensuring accountability. Below is a detailed penalty table summarizing key infractions and their corresponding fines:</p>



              <table className="w-full border-collapse border border-gray-300 text-gray-900">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 px-4 py-2 text-left">Type of Breach</th>
                    <th className="border border-gray-300 px-4 py-2 text-left">Penalty (INR)</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTable.map((item, index) => (
                    <tr key={index} className="odd:bg-white even:bg-gray-100">
                      <td className="border border-gray-300 px-4 py-2">{item.type}</td>
                      <td className="border border-gray-300 px-4 py-2">{item.penalty}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <h2 className="text-xl font-bold py-[1vh] mt-[3vh]">Kickstart your Compliance journey with Matisoft DLP</h2> */}
              

            </section>

            <section id="kickstart-your-compliance" ref={(el) => (sectionRefs.current["kickstart-your-compliance"] = el)} className=" border-b py-8 scroll-mt-[120px]">
              <h2 className="text-2xl font-bold py-[1vh] mb-1">Kickstart your Compliance journey with Matisoft DLP</h2>
              <p className="py-[2vh] text-gray-700 text-justify tracking-wider">With the DPDP Act set to take effect soon, protecting personal data is no longer just a moral obligation—it is now a legal requirement. Implementing Matisoft DLP helps reduce the risk of breaches, avoid costly penalties, and safeguard sensitive customer and confidential business data from leaks.</p>
              <p className="text-gray-700 text-justify tracking-wider"><NavLink className="text-blue-600 underline font-semibold hover:text-blue-700" to="/presignup" target="_blank">Start Your Free Trial of Matisoft DLP Today</NavLink> and take the first step toward robust data protection.</p>
         
            </section>


          </main>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default DpDpAct;
