import React from 'react'
import { ImCross } from 'react-icons/im'

const PermissionTable = ({accessHelp,setAccessHelp}) => {
  return (
         <div className='fixed bg-blue-100 opacity-[0.96] border-white-100 border-[1px] rounded w-[86vw] left-[7vw] z-[200] top-[3vh] bottom-[3vh] text-[1em] ' style={{ zIndex: '120' }}>
                                     <div className='filter-section-content relative'>
                                         <button
                                             className='absolute right-[-5px] top-[-5px]'
                                             onClick={() => { setAccessHelp(!accessHelp) }}
                                         >
                                             <ImCross className='text-white bg-red-600 hover:bg-red-700 p-1 text-2xl font-[200] rounded-full' onClick={() => setAccessHelp(false)} />
                                         </button>
                                         <h1 className="text-[1.3em] shadow-md font-[500] pb-[1vh] text-blue-800 text-center ">Permissions Based on Access Levels</h1>
                                         <div className="overflow-auto p-4">
                                             <table className="table-auto w-full text-left border-collapse">
                                                 <thead className="font-[500]">
                                                     <tr>
                                                         <th className="border border-blue-300 px-4 py-2 text-center">Page</th>
                                                         <th className="border border-blue-300 px-4 py-2 text-center">Admin (Default on first account creation)</th>
                                                         <th className="border border-blue-300 px-4 py-2 text-center">Read & Write</th>
                                                         <th className="border border-blue-300 px-4 py-2 text-center">Read Only</th>
                                                     </tr>
                                                 </thead>
 
                                                 <tbody className="text-[0.9em]">
                                                     <tr>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Notifications</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Add, update, delete</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Add, update, delete</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                     </tr>
                                                     <tr>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Edit/view users</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Add user, delete (except admin)</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Add user, delete (except admin)</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                     </tr>
                                                     <tr>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Edit/view user account</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Edit basic info, password, email</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Edit basic info, password, email, delete user account</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Edit basic info, password, email, delete user account</td>
                                                     </tr>
                                                     <tr>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Edit/view organization</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Edit, delete organization</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                     </tr>
                                                     <tr>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">License information</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Edit billing address, link key</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                     </tr>
                                                     <tr>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Linked devices</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Delete device</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Delete device</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                     </tr>
                                                     <tr>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Installer</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Download only approved products</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">Download only approved products</td>
                                                         <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                     </tr>
                                                 </tbody>
                                             </table>
 
                                         </div>
                                     </div></div>
  )
}

export default PermissionTable