import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// import SignUp1 from './pages/SignUp1';
import ThankYouSign from './pages/signUp/ThankYouSign';
import 'react-tabs/style/react-tabs.css'; // Import default styles for react-tabs

import { Installer1 } from './webapplication/pages/Installer1';
import { Helmet } from 'react-helmet';
// import { useCookies } from 'react-cookie';
import CreateLicense2 from './webapplication/pages/licensing/CreateLicense2';
import LicenseDetail1 from './webapplication/pages/licensing/LicenseDetail1';
import LicenseHistoryAdmin from './webapplication/pages/licensing/LicenseHistoryAdmin';

import Error from './pages/Error';
import LinkedDevices from './webapplication/pages/licensing/LinkedDevices';
import UserRoles from './webapplication/pages/UserRoles';
import NewUserSignup from './webapplication/pages/NewUserSignup';

import AllOrganisation from './webapplication/pages/adminPages/AllOrganisation';
import AllCompanies from './webapplication/pages/adminPages/AllCompanies';
import Notification from './webapplication/pages/administrator/Notification';
import OrganisationSettings from './webapplication/pages/administrator/OrganisationSettings';
import Release from './webapplication/pages/adminPages/Release';
import Login12 from './pages/login/Login12';
import Account1 from './webapplication/pages/settings/Account1';
import ProgramLogs from './webapplication/pages/adminPages/logs/ProgramLogs';
import SystemLogs from './webapplication/pages/adminPages/logs/SystemLogs';

import VerifyUser from './webapplication/pages/adminPages/verify/VerifyUser';

import NotificationAlert from './utils/Notification';
import AddPermission from './webapplication/pages/administrator/AddPermission';
import AddUser from './webapplication/pages/administrator/AddUser';
import Error1 from './pages/Error1';

const PrivateRoutes = () => {
    const { userAuthData, permissions,errorAdminAuth } = useSelector((state) => ({
        // companyId: state.companyid.companyId,
        // userId: state.companyid.userId,
        permissions: state.companyid.permissions,
        // adminAuthData: state.adminAuth.adminAuthData,
        userAuthData: state.userAuth.userAuthData,
        errorAdminAuth:state.adminAuth.errorAdminAuth
    }));
   
    
    return (

        <>
            {/* <div className='bg-gray-400 h-9 min-w-[80vw] absolute right-0 top-0 z-[150]'>bgbbg</div> */}
      {errorAdminAuth &&  <NotificationAlert  />}
            <Routes >
                <Route path="/cms/account/:userId" element={
                   permissions?.some(
                    (permission) =>
                        permission.pageData?.name === "View/Edit User Account" &&
                        permission.permissionLevel === "no access"
                ) 
                        ? <Error1 /> : <Account1 />} />
                <Route path="/cms/allOrg/:userId" element={<AllOrganisation />} />
                <Route path="/cms/allCompany/:userId" element={<AllCompanies />} />
                <Route
                    path="/cms/admin/notifications/:userId"
                    element={
                        permissions?.some(
                            (permission) =>
                                permission.pageData?.name === "Notification" &&
                                permission.permissionLevel === "no access"
                        )
                            ? <Error1 /> : <Notification />}
                />
                <Route
                    path="/cms/admin/organisation/:userId"
                    element={permissions?.some(
                        (permission) =>
                            permission.pageData?.name === "View/Edit Account" &&
                            permission.permissionLevel === "no access"
                    ) ? <Error1 /> : <OrganisationSettings />}
                />
                <Route
                    path="/cms/licenseInfo/:userId"
                    element={
                        permissions?.some(
                            (permission) =>
                                permission.pageData?.name === "License Information" &&
                                permission.permissionLevel === "no access"
                        )
                            ? <Error1 />
                            : <LicenseDetail1 />
                    }
                />
                <Route path="/cms/licenseHistory/:userId" element={ permissions?.some(
                        (permission) =>
                            permission.pageData?.name === "License Transaction History" &&
                            permission.permissionLevel === "no access"
                    )
                        ? <Error1 /> :  <LicenseHistoryAdmin />} />

                <Route path="/cms/linkeddevices/:userId" element={
                    permissions?.some(
                        (permission) =>
                            permission.pageData?.name === "License Devices" &&
                            permission.permissionLevel === "no access"
                    )
                        ? <Error1 /> : <LinkedDevices />} />

                <Route path="/cms/installer/:companyId/:userId" element={
                   permissions?.some(
                    (permission) =>
                        permission.pageData?.name === "Installer" &&
                        permission.permissionLevel === "no access"
                )
                        ? <Error1 /> : <Installer1 />} />
                <Route
                    path='/cms/userroles/:userId'
                    element={
                        permissions?.some(
                            (permission) =>
                                permission.pageData?.name === "View/Edit New Users" &&
                                permission.permissionLevel === "no access"
                        )
                            ? <Error1 />
                            : <AddUser />
                    }
                />
                <Route path='/cms/permission/:userId' element={<AddPermission />} />
                {/* <Route path="/cms/newuser/:uniqueId" element={<NewUserSignup />} /> */}
                <Route path="/cms/createLicense/:userId" element={<CreateLicense2 />} />
  
                <Route path="/cms/release/manage/:userid" element={<Release />} />
                <Route path="/cms/verifyUser/:userId" element={<VerifyUser />} />
            
            </Routes>
        </>


    )
}

export default PrivateRoutes