import React from 'react'
import { Helmet } from 'react-helmet'
import CookiesBanner from '../components/Cookie'
import Footer from '../Footer'
import Header from '../Header1'
import DpDpAct from './DpDpAct'
import FreeTrialbot from '../components/FreeTrialbot'
const DpdpPage = () => {
    return (
        <>
            <Helmet>
                <title>Matisoft | DPDP Act</title>
            </Helmet>
            <Header />
            <FreeTrialbot />
            <DpDpAct />
            <CookiesBanner />
            <Footer />
        </>
    )
}

export default DpdpPage