import React, { startTransition, useEffect, useState, useRef } from 'react'
import Sidebar from '../../common/Sidebar'
// import Loader from '../common/Loader'
import Loader from '../../common/Loader'
import '../../css/index.css'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { IoMdHome } from "react-icons/io";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import FilterSection from '../common/FilterSection'
import { FaCheckDouble, FaFilter, FaInfoCircle, FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setFileId, setCompanyId, setUserId, setCurrentKey } from '../../../Redux/companyid/companyid.action';
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";

import { ImCross } from 'react-icons/im'
import { LuRefreshCcw } from 'react-icons/lu'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import Sidebar1 from '../../common/Sidebar1';
import SidebarAdmin from '../../common/SidebarAdmin';
import { Helmet } from 'react-helmet';
import { checkAuthentication } from '../../../Redux/auth/auth.action'
import { RiComputerLine } from 'react-icons/ri'
import '../../css/license.css'
import PageNumber from '../../components/pagination/PageNumber';
import DataEntriesNo from '../../components/pagination/DataEntriesNo';
import FilterSection from '../../common/FilterSection';
import SidebarLicense from '../../common/SidebarLicense';
import { fetchLicenceHISTORY_USER, setSortOptions } from '../../../Redux/licence/licenseHistoryUser.action';
import { checkAdminAuth } from '../../../Redux/adminAuth/adminAuth.action';
import Error from '../../../pages/Error';
import { FaCircleInfo } from 'react-icons/fa6';
import { useSortedData } from '../../../utils/sortUtils';
import FormatDate from '../../../utils/FormatDate'
const LicenseHistoryAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openShowHide, setOpenShowHide] = useState(false);
  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState("auto");
  const { userId, currentLicenseKey, licenseHistoryList, pageNumber, totalRecords, sortOrder, sortBy, filters,
    loading, adminAuthData, permissions, errorAdminAuth, errorUserAuth } = useSelector((state) => ({
      userId: state.companyid.userId,
      currentLicenseKey: state.companyid.currentLicenseKey,
      licenseHistoryList: state.licenseHistory.licenseHistoryList,
      pageNumber: state.licenseHistory.pageNumber,
      totalRecords: state.licenseHistory.totalRecords,
      sortOrder: state.licenseHistory.sortOrder,
      sortBy: state.licenseHistory.sortBy,
      filters: state.licenseHistory.filters,
      loading: state.licenseHistory.loading,
      adminAuthData: state.adminAuth.adminAuthData,
      permissions: state.companyid.permissions,
      errorAdminAuth: state.adminAuth.errorAdminAuth,
      errorUserAuth: state.userAuth.errorUserAuth

    }));

  let newSize;
  const fieldParam = new URLSearchParams(location.search).get('field');
  const operandParam = new URLSearchParams(location.search).get('operand');
  const valueParam = new URLSearchParams(location.search).get('value');
  const excludeParam = new URLSearchParams(location.search).get('exclude');
  const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
  const pageNumberParam = new URLSearchParams(location.search).get('page');
  const sortByParam = new URLSearchParams(location.search).get('sortBy');
  const sortOrderParam = new URLSearchParams(location.search).get('sortOrder');


  // Assuming the values are stored as JSON strings in the URL, parse them
  const field = fieldParam ? JSON.parse(fieldParam) : [];
  const operand = operandParam ? JSON.parse(operandParam) : [];
  const value = valueParam ? JSON.parse(valueParam) : [];
  const exclude = excludeParam ? JSON.parse(excludeParam) : [];
  const pageSize = pageSizeParam ? JSON.parse(pageSizeParam) : [];
  const page = pageNumberParam ? JSON.parse(pageNumberParam) : [];
  const sortByCol = sortByParam ? JSON.parse(sortByParam) : [];
  const sortOrderCol = sortOrderParam ? JSON.parse(sortOrderParam) : [];
  // console.log(sortByCol,sortOrderCol,"clm and order");

  const [activeArrow, setActiveArrow] = useState({
    columnName: sortByCol.length > 0 ? sortByCol[0] : "updated_date", // Extract first element or use default
    direction: sortOrderCol.length > 0 ? sortOrderCol[0] : -1, // Extract first element or use default
  });
  // console.log(activeArrow);

  const [pageSizeFromURL, setPageSizeFromURL] = useState(100);
  const [currPage, setCurrPage] = useState(1);
  const [filterChange, setFilterChange] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const handleFilter = () => {
    setShowFilter(!showFilter);
  }
  let filtersvalue = React.useMemo(() => {
    return {
      filters: {
        field,
        operand,
        value,
        exclude,
      },
    };
  }, [field, operand, value, exclude]);
  const filtersValueRef = React.createRef(filtersvalue);
  const pageSizeRef = React.createRef(pageSize);
  const [showConfirmationModal, setShowConfirmationModal] = useState(null);

  useEffect(() => {
    const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
    const parsedSize = pageSizeParam ? parseInt(pageSizeParam) : pageSizeFromURL; // Set default if not provided
    setPageSizeFromURL(parsedSize);
    const pageNumberParam = new URLSearchParams(location.search).get('page');
    const parsedPage = pageNumberParam ? parseInt(pageNumberParam) : currPage;
    setCurrPage(parsedPage);
  }, [location.search]);
  const [currentTime, setCurrentTime] = useState(getCurrentTime()); // Initial state with current time

  // Function to get current time
  function getCurrentTime() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = String(now.getFullYear()).slice(-2); // Get last two digits of the year
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

useEffect(() => {
    const updateHeight = () => {
      if (tableRef.current) {
        const offsetTop = tableRef.current.getBoundingClientRect().top;
        const availableHeight = window.innerHeight - offsetTop;
        setTableHeight(`${availableHeight}px`);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  // Function to add leading zero if needed
  function addZeroIfNeeded(value) {
    return value < 10 ? '0' + value : value;
  }
const [updatedData,setUpdatedData]=useState(false);
  // Function to update current time
  function updateTime() {
    setCurrentTime(getCurrentTime());
  setUpdatedData(!updatedData);
  }
  // const location = useLocation();

  // Access the key from the state
  const { key } = location.state || {};

  useEffect(() => {
    const currentURL = window.location.pathname;

    const parts = currentURL.split('/');
    const fileid = null;
    const userid = parts[parts?.length - 1];

    dispatch(setUserId(userid));
    dispatch(setFileId(fileid));

    // Fetch `key` from the URL query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const localKey = queryParams.get('Key') || "null"; // Default to null if `Key` is not present

    const { id, key } = location.state || {};

    if (userid) {
      dispatch(fetchLicenceHISTORY_USER(userid, localKey, pageSizeFromURL, currPage, sortBy, sortOrder, filtersvalue));
      dispatch(checkAdminAuth(userid));
    }
    dispatch(checkAuthentication());

    setCurrentTime(getCurrentTime());
  }, [
    dispatch,
    pageNumber,
    filtersValueRef.current,
    pageSizeFromURL,
    currPage,
    filterChange,
    currentTime, updatedData
  ]);


  let columnsLicense = [
    { id: 0, name: "Modification date", value: "updated_date", type: 'date' },
    { id: 1, name: "Linked organziation", value: "username", type: 'string' },
    { id: 2, name: "Key", value: "key", type: 'string' },
    { id: 3, name: "Previous credits", value: "before_total_credits", type: 'number' },
    { id: 4, name: "Change in credits", value: "updated_total_credits", type: 'number' },
    { id: 5, name: "Updated credits", value: "total_credits", type: 'number' },
    { id: 6, name: "Remark", value: "remark", type: 'string' },

    { id: 7, name: "Total License Daily Rate", value: "total_daily_rate", type: 'number' },
    { id: 8, name: "Expiry date", value: "license_due_date", type: 'date' },
    { id: 9, name: "License link date", value: 'license_linked_date_time', type: 'date' },

  ];

  // if (key) {
  //     columnsLicense = columnsLicense.filter(column => column.value !== "key");
  // }

  const [checkedRows, setCheckedRows] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columnsLicense);
  const [checkedColumns, setCheckedColumns] = useState(columnsLicense.map(() => true));
  const handleCheckboxChange = (event, index) => {
    const { checked } = event.target;
    const column = columnsLicense[index];
    // console.log(column, "column");

    setCheckedColumns(prevState => {
      const updatedCheckedColumns = [...prevState];
      updatedCheckedColumns[index] = checked;
      return updatedCheckedColumns;
    });
    setVisibleColumns(prevState => {
      const updatedVisibleColumns = [...prevState];
      // console.log(updatedVisibleColumns, "visibleColumn");
      if (checked) {

        if (!updatedVisibleColumns.find(col => col.id === column.id)) {
          updatedVisibleColumns.push(column);
          updatedVisibleColumns.sort((a, b) => a.id - b.id);
        }
      } else {

        const filteredColumns = updatedVisibleColumns.filter(col => col.id !== column.id);
        // console.log(filteredColumns, "filteredcol");
        return filteredColumns;
      }

      return updatedVisibleColumns;
    });
  };
  const selectAllColumns = () => {
    setCheckedColumns(columnsLicense.map(() => true)); // Mark all columns as checked
    setVisibleColumns(columnsLicense);
  };

  // Function to deselect all columns
  const deselectAllColumns = () => {
    setCheckedColumns(columnsLicense.map(() => false)); // Mark all columns as unchecked
    setVisibleColumns([]); // Hide all columns
  };

  const handleSort = (columnName, newSortOrder) => {
    // Set active arrow state
    setActiveArrow({
      columnName: columnName,
      direction: newSortOrder,
    });

    // Dispatch the sort options (optional, if you're using redux or state management)
    dispatch(setSortOptions(columnName, newSortOrder));

    // Get the current query string and update the sort parameters
    const searchParams = new URLSearchParams(location.search);

    // Update the sortBy and sortOrder parameters
    searchParams.set('sortBy', JSON.stringify([columnName])); // Save the sorted column
    searchParams.set('sortOrder', JSON.stringify([newSortOrder])); // Save the sort order

    // Use navigate to update the URL with the new query parameters
    navigate({
      pathname: location.pathname, // Keep the current pathname
      search: searchParams.toString(), // Apply the updated search parameters
    });
  };

  const sortedData = useSortedData(licenseHistoryList, activeArrow.columnName, activeArrow.direction);

  const [isSidebarExpanded, setSidebarExpanded] = useState(true);

  const toggleSidebar = () => {
    setSidebarExpanded(!isSidebarExpanded);

  };
  const handlePageSizeChange = (event) => {
    event.preventDefault();
    newSize = parseInt(event.target.value);
    // console.log(newSize, "newsize");
    // dispatch(setPageSize(newSize))
    const existingParams = new URLSearchParams(location.search);
    existingParams.set('pageSize', JSON.stringify(newSize)); // Update or add page size

    const queryString = existingParams.toString();

    navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

  };

  const [isPercentage, setIsPercentage] = useState(true); // State to track percentage option
  const [maxNegativeCredits, setMaxNegativeCredits] = useState(0); // State for max_negative_credits

  const handleOptionChange = (value) => {
    setIsPercentage(value === 'percentage');
    if (value === 'percentage') {
      const percentageValue = 0.0833 * licenceInfo.total_credits; // Calculate 8.33% of total_credits
      setMaxNegativeCredits(percentageValue.toFixed(2)); // Set to fixed 2 decimal places
    } else {
      setMaxNegativeCredits(licenceInfo.max_negative_credits); // Use current absolute value
    }
  };


  const [licenceId, setLicenceId] = useState('');
  const [addNew, setAddNew] = useState([]);
  const [currentKey, setCurrentKey] = useState('');


  const [editWindow, setEditWindow] = useState(false);

  const handlePageNumberChange = (newPageNumber) => {
    setCurrPage(newPageNumber);
    const existingParams = new URLSearchParams(location.search);
    existingParams.set('page', JSON.stringify(newPageNumber)); // Update or add page number

    const queryString = existingParams.toString();

    navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

  };
  const handleClick = () => {

  };
  const [dailyRateCriteria, setDailyRateCriteria] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showMoreStates, setShowMoreStates] = useState({});
  // Function to toggle show more/less state for 'details'
  const toggleShowMoreDetails = (rowIndex) => {

    const key = `${rowIndex}-details`;
    setShowMoreStates(prevState => ({
      ...prevState,
      [key]: !prevState[key] // Toggle the show more/less state for the specific row and 'details' value
    }));
  };
   const [expandedStates, setExpandedStates] = useState({});

    // Function to toggle show more/less state for 'details'
    const toggleExpand = (rowIndex, colIndex) => {
        setExpandedStates((prev) => ({
            ...prev,
            [`${rowIndex}-${colIndex}`]: !prev[`${rowIndex}-${colIndex}`],
        }));
    };
  const onApplyFilters = (filters) => {

    // console.log(filters, "filternewVLUE");
    setFilterChange(filters);

  }

  const buttonRef = useRef(null);

  if (loading) return <Loader />;
  if (errorAdminAuth && errorUserAuth) {
    return <Error />;
  }
  return (
    <div className="flex h-screen">
      <Helmet>
        <title>Matisoft | License Transaction History</title>
      </Helmet>
      <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25 z-50'} bg-gray-200 h-full fixed `}>
        {/* Sidebar Content Goes Here */}
        {adminAuthData?.role?.includes('ADMINISTRATOR') ? (
          <SidebarLicense isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
        ) : (
          <Sidebar1 isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} pageName="License Transaction History" />
        )}

      </div>

      {/* Main Content */}
      <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%]'} overscroll-y-none relative overflow-hidden licenseCreation transition-all`}>
      <div className={` ${showFilter ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50 z-[42] `} onClick={handleFilter}></div>

      <div className=" w-full bg-white z-50">
          <div className={`flex flex-row justify-end items-center px-[2%] pt-[1%] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'}`}>

          </div>
          <div className={`flex flex-row items-center justify-between pt-[2vh] pl-[5vw] mx-auto  `}>

            <div className='text-[1.1em] py-[2vh]  font-[600]'>
              {/* Conditional rendering with highlighted key */}

              <>
                License Transaction History

              </>

            </div>
          </div>
          <div className='bg-[#bbe3f55e] text-gray-900 text-[0.9em] py-[1vh] relative w-[3000px] pl-[1%] flex flex-row gap-x-[1%]'>

            <div className=''>
              <div className='cursor-pointer hover:text-blue-500' onClick={() => setOpenShowHide(true)} >Show/Hide</div>
              {openShowHide && <>
                <div
                  className="fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50 z-[42]"

                ></div>
                <div className='showhide-section-container z-40'>
                  <div className="modal-body filter-section-content  ">
                    <button className=' absolute right-[-5px] top-[-5px]' onClick={() => setOpenShowHide(!openShowHide)}>
                      <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' />
                    </button>
                    <div className="row">
                      <div className="col-sm-6">
                        {columnsLicense.map((item, index) => (
                          <label className="cont flex flex-row gap-x-[1%] text-white">
                            <input
                              type="checkbox"
                              className="toggle-vis"
                              data-column={index + 1}
                              checked={checkedColumns[index]}
                              defaultChecked
                              onChange={(event) => handleCheckboxChange(event, index)}
                            />
                            <span className="checkmark"></span> <p>{item.name}</p>
                          </label>
                        ))}
                      </div>
                      <div></div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                      <button className="btn btn-primary apply-button" style={{ marginRight: "10px", marginLeft: "15px" }} onClick={selectAllColumns}>Select All</button>
                      <button className="btn btn-primary apply-button" onClick={deselectAllColumns}>Deselect All</button>
                    </div>
                  </div>
                </div>
              </>}
            </div>
            <div className='flex flex-row items-center hover:text-blue-500' onClick={handleFilter}>
              <span>Filters &nbsp;</span>
              <FaFilter className={` ${filtersvalue?.filters?.field?.length > 0 ? "text-green-500" : "text-yellow-400"} cursor-pointer`} />
              {filtersvalue?.filters?.field.length > 0 && <span>&nbsp;{filtersvalue?.filters?.field?.length} applied</span>}
            </div>
            {showFilter && <FilterSection showFilter={showFilter} setShowFilter={setShowFilter} columns={columnsLicense} initialFilters={filtersvalue} onApplyFilters={onApplyFilters} />}
            <div className='cursor-pointer flex flex-row items-center gap-x-[0.5vw] hover:text-blue-500' onClick={updateTime}>Refresh <LuRefreshCcw /></div>
            <div>{`Last page updated at : ${currentTime}`}</div>
          </div>
          <div className={`flex flex-row justify-between items-center py-1 ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'} border-b-1 border-b-gray-500`}>
            <DataEntriesNo pageSize={pageSize} handlePageSizeChange={handlePageSizeChange} />
            <PageNumber
              pageSize={pageSizeFromURL}
              currPage={currPage}
              totalRecords={totalRecords}
              handlePageNumberChange={handlePageNumberChange}
            />
          </div>
        </div>

        {/* Main Content Goes Here */}

        <div ref={tableRef} className="overflow-auto w-full -z-10" style={{ maxHeight: tableHeight }}>
        <table className="w-full top-0">
            {/* Table Head */}
            <thead className="sticky top-0 bg-blue-200 text-[0.9em]">
              <tr className="text-sm font-semibold text-gray-800">

                {visibleColumns.map((column, index) => (
                  <th key={index} className="py-2 px-3 min-w-[280px] w-[280px] text-center">
                    <div
                      className="flex items-center justify-center gap-x-1 cursor-pointer"
                      onClick={() => handleSort(column.value, activeArrow.columnName === column.value ? -activeArrow.direction : 1)}
                    >
                      <span title={`${column.type === "date" ? "DD/MM/YYYY": ""}`}>{column.name}</span>
                      <p>
                        <span className='font-thin text-[0.5em] text-blue-400 flex flex-row items-center arrow'>
                          <FaLongArrowAltUp title='Ascending' onClick={() => handleSort(column.value, 1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === 1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] px-0 h-[2vh] cursor-pointer arrow`} />
                          <FaLongArrowAltDown title='Descending' onClick={() => handleSort(column.value, -1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === -1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] h-[2vh] px-0 h-[1vh]cursor-pointer`} />
                        </span>
                      </p>
                    </div>
                  </th>
                ))}

                {/* <th className="py-2 px-3 min-w-[200px] w-[200px] text-center">Delete</th> */}
              </tr>
            </thead>

            {/* Table Body */}
            <tbody className="text-sm text-gray-800 border border-b-1 border-b-gray-300">
              {sortedData.length > 0 ? (
                sortedData.map((item, indexVal) => (
                  <tr key={indexVal} className={`${indexVal % 2 === 1 ? 'bg-gray-100' : ''}`}>
                    {/* Action Column */}


                    {/* Dynamic Columns */}

                    {visibleColumns.map((column, colIndex) => (
                      <td
                        key={`${indexVal}-${colIndex}`}
                        className="py-2 px-3  w-[280px] text-center text-[0.9em] break-words"
                      >
                        {(() => {
                          const value = item[column.value];

                        
                        
                          if (column.type === "date" && value) {
                            // Format dates using toLocaleString()
                            return <span><FormatDate date={value} /></span>;
                          }

                          if (value && typeof value === "string" && value.length > 200) {
                            // Handle long text with "Show More/Show Less"
                            return (
                              <div className="text-container">
                                {expandedStates[`${indexVal}-${colIndex}`] ? (
                                  <>
                                    <span>{value}</span>
                                    <button
                                      className="show-more-btn text-blue-600"
                                      onClick={() => toggleExpand(indexVal, colIndex)}
                                    >
                                      ...Show Less
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <span className="text-truncated">{value.substring(0, 200)}...</span>
                                    <button
                                      className="show-more-btn text-blue-600"
                                      onClick={() => toggleExpand(indexVal, colIndex)}
                                    >
                                      ...Show More
                                    </button>
                                  </>
                                )}
                              </div>
                            );
                          }

                          // Default case for plain text or other values
                          return <span>{value}</span>;
                        })()}
                      </td>


                    ))}
                   

                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={visibleColumns.length + 3} className="py-2 pl-2">
                    No matching data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

      </div>

    </div>
  )
}

export default LicenseHistoryAdmin;