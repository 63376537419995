import React, { useEffect } from 'react';
import { ImCross } from 'react-icons/im';
import { IoMdClose } from 'react-icons/io';
import { NavLink } from 'react-router-dom';

const DPDP = ({ showMore, setShowMore }) => {
    const handleScrollToSection = (e) => {
        e.preventDefault(); // Prevent default NavLink behavior
        const targetSection = document.querySelector('#dlp');
        if (targetSection) {
            const elementPosition = targetSection.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({
                top: elementPosition - 150, // Adjust scrolling to include the offset
                behavior: 'smooth', // Smooth scrolling effect
            });
        }
        setShowMore(!showMore); // Toggle additional content visibility
    };

    return (
        <div className='fixed bg-[#addef5] opacity-[0.98] border-blue-400 border-[1px] rounded  w-[88vw] left-[6vw]  z-[100] top-[5vh] bottom-[5vh] text-[0.9em] text-blue-900' style={{ zIndex: '120' }}>
            <div className='filter-section-content relative z-[100]'>

                {/* <button className='absolute right-[-5px] top-[-5px] cursor-pointer' onClick={() => { setShowMore(!showMore) }}>
                    <ImCross className='text-white bg-red-600 p-1 text-2xl font-[200] cursor-pointer rounded-full' />
                </button> */}
                {/* <IoMdClose className='float-right'/> */}
                <button className='absolute h-9  right-[12px] top-[5px] cursor-pointer' onClick={() => { setShowMore(!showMore) }}>
                    <IoMdClose className='text-[#0b2530]   text-[30px] font-[600] cursor-pointer rounded-full hover:scale-110' />
                </button>
                <div className="px-4   text-gray-800 mt-[3vh] max-h-[80vh] overflow-y-auto">
                    {/* Introduction */}


                    <div className='flex flex-col justify-between md:flex-row items-center w-full'>
                        {/* Left Section (60%) - Logo and Heading */}
                        <div className='flex flex-col sm:flex-row gap-x-2 items-center w-full md:w-[65%]'>
                            {/* Logo */}
                            <img
                                src="/company-logo1.png"
                                className="z-30 w-[80px] h-[80px] sm:w-[120px] sm:h-[120px] flex-nowrap"
                                alt="Company-logo"
                            />
                            {/* Heading */}
                            <h1 className='text-[1.5em] sm:text-[2em] text-center sm:text-left mt-2 sm:mt-0'>
                                How does <a onClick={() => { setShowMore(!showMore) }} href='/dlp#dlp' className='text-blue-900 hover:underline'>Matisoft DLP</a> ensure Digital Personal Data Protection Act (DPDP Act) compliance?
                            </h1>
                        </div>

                        {/* Right Section (30%) - Button */}
                        <div className='w-full md:w-[30%] mt-4 md:mt-0 flex justify-center'>
                            <NavLink className="btnForLightbg text-[1.1em]" to='/presignup'>
                                Start your free trial
                            </NavLink>
                        </div>
                    </div>
                    {/* Content */}
                    <section className="pt-7 pb-4 mt-1  md:text-[1.3em] text-[#0d1c30] text-justify">
                        <h2 className='text-[1.25em] font-[600] text-[#0d1c30] pb-1'>Digital Personal Data Protection Act (DPDP Act) Compliance and Data Breaches
                        </h2>
                        <p className='  text-[#0d1c30] '>
                            The draft DPDP Act Rules require organizations to protect personal data from unauthorized access & leakage. If personal data is shared with an unauthorized individual or is stored/used in violation of the DPDP Act guidelines, companies can face fines up to<span className='font-semibold text-[#23233d]'> INR 250 crore</span>. Non-compliance with  DPDP Act can result in severe penalties and legal complications.
                        </p>
                    </section>

                    <section className="py-4 md:text-[1.3em] text-[#0d1c30] text-justify">
                        <h2 className='text-[1.25em] font-[600] text-[#0d1c30] pb-1'>What is Personal Data?</h2>
                        <p className='  text-[#0d1c30]'>Personal data refers to any information that can identify an individual, such as names, contact details or financial information. Examples of files or documents that may contain personal data include customer forms, invoices, emails, employee records, health information etc.</p>
                    </section>

                    <section className="py-4 md:text-[1.3em] text-[#0d1c30] text-justify">
                        <h2 className='text-[1.25em] font-[600] text-[#0d1c30] pb-1'>How Matisoft Data Leak Prevention (DLP) Safeguards Personal Data Under DPDP Act?</h2>
                        <p className=' text-[#0d1c30]'>Files containing personal data such as invoices, resumes, customer forms, emails, and employee records—are often at risk of being accidentally or intentionally shared outside the organization by employees. Such incidents can make the company liable under the DPDP Act, resulting in severe penalties.

                            <p className='py-2'>   To prevent data leaks, Matisoft DLP monitors and blocks all potential channels through which data can be sent/leaked outside the organization, including emails, cloud uploads, USB devices and more. It scans for sensitive keywords, such as personal information and blocks any files or emails containing such information & generates real-time alerts for the same.
                            </p>     By implementing Matisoft DLP, your organization can effectively safeguard sensitive data, achieve greater compliance with DPDP Act regulations, reduce the risk of breaches and penalties, and ensure that your business secrets are not leaked.
                        </p>
                    </section>
                    {/* Footer */}
                    <div className="mt-8 py-3 px-2 bg-primary border-t border-gray-200 text-center text-gray-400 2xl:text-[1.1em]">
                        <div className="flex flex-wrap items-center justify-center gap-2">
                            <span>
                                To learn more about our DLP (Data Leak Prevention),{" "}

                                visit our  <a onClick={() => {
                                    setShowMore(!showMore); // Toggle the showMore state
                                }}
                                    href="/dlp#dlp"
                                    className="underline text-secondary hover:text-blue-600 transition-colors duration-200"
                                > page
                                </a>
                                .
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DPDP;