import React, { useEffect } from 'react'
import { styles } from '../styles'
const DlpProduct1 = () => {
    useEffect(() => {
        const handleScrollToSection = () => {
            const hash = window.location.hash; // Get the current hash
            if (hash === "#dlp") {
                const navbarHeight = 80; // Replace with the actual height of your navbar
                const dlpSection = document.getElementById("dlp");

                if (dlpSection) {
                    // Calculate the position to scroll to (section position - navbar height)
                    const scrollPosition = dlpSection.offsetTop - navbarHeight;

                    // Scroll to the calculated position
                    window.scrollTo({
                        top: scrollPosition,
                        behavior: "smooth", // Optional: Add smooth scrolling
                    });
                }
            }
        };

        // Run the function on initial mount
        handleScrollToSection();

        // Add an event listener for hash changes
        window.addEventListener("hashchange", handleScrollToSection);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener("hashchange", handleScrollToSection);
        };
    }, []); // Empty dependency array ensures this runs only once on mount
    return (

        <div
            className=" w-full  static h-full mx-auto rounded-b-[10px]bg-[#fff] mt-[5vh] pt-[5vh]" id="dlp" >

            <div className={`static  pt-1  inset-0  container mx-auto ${styles.paddingX} `} >

                <div className="matisoft py-[1vh] pt-[2vh]" id='dlpheading'>
                    <h1 className="text-secondary text-4xl font-extrabold font-heading text-center mx-auto  font-high " >
                        {" "}
                        Matisoft DLP
                    </h1>
                </div>
                <p className={`text-primary  py-2  font-medium  mx-auto text-justify`}>
                    As an IIT-Delhi partner company in collaboration with Data Security Council of India, Matisoft offers a comprehensive Next-Generation Data Leak Prevention (DLP) solution. Our DLP suite includes advanced features such as email protection, cloud protection, application blocking, website blocking and device blocking. Additionally, our proprietary USB Firewall technology and Digital Vault technology ensure 100% secure and convenient file transfer and data protection both within and outside your organization.
                </p>
                <div className="dlp-products py-[1vh]" >
                    {/* Email Protection  */}
                    <div className=" mb-10 flex flex-row justify-between items-center p-3 w-full h-full xl:h-[53vh] firewall mx-auto dlp-usb ">
                        {/* <div className="max-w-md bg-gradient-to-br min-h-screen rounded-xl "> */}
                        <div className="bg-contain   max-w-lg px-6 py-4 z-10 ">
                            <h1 className=" font-aboveMed text-center pb-1">Emails and Attachments</h1>
                            <p className={`text-white mt-2 max-w-xl font-thin ${styles.sectionHeadText} font-medium text-justify`}>
                                Matisoft DLP scans emails and attachments for keywords such as<span className='text-secondary font-[500]'>  confidential, classified, account numbers, credit card numbers, PAN cards, etc.</span> This scanning includes <span className='text-secondary font-[500]'> email bodies</span> and <span className='text-secondary font-[500]'> attachments</span> like <span className='text-secondary font-[500]'> zip files, office files, PDFs</span><span className='text-secondary font-[500]'>, images, </span>etc.</p>
                        </div>
                        <figure>
                            <img src='/images/email (1).png' className="p-12 object-contain" alt='email_protection' />
                        </figure>
                    </div>
                    {/* Cloud Protection  */}
                    <div className=" mb-10 flex flex-row justify-between items-center p-3 w-full h-full xl:h-[53vh] firewall mx-auto dlp-usb ">
                        {/* <div className="max-w-md bg-gradient-to-br min-h-screen rounded-xl "> */}
                        <figure>
                            <img src='/images/data-encryption.png' className="p-12 object-contain" alt='email_protection' />
                        </figure>
                        <div className="bg-contain   max-w-lg px-6 py-4 z-10 ">
                            <h1 className=" font-aboveMed text-center pb-1">Cloud Protection</h1>
                            <p className={`text-white mt-2 max-w-xl font-thin ${styles.sectionHeadText} font-medium text-justify`}>
                                Protects cloud file uploads by monitoring and inspecting files with keywords such as<span className='text-secondary font-[500]'> confidential, classified, account numbers, credit card numbers, PAN cards, etc. </span>It provides real-time alerts and detailed reports to administrators.</p>
                        </div>
                    </div>
                    <div className=" mb-10 flex flex-row justify-between items-center p-3 w-full h-full xl:h-[53vh] firewall mx-auto dlp-usb ">
                        {/* <div className="max-w-md bg-gradient-to-br min-h-screen rounded-xl "> */}
                        <div className="bg-contain   max-w-lg px-6 py-4 z-10 ">
                            <h1 className=" font-aboveMed text-center pb-1">Website Blocking</h1>
                            <p className={`text-white mt-2 max-w-xl font-thin ${styles.sectionHeadText} font-medium text-justify`}>
                                Boosts security by blocking access to instant messaging and social media websites like <span className='text-secondary font-[500]'> Facebook, WhatsApp</span>, etc. that could lead to data leaks or breaches. These measures ensure compliance, protect sensitive information, and prevent data transmission, thereby safeguarding organizational integrity. </p>
                        </div>
                        <figure>
                            <img src='/images/no-internet.png' className="p-12 object-contain" alt='email_protection' />
                        </figure>
                    </div>
                    <div className=" mb-10 flex flex-row justify-between items-center p-3 w-full h-full xl:h-[53vh] firewall mx-auto dlp-usb ">
                        {/* <div className="max-w-md bg-gradient-to-br min-h-screen rounded-xl "> */}

                        <figure>
                            <img src='/images/blocked.png' className="p-12 object-contain" alt='email_protection' />
                        </figure>
                        <div className="bg-contain   max-w-lg px-6 py-4 z-10 ">
                            <h1 className=" font-aboveMed text-center pb-1">Application Blocking</h1>
                            <p className={`text-white mt-2 max-w-xl font-thin ${styles.sectionHeadText} font-medium text-justify`}>
                                This feature enables control over which applications can be used within your organization. Applications like <span className='text-secondary font-[500]'>Zoom, Any Desk, WhatsApp</span> and others can be blocked to prevent data leaks. This feature is robust against bypass attempts, such as renaming files or updating versions.                                   </p>

                        </div>
                    </div>
                    {/* <img src="images/dlp-1.jpg" className="mx-auto h-[350px] object-contain py-4 " alt="" /> */}
                    <div className="flex flex-row justify-between items-center p-3 w-full h-full xl:h-[53vh] firewall mx-auto dlp-usb mb-10 ">
                        {/* <div className="max-w-md bg-gradient-to-br min-h-screen rounded-xl "> */}
                        <div className="bg-contain   max-w-lg px-6 py-4 z-10 ">
                            <h1 className=" font-aboveMed text-center pb-1">Matisoft USB Firewall</h1>
                            <p
                                className={`text-white  mt-2 max-w-xl font-thin ${styles.sectionHeadText} font-medium text-justify `}
                            >
                                Proprietary USB Firewall technology to share data within the
                                organisation: This technology helps share data on USB storage in
                                a 100% secure manner
                            </p>

                            <ol className=" text-justify   text-white     list-disc">
                                {/* <span className="w-5 h-5 rounded-full bg-primary" />{" "} */}
                                <li>Without fear of data leak/loss/theft</li>
                                <li >
                                    No USB viruses can enter your organization
                                </li>
                            </ol>
                            {/* </div> */}
                        </div>{" "}
                        <figure>

                            <div className="flex flex-row items-center justify-around  relative">
                                {/* <img src="/images/usb-drive_1.gif" className="img-style1" alt="" /> */}
                                <img src="/images/flash-drive.png" className="img-style1" alt="" />

                                <img
                                    src="/images/firewall (1).png"
                                    className="img-style1"
                                    alt=""
                                />
                            </div>
                        </figure>

                    </div>
                    <div className="flex flex-row justify-between items-center p-3 w-full h-full xl:h-[80vh] py-4   vault-wall mx-auto  dlp-usb">
                        {/* <div> <img src="images/vault-5.jpg" className="hidden sm:block w-96 object-contain pl-3 flex usbVault " alt="usbVault" /></div>  */}
                        <figure>
                            <img
                                src="/images/safe-box.png"
                                alt="usbVault"
                                className="img-style2"
                            />
                        </figure>
                        <div className=" bg-contain max-w-lg p-6  rounded-xl">
                            <h1 className=" font-aboveMed text-center pb-1">Matisoft USB Vault</h1>
                            <p
                                className={`text-white  mt-2 max-w-xl font-thin ${styles.sectionHeadText} font-medium text-justify `}
                            >
                                USB Vault technology securely transfers data outside your
                                organization & ensures
                            </p>
                            <ol className="    text-justify   text-white     list-disc">
                                <li> Data can be accessed only by the authorized recipient</li>
                                <li>
                                    Immediate notification of where and when USB data is accessed
                                </li>
                                <li>Access control – login to access data </li>
                                <li>Data privacy – read/write own data only </li>
                                <li>
                                    Data integrity – protection against unauthorized data
                                    manipulation{" "}
                                </li>
                                <li>
                                    Malware Protection – data is protected from malware like
                                    ransomware{" "}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DlpProduct1