
import { SET_USER_AUTH, ERROR_USER_AUTH, REQUEST_USER_AUTH, SET_USER_CREDENTIAL } from "./userAuth.action_type";
import axios from "axios";
import { setCompanyId, setDefaultCompanyId, setPermissions } from "../companyid/companyid.action";
export const checkUserAuth = (userId) => {

  try {
    return async (dispatch) => {
      dispatch({ type: REQUEST_USER_AUTH });

      try {

        const response = await axios.get(`${process.env.REACT_APP_HOST_NAME}/api/webuser/${userId}`);

        dispatch({ type: SET_USER_AUTH, payload: response?.data });

        if (response?.data?.data?.permissions) {
          dispatch(setPermissions(response.data?.data?.permissions));
        }
        // console.log(response);
        
        dispatch(setDefaultCompanyId(response?.data?.companyId))
        // console.log('Data from backend FOR USER_AUTH:', response);
        // Handle the response data
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch({ type: ERROR_USER_AUTH, payload: error })
        // Handle errors
      }
    };

  } catch (error) {
    console.log(error, 'edrfdfdsfdsd');

  }

};
export const userCredential = (location) => ({
  type: SET_USER_CREDENTIAL,
    payload: location,
  })